import React from 'react';
import BaseList from 'components/lists/BaseList';
import ActionList from 'components/lists/ActionList';
import { datetimeFormatter } from 'utils/formatters.js';
import Edit from '@material-ui/icons/Edit';
import TableHeaderAction from 'components/table/TableHeaderAction';
import { CheckboxColumn } from 'components/table/columns';
import ModalEquipmentEdit from 'components/modals/ModalEquipmentEdit';
import { useAccessLog } from 'hooks/logs';

function ListRF(props) {
  useAccessLog('Acesso ao cadastro de RF');
  const { match } = props;
  return (
    <BaseList
      modals={{
        'Edit': {
          isVisible: false,
          needReloadData: true,
          component: (props) => (
            <ModalEquipmentEdit key='EditModal' {...props} />
          ),
        },
      }}

      columns={[
        { value: row => row.file ? row.file.filename : '-', label: 'Nome do arquivo', sort: 'file.filename' },
        { value: row => row.description, label: 'Descrição', sort: 'description' },
        {
          value: row => {
            if(row.date) {
              return datetimeFormatter(row.date)
            } else {
              return '-'
            }
          },
          label: 'Data da importação',
          sort: 'date'
        },
        { value: row => (row.user ? row.user : '-'), label: 'Usuário', sort: 'user' },
        { value: row => (row.totalRecords ? row.totalRecords : '-'), label: 'Quantidade de registros', sort: 'totalRecords' },
        { value: row => (row.status ? row.status : '-'), label: 'Status do processamento', sort: 'status' },
        // {
        //   value: row => (row.equipmentType ? row.equipmentType.name : ''),
        //   label: 'Tipo de Equipamento',
        //   sort: 'equipmentType.name'
        // },
        // { value: row => row.iccid, label: 'ICCID', sort: 'iccid' },
      ]}
      genericSearchFields={[
        { label: 'Nome do Arquivo', name: 'file.filename' },
      ]}
      basePath="rf"
      title="Lista de Importações"
      addButtonText="Nova Importação"
      addUrl={`${match.path}/novo`}
    />
  );
}

export default ListRF;
