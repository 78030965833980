import React, { 
  useState, 
  useEffect, 
  useImperativeHandle,
  useRef
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, InputAdornment, Typography } from '@material-ui/core';
import {
  ButtonSubmit,
  ButtonRemove,
  ButtonCancel,
  ButtonGeneric,
  ButtonDownload,
} from 'components/buttons/ButtonsForm';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  IconButton,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import {
  Close
} from '@material-ui/icons';

import Permission from 'components/permissions/Permission';
import Form, { FormSection } from './Form';
import { TextField, RfFileUpload } from '../fields';
import { AutocompleteField, RadioGroupField } from '../fields';
import { useFields } from '../../hooks/fields';
import { useFetch, useAuthenticated } from '../../hooks/fetch';
import { emitEvent } from '../../utils/events';
import { Rule, validate } from "../../utils/formValidator";

import theme from 'styles/theme';
import { EQUIPMENT_STATUS } from 'utils/statuses';
import NumberInput from 'components/fields/NumberInput.field';
import { postWithErrorHandler } from 'services/fetch';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    marginTop: 12,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: theme.typography.fontWeight['700'],
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  textRoot: {
    minHeight: 'auto'
  }
}));

const RfImportForm = props => {
  const classes = useStyles();
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState({
    companies: false,
    mobileOperators: false,
    colors: false,
    exemplaries: false,
    packings: false,
    models: false,
    equipmentType: false,
    baitType: false,
    form: false,
  });
  const [submitForm, setSubmitForm] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  // const [temperatureRange, setTemperatureRange] = useState([10, 20]);
  // const [temperatureRangeEnabled, setTemperatureRangeEnabled] = useState(false);


  const [file, setFile] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [baitTypes, setBaitTypes] = useState([]);
  const reader = useRef(new FileReader());
  const [validations, setValidations] = useState({
    rules: [
      Rule(
        'isEmpty', [], false,
        'Campo de preenchimento obrigatório',
         value => value ? value.name : ''
      )
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const fields = useFields([
    'file',
    'description',
    'status',
    'processingDetails'
  ]);

  const validateField = () => {
    const _rules = (false || file) ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(file, _rules)
    });
  };

  async function urltoFile({url, filename, mimetype}){
    try {
      const res = await fetch(url);
      const buf = await res.arrayBuffer();
      setFile(new File([buf], filename, {type: mimetype}));
    } catch (err) {
      console.log(err);
    }
  }

  const setFieldValue = async (value) => {
    if (value && value.url && value.mimetype && value.filename) {
      await urltoFile(value);
    } else {
      console.log(`${value} is not a valid value`);
    }
  }

  useImperativeHandle(ref, () => ({
    value: file,
    raw: !file ? {} : {filename: file.name, mimetype: file.type, url: reader.current.result},
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue
  }));

  /**======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    setFormDisabled({ form: true });
    if (props.data) {
      for (const [key, value] of Object.entries(props.data)) {
        if (fields[key] && fields[key].current) {
          if (fields[key].current.setFieldValue) {
            fields[key].current.setFieldValue(value);
          }
        }
      }
    }
    setFormDisabled(false);
  }, [props.data]);

  const validateForm = async event => {
    event.preventDefault();

    const nonValidatedFields = Object.values(fields).filter(
      f => !f.current.alreadyValidated
    );
    nonValidatedFields.map(f => f.current.validateField());

    setSubmitForm(true);
  };

  // Handle form submission
  useEffect(() => {
    if (submitForm) {
      (async () => {
        await handleSubmit();
        setSubmitForm(false)
      })();
    }
  }, [submitForm]);

  const handleSubmit = async () => {

    const data = {};
    Object.entries(fields).forEach(([key, value]) => {
      data[key] = value.current.raw;
    });

    props.handleSubmit(data)
  };

  return (
    <Form title={props.title}>
      <form onSubmit={validateForm} noValidate>
        <FormSection title={'Dados da Importação'} divider>
          <Grid container justify="flex-start" spacing={4}>
            <Grid item className={classes.radioGroup}>
              <RfFileUpload
                required
                ref={fields.file}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                type="text"
                ref={fields.description}
                fullWidth
                className={classes.fullWidthField}
                label="Descrição"
                name="description"
                disabled={formDisabled}
              />
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title={'Detalhes do Processamento'}>
          <Grid container justify="flex-start" spacing={4}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <TextField
                type="text"
                ref={fields.status}
                fullWidth
                className={classes.fullWidthField}
                label="Status do Processamento"
                name="status"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <TextField
                type="text"
                ref={fields.processingDetails}
                fullWidth
                className={classes.fullWidthField}
                label="Detalhes do Processamento"
                name="details"
                disabled={true}
              />
            </Grid>
          </Grid>
        </FormSection>

        <Grid container justify="flex-end" spacing={1}>

          {props.handleCancel && (
            <Grid item xs={12} md="auto">
              <ButtonCancel
                onClick={props.handleCancel}
                disabledCondition={formDisabled}
              ></ButtonCancel>
            </Grid>
          )}

          {props.handleRemove && (
            <Grid item xs={12} md="auto">
              <ButtonRemove
                onClick={props.handleRemove}
                conditionDisabled={formDisabled}
              ></ButtonRemove>
            </Grid>
          )}

          {props.handleDownload && (
            <Grid item xs={12} md="auto">
              <ButtonDownload
                onClick={props.handleDownload}
                conditionDisabled={formDisabled}
              ></ButtonDownload>
            </Grid>
          )}

          {props.handleSubmit && (
            <Grid item xs={12} md="auto">
              <ButtonSubmit conditionDisabled={formDisabled}></ButtonSubmit>
            </Grid>
          )}
        </Grid>
      </form>
    </Form>
  );
};

const styles = theme => ({
  btnRoot: {
    minWidth: 125,
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});
export default withStyles(styles)(RfImportForm);
