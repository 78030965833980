import React from 'react';
import { withStyles } from '@material-ui/core';

import SubRoutes from "components/SubRoutes";
import EditGeneralParameters from "pages/Admin/GeneralParameters/Edit";

function GeneralParameters(props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "GeneralParameters",
      path: '/',
      component: EditGeneralParameters,
    }
  ]

  return (
    <div>
      <SubRoutes
        setBreadcrumbs={setBreadcrumbs}
        defaultPage={0}
        pages={pages}
        match={match}
      />
    </div>
  )
}

const styles = theme => ({
  /** */
});

export default withStyles(styles)(GeneralParameters);
