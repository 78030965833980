import { useState, useEffect, useCallback } from 'react';
import { get } from '../services/fetch';
import { logoutUser } from '../services/user';
import { buildQuery } from 'utils/query';
import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

export function useFetch(path, {
  httpMethod, beforeFetch, afterFetch, reload, defaultValue,
}) {
  const [data, setData] = useState(defaultValue || false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (beforeFetch) {
        beforeFetch();
      }
      try {
        let response;
        if (!httpMethod) {
          response = await get(path);
        } else {
          response = httpMethod.method(path, httpMethod.data);
        }
        setData(response);
        if (afterFetch) {
          afterFetch();
        }
      } catch (err) {
        setIsError(err);
      }
    };
    fetchData();
  }, [path, reload]);
  return { data, isError };
}

export function useAuthenticated(hook, path, params) {
  const response = hook(path, params);
  if (response.isError.statusCode === 401) {
    const logout = async () => {
      await logoutUser();
      window.location.replace('/');
    };
    logout();
  }
  return response;
}

export function useQuery(path, dataFn, fields, initialFilter = {}, checkBeforeFetch, refresh, options={}) {
  if (fields && fields.length) {
    initialFilter['fields'] = fields;
  }

  const [query, setQuery] = useState(buildQuery(path, initialFilter));
  const [isLoading, setIsLoading] = useState(true);

  const fn = async (query) => {
    try {
      if (checkBeforeFetch) {
        if (!checkBeforeFetch(query)) {
          dataFn([]);
          setIsLoading(false);
          return;
        }
      }

      emitEvent('showGlobalLinearProgress');
      setIsLoading(true);

      let queryString = query.queryString;
      if(options.removeFilters) {
        const newObject = {...query.queryObject};
        for(const key of Object.keys(query.queryObject.filter)) {
          if(options.removeFilters.includes(key)) {
            delete newObject.filter[key];
          }
        }
        const newQuery = buildQuery({search: ''}, newObject);
        queryString = newQuery.queryString;
      }
      const response = await fetchAuthenticated('GET', `${path}${queryString}`);

      if (!response.ok) {
        emitEvent('showSnack', {
          message: 'Erro ao carregar dados',
          type: 'error',
        });
      } else {
        dataFn(await response.json());
      }
    } catch (err) {
      console.error('Response error');
      console.error(err);
    } finally {
      setIsLoading(false);
      emitEvent('hideGlobalLinearProgress');
    }
  }

  useEffect(() => {
    fn(query);
  }, [query, refresh]);

  return [query, setQuery, isLoading, fn];
}
