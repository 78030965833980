import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import {
  Grid,
  Paper,
  DialogContentText,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from '@material-ui/core';

import theme from 'styles/theme';
import { fetchAuthenticated } from 'services/fetch';
import { buildUrl, defaultQuery, buildQuery } from 'utils/query';
import { emitEvent } from 'utils/events';

import DatePicker from 'components/pickers/DatePicker';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import PoffRenewModal from './PoffRenewModal';
import PoffRecycleModal from './PoffRecycleModal';
import {
  Delete,
  Block,
  Repeat,
  RestoreFromTrash,
  KeyboardArrowDown,
  SettingsBackupRestore,
} from '@material-ui/icons';
import { CheckboxColumn } from 'components/table/columns';

import Filter from 'components/filter/Filter';
import FilterHandler from 'utils/FilterHandler';
import FilterAutoComplete from 'components/filter/FilterAutoComplete';
import ModalConfirm from 'components/modals/ModalConfirm';

import { mapPoffStatus, datetimeFormatter, percentageFormatter } from 'utils/formatters';
import { useAccessLog } from 'hooks/logs';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
  paper: {
    borderRadius: theme.border.radius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  filters: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  button: {
    marginLeft: 20,
  },
  shrink: {
    color: theme.palette.gray.gray_5,
    fontWeight: 'bold',
    fontSize: 16,
    transform: 'translate(0, 1.5px)',
  },
}));

const FILTER_OPTIONS = [
  { label: 'Todos', value: 'all' },
  { label: 'Sem Poff', value: 'no-poff' },
  { label: 'Poff Agendado', value: 'received' },
  { label: 'Aguardando Ativação', value: 'scheduled' },
  { label: 'Ativos', value: 'active' },
  { label: 'Expirados', value: 'expired' },
  { label: 'Descartados', value: 'discarded' },
];

const isChecked = (value, checkedList) => checkedList.indexOf(value) > -1;

const toggleCheckbox = (value, checkedList) => {
  const index = checkedList.indexOf(value);
  const newList = [...checkedList];

  if (index > -1) {
    newList.splice(index, 1);
  } else {
    newList.push(value);
  }
  return newList;
};

const EQUIPMENTS_PATH = 'equipment';
function Poff(props) {
  useAccessLog('Acesso a ferramenta de POFF');
  const classes = useStyles();
  const { location, match, history } = props;

  const [path, setPath] = useState(buildUrl(EQUIPMENTS_PATH, location));
  // const [query, setQuery] = useState({
  //   queryString: '?filter={"status":{"$ne":"pre-register"}}',
  //   queryObject: {
  //     'status': { '$ne': 'pre-register' }
  //   }
  // });
  const [query, setQuery] = useState(defaultQuery);
  const [equipments, setEquipments] = useState(null);

  const [filter, setFilter] = useState(FILTER_OPTIONS[0].value);
  const [checkedList, setCheckedList] = useState([]);

  const [showPoffRenewModal, setShowPoffRenewModal] = useState(false);
  const [showDiscardConfirmModal, setShowDiscardConfirmModal] = useState(false);
  const [showRecycleConfirmModal, setShowRecycleConfirmModal] = useState(false);
  const [showRestoreSettingsConfirmModal, setShowRestoreSettingsConfirmModal] =
    useState(false);

  const filterHandler = new FilterHandler(query, setQuery, location);

  // Initial filter setup
  useEffect(() => {
    filterHandler.updateQueryFilter({
      status: { $ne: 'pre-register' },
      'baitType.name': {
        $in: ['Descartável', 'Retornável', 'Teste', 'Customizada'],
      },
    });
  }, []);

  // Load data
  useEffect(() => {
    const queryPath = `${EQUIPMENTS_PATH}${query.queryString || ''}`;
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const data = await (await fetchAuthenticated('get', queryPath)).json();
      setEquipments(data);
      setCheckedList([]);
      emitEvent('hideGlobalLinearProgress');
    };
    fn();
  }, [query]);

  const onChange = (value, checkedList) => {
    setCheckedList(toggleCheckbox(value, checkedList));
  };

  const onCheckAll = (checkedList, allOptions) => {
    if (checkedList.length === allOptions.length) {
      setCheckedList([]);
    } else {
      setCheckedList(allOptions);
    }
  };

  const COLUMNS = [
    {
      component: row => (
        <CheckboxColumn
          onChange={() => {
            onChange(row.imei, checkedList);
          }}
          checked={isChecked(row.imei, checkedList)}
        />
      ),
      label: () => (
        <CheckboxColumn
          onChange={() =>
            onCheckAll(
              checkedList,
              equipments.data.map(e => e.imei)
            )
          }
          checked={checkedList.length === equipments.data.length}
        />
      ),
      cellStyle: {
        width: '30',
      },
    },
    { value: row => row.shortImei, label: 'IMEI', sort: 'shortImei' },
    {
      value: row =>
        row.currentCompany ? row.currentCompany.name : 'Desconhecida',
      label: 'Empresa',
      sort: 'currentCompany.name',
    },
    {
      value: row => {
        if(row.lastEvent && row.lastEvent.timestamp) {
          if(typeof row.lastEvent.timestamp === 'string') {
            let date = row.lastEvent.timestamp.split('T')[0]
            let hour = row.lastEvent.timestamp.split('T')[1].split('.')[0]

            let year = date.split('-')[0]
            let month = date.split('-')[1]
            let day = date.split('-')[2]

            return `${day}/${month}/${year} ${hour}`
          } else {
            return ' - '
          }
        } else {
          return ' - '
        }
      },
      label: 'Última Transmissão',
      sort: 'lastEvent.timestamp',
    },
    {
      label: 'Bat.',
      value: row =>
        (row.lastEvent &&
          `${percentageFormatter(
            Number(
              (row.lastEvent.adjustedInternalBatteryPercentage ||
                row.lastEvent.internalBatteryPercentage) / 100
            )
          )}`) ||
        '-',
      sort: 'lastEvent.internalBatteryPercentage',
    },
    {
      value: row => (row.mobileOperator && row.mobileOperator.name) || '',
      label: 'Operadora',
      sort: 'mobileOperator.name',
    },
    {
      value: row => row.equipmentType.name,
      label: 'Equipamento',
      sort: 'equipmentType.name',
    },
    {
      value: row => (row.baitType && row.baitType.name) || '',
      label: 'Tipo',
      sort: 'baitType.name',
    },
    {
      label: 'Status Eqp.',
      value: row => {
        switch (row.status) {
          case 'registered':
            return 'Registrado';
          case 'discarded':
            return 'Descartado';
          case 'pre-register':
            return 'Pré-registro';
          default:
            return '-';
        }
      },
    },
    {
      label: 'Status Poff',
      value: row => mapPoffStatus(row.poffCommands[0]),
    },
    {
      label: 'Início Poff',
      value: row =>
        (row.poffCommands.length &&
          datetimeFormatter(row.poffCommands[0].startedOn)) ||
        '-',
    },
    {
      label: 'Período Poff',
      value: row =>
        `${
          row.poffCommands.length && row.poffCommands[0].status != 'RESTORED'
            ? row.poffCommands[0].runningDays
            : '-'
        }/${
          row.poffCommands.length && row.poffCommands[0].status != 'RESTORED'
            ? row.poffCommands[0].totalDays
            : '-'
        }`,
    },
    {
      label: 'ICCID',
      value: row => `${row.iccid || '-'}`,
    },
  ];

  const onDateSelected = ({ id, date, filterHandler }) => {
    switch (id) {
      case 'initialDate':
        if (date) {
          filterHandler.updateQueryFilter({ expirationStartDate: date });
        } else {
          filterHandler.removeFilter('expirationStartDate');
        }
        break;
      case 'finalDate':
        if (date) {
          filterHandler.updateQueryFilter({ expirationFinalDate: date });
        } else {
          filterHandler.removeFilter('expirationFinalDate');
        }
        break;
      default:
        console.log(date, id);
    }
  };

  const handleCompanySelect = ({
    opt,
    filterHandler,
    setIsLoading,
    isLoading,
  }) => {
    if (opt) {
      filterHandler.updateQueryFilter({
        'currentCompany._id': { value: opt ? opt._id : '', toObjectId: false },
      });
    } else {
      filterHandler.removeFilter('currentCompany._id');
    }
    setIsLoading({ ...isLoading, equipments: true });
  };

  const handleStatusSelect = event => {
    setFilter(event.target.value);
    switch (event.target.value) {
      case 'received':
        filterHandler.removeFilters(['$or']);
        filterHandler.updateQueryFilter({
          status: 'registered',
          'poffCommands.0.status': 'RECEIVED',
        });
        break;

      case 'scheduled':
        filterHandler.removeFilters(['$or']);
        filterHandler.updateQueryFilter({
          status: 'registered',
          'poffCommands.0.status': 'SCHEDULED',
        });
        break;

      case 'expired':
        filterHandler.removeFilters(['$or']);
        filterHandler.updateQueryFilter({
          status: 'registered',
          'poffCommands.0.status': 'COMPLETED',
        });
        break;

      case 'discarded':
        filterHandler.removeFilters(['poffCommands.0.status', '$or']);
        filterHandler.updateQueryFilter({ status: 'discarded' });
        break;

      case 'active':
        filterHandler.updateQueryFilter({
          status: 'registered',
          'poffCommands.0.status': 'RUNNING',
        });
        break;

      case 'no-poff':
        filterHandler.removeFilters(['poffCommands.0.status']);
        filterHandler.updateQueryFilter({
          status: 'registered',
          $or: [
            { poffCommands: { $eq: [] } },
            {
              $or: [
                { 'poffCommands.0.status': 'CANCELED' },
                { 'poffCommands.0.status': 'RESTORED' },
              ],
            },
          ],
        });
        break;

      case 'all':
      default:
        filterHandler.removeFilters(['status', 'poffCommands.0.status', '$or']);
        filterHandler.updateQueryFilter({
          status: { $ne: 'pre-register' },
        });
        break;
    }
  };

  const handlePoffRenew = () => {
    setShowPoffRenewModal(true);
  };

  const handlePoffCancel = async equipments => {
    const action = 'cancel';
    const response = await fetchAuthenticated('post', 'equipment/poff', {
      equipments,
      action,
    });

    if (response.ok) {
      logAction('Comando POFF cancelado');
      emitEvent('showSnack', {
        message: 'Ação realizada com sucesso',
        type: 'success',
      });
    } else {
      emitEvent('showSnack', {
        message: 'Erro ao realizar ação',
        type: 'error',
      });
    }
  };

  const updateCurrentList = async () => {
    const data = await (
      await fetchAuthenticated(
        'get',
        `${EQUIPMENTS_PATH}${query.queryString || ''}`
      )
    ).json();
    setEquipments(data);
    setCheckedList([]);
  };

  const handleEquipmentDiscard = async equipmentsChecked => {
    const response = await fetchAuthenticated('post', 'equipment/discard', {
      equipments: equipmentsChecked,
    });

    if (response.ok) {
      const totalEquipments = equipmentsChecked.length;

      if (
        totalEquipments === equipments.limit &&
        equipments.page === equipments.totalPages
      ) {
        setQuery(
          buildQuery(location, {
            ...query.queryObject,
            page: equipments.page - 1,
          })
        );
      }

      const data = await (
        await fetchAuthenticated(
          'get',
          `${EQUIPMENTS_PATH}${query.queryString || ''}`
        )
      ).json();
      setEquipments(data);
      setCheckedList([]);
      setShowDiscardConfirmModal(false);
      logAction('Equipamentos descartados');
      emitEvent('showSnack', {
        message: 'Ação realizada com sucesso',
        type: 'success',
      });
    } else {
      emitEvent('showSnack', {
        message: 'Erro ao realizar ação',
        type: 'error',
      });
      setShowDiscardConfirmModal(false);
    }
  };

  const handleEquipmentSettingsRestore = async equipments => {
    const response = await fetchAuthenticated(
      'post',
      'equipment/restore-poff',
      {
        equipments,
      }
    );

    if (response.ok) {
      const data = await (
        await fetchAuthenticated(
          'get',
          `${EQUIPMENTS_PATH}${query.queryString || ''}`
        )
      ).json();
      setEquipments(data);
      setCheckedList([]);
      setShowRestoreSettingsConfirmModal(false);
      logAction('Configurações de POFF restauradas');
      emitEvent('showSnack', {
        message: 'Ação realizada com sucesso',
        type: 'success',
      });
    } else {
      emitEvent('showSnack', {
        message: 'Erro ao realizar ação',
        type: 'error',
      });
      setShowRestoreSettingsConfirmModal(false);
    }
  };

  const handleEquipmentRecycle = async equipments => {
    const status = 'registered';
    const response = await fetchAuthenticated('post', 'equipment/recycle', {
      equipments,
      status,
    });

    if (response.ok) {
      const data = await (await fetchAuthenticated('get', path)).json();
      setEquipments(data);
      setCheckedList([]);
      setShowRecycleConfirmModal(false);
      emitEvent('showSnack', {
        message: 'Ação realizada com sucesso',
        type: 'success',
      });
    } else {
      setShowRecycleConfirmModal(false);
      emitEvent('showSnack', {
        message: 'Erro ao realizar ação',
        type: 'error',
      });
    }
  };

  const handleResetPoff = async equipments => {
    equipments.forEach(eq => {
      fetchAuthenticated('post', 'equipment-event/command', {
        imei: eq,
        status: {
          expirationClockReset: true,
        },
      });
    });
  };

  const handleExportXLS = async query => {
    console.log('Handle export xls');
    console.log(query);

    try {
      const response = await fetchAuthenticated(
        'GET',
        `equipment/report/expired/xls${query.queryString}`
      );

      let filterType = FILTER_OPTIONS.filter(opt => opt.value === filter)[0];
      filterType = filterType.label.replace(' ', '_');

      const blob = await response.blob();
      saveAs(blob, `Relatorio_${filterType}.xls`);
      logAction('Exportou relatório de expirados em XLS');
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const filterFields = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Empresa"
          showPlaceholder
          hideLabel
          path="company?notPaginated=true"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={handleCompanySelect}
        />
      ),
    },
    {
      name: 'equipments',
      component: args => {
        return (
          <FilterAutoComplete
            {...args}
            menuContainerStyle={{
              marginTop: 5,
            }}
            placeholder="Equipamentos"
            showPlaceholder={true}
            hideLabel={true}
            path="equipment?notPaginated=true&fields=_id,imei&"
            additionalStartPath='filter={"isActive":true}'
            name="equipments"
            getOptionLabel={opt => opt.imei}
            loadingMessage="Carregando equipamentos..."
            noOptionsMessage="Nenhum equipamento encontrado."
            onChange={args => {
              const { opt, filterHandler } = args;
              if (!opt) {
                filterHandler.removeFilter('_id');
              } else {
                filterHandler.updateQueryFilter({
                  currentCompany: { $exists: true },
                  _id: opt._id,
                  isActive: true,
                });
              }
            }}
            whenIsLoading={({ path: defaultPath, setFetchUrl, fields }) => {
              const companyField = fields.companies.current.value;
              if (companyField) {
                const _filter = {
                  'currentCompany._id': {
                    value: companyField._id,
                    toObjectId: false,
                  },
                  isActive: true,
                };
                setFetchUrl(defaultPath + `filter=${JSON.stringify(_filter)}`);
              } else {
                setFetchUrl(
                  defaultPath + `filter=${JSON.stringify({ isActive: true })}`
                );
              }
            }}
          />
        );
      },
    },
    // {
    //   name: 'initialDate',
    //   component: args => (
    //     <DatePicker
    //       placeholder="Data início"
    //       onChange={date => onDateSelected({ id: 'initialDate', date, ...args })}
    //     />
    //   ),
    // },
    // {
    //   name: 'finalDate',
    //   component: args => (
    //     <DatePicker
    //       placeholder="Data término"
    //       onChange={date => onDateSelected({ id: 'finalDate', date, ...args })}
    //       className={classes.filters}
    //     />
    //   ),
    // },
  ];

  const EquipmentFilter = props => {
    const classes = useStyles();
    const { value, onChange, options, query } = props;
    return (
      <FormControl>
        <InputLabel classes={{ shrink: classes.shrink }}>
          Equipamentos
        </InputLabel>
        <Select
          disableUnderline
          IconComponent={KeyboardArrowDown}
          style={{ marginTop: 20 }}
          onChange={e => onChange(e, query)}
          value={value}
        >
          {options.map(o => (
            <MenuItem value={o.value} key={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <Filter
          containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
          asyncFields={filterFields}
          noSearch
          hideButton
          path={path}
          query={query}
          setQuery={setQuery}
          filterHandler={filterHandler}
        />
      </Grid>

      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Table
          columns={COLUMNS}
          data={equipments}
          query={query}
          setQuery={setQuery}
          onRowClick={row => history.push(`${match.path}/${row._id}`)}
          HeaderComponent={
            <TableHeader
              headerFilters={[
                <EquipmentFilter
                  value={filter}
                  query={query}
                  options={FILTER_OPTIONS}
                  onChange={handleStatusSelect}
                />,
              ]}
              headerActions={[
                <TableHeaderAction
                  title="Enviar/Renovar Poff"
                  Icon={Repeat}
                  disabled={
                    checkedList.length === 0 ||
                    filter === 'all' ||
                    filter === 'discarded' ||
                    filter === 'scheduled'
                  }
                  onClick={handlePoffRenew}
                />,
                <TableHeaderAction
                  title="Cancelar Poff"
                  Icon={Block}
                  disabled={checkedList.length === 0 || filter !== 'received'}
                  onClick={() => handlePoffCancel(checkedList)}
                />,
                <TableHeaderAction
                  title="Descartar equipamentos"
                  Icon={Delete}
                  disabled={
                    checkedList.length === 0 ||
                    filter === 'all' ||
                    filter === 'scheduled'
                  }
                  onClick={() => setShowDiscardConfirmModal(true)}
                />,
                <TableHeaderAction
                  title="Reativar equipamentos"
                  Icon={RestoreFromTrash}
                  disabled={checkedList.length === 0 || filter !== 'discarded'}
                  onClick={() => setShowRecycleConfirmModal(true)}
                />,
                <TableHeaderAction
                  title="Restaurar configurações de POFF"
                  Icon={SettingsBackupRestore}
                  disabled={
                    checkedList.length === 0 ||
                    (filter !== 'active' && filter !== 'expired')
                  }
                  onClick={() => setShowRestoreSettingsConfirmModal(true)}
                />,
                <TableHeaderAction
                  title="Exportar XLS"
                  IconProps={{ style: theme.custom.icon }}
                  Icon={props => <Typography {...props}>XLS</Typography>}
                  disabled={equipments && equipments.totalItems === 0}
                  onClick={() => handleExportXLS(query)}
                />,
              ]}
            />
          }
        />
      </Paper>
      {showPoffRenewModal && (
        <PoffRenewModal
          onClose={() => setShowPoffRenewModal(false)}
          isVisible={showPoffRenewModal}
          equipments={checkedList}
        />
      )}
      {showDiscardConfirmModal && (
        <ModalConfirm
          isOpen={showDiscardConfirmModal}
          setIsOpen={setShowDiscardConfirmModal}
          onConfirm={() => handleEquipmentDiscard(checkedList)}
          onCancel={() => setShowDiscardConfirmModal(false)}
          title="Confirmar descarte de equipamentos?"
          confirmButtonLabel="Sim, descartar"
        >
          <DialogContentText>
            Os seguintes equipamentos serão descartados e desativados
          </DialogContentText>
          {checkedList.map(imei => (
            <DialogContentText>IMEI: {imei}</DialogContentText>
          ))}
        </ModalConfirm>
      )}

      {showRecycleConfirmModal && (
        <PoffRecycleModal
          onClose={update => {
            setShowRecycleConfirmModal(false);
            update && updateCurrentList();
          }}
          isVisible={showRecycleConfirmModal}
          equipments={checkedList}
        />
      )}
      {showRestoreSettingsConfirmModal && (
        <ModalConfirm
          isOpen={showRestoreSettingsConfirmModal}
          setIsOpen={setShowRestoreSettingsConfirmModal}
          onConfirm={() => handleEquipmentSettingsRestore(checkedList)}
          onCancel={() => setShowRestoreSettingsConfirmModal(false)}
          title="Confirmar restauração de POFF?"
          confirmButtonLabel="Sim, restaurar"
        >
          <DialogContentText>
            Os seguintes equipamentos terão as configurações de POFF restauradas
          </DialogContentText>
          {checkedList.map(imei => (
            <DialogContentText>IMEI: {imei}</DialogContentText>
          ))}
        </ModalConfirm>
      )}
    </Grid>
  );
}

export default Poff;