import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import CircularLoading from 'components/loading/CircularLoading';
import IconButton from '@material-ui/core/IconButton';
import { Delete } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { AutocompleteField, RadioGroupField } from 'components/fields';
import AutoCompleteGray from "components/fields/AutoCompleteGray";
import ModalConfirm from 'components/modals/ModalConfirm';
import ModalPopup from 'components/modals/ModalPopup';
import { ButtonSubmit } from 'components/buttons/ButtonSubmit';
import { fetchAuthenticated } from 'services/fetch';
import { useFields } from 'hooks/fields';
import { emitEvent } from 'utils/events';
import CheckboxList from 'components/lists/CheckboxList';
import RightIcon from 'assets/icon/right.png';
import { useAccessLog } from 'hooks/logs';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.custom.page.root,
    flexDirection: 'column',
    display: 'flex',
  },
  paperRoot: {
    ...theme.custom.paper.root,
    height: '100%',
    // marginBottom: theme.main.padding.bottom,
  },
  title: {
    color: theme.palette.gray.gray_4,
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 20,
  },
  fieldTitle: {
    color: theme.palette.gray.gray_5,
    fontWeight: 'bold',
    fontSize: 16,
  },
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
  transferContainer: {
    flexWrap: 'nowrap',
    marginTop: 16,
    height: '100%',
  },
  textarea: {
    height: '70%',
    borderRadius: 22,
    border: 'solid 1px #808492',
    resize: 'none',
    outline: 'none',
    padding: 22,
    fontFamily: 'Raleway',
    fontSize: 15,
    width: '100%',
    fontWeight: '400',
  },
  iconContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
    justifyContent: 'center',
  },
  activeIcon: {
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.background.white,
    width: 54,
    height: 54,
    padding: 0,
    marginRight: 11,
    marginLeft: 11,
    '&:disabled': {
      backgroundColor: theme.palette.background.white,
      opacity: 0.5,
    }
  },
  topContainerPaper: {
    borderRadius: 22,
    paddingTop: 25,
    paddingBottom: 25,
    paddingRight: 32,
    paddingLeft: 32,
    flexDirection: 'column',
    display: 'flex',
  },
  centerContainer: {
  },
  placeholder: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  equipmentContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  itemContainer: {
    display: 'flex',
    marginRight: 30,
    height: 'fit-content',
    alignItems: 'center',
  },
  equipmentLabel: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
  },
  missingText: {
    fontSize: 16,
    color: '#41475a',
    fontWeight: 'normal',
  }
}));

function TransferOption(props) {
  const { companyRef, transferType, setTransferType, companyToTransfer, setCompanyToTransfer } = props;
  const classes = useStyles();
  // Load Companies
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);

  useEffect(() => {
    const fn = async () => {
      setLoadingCompanies(true);
      const data = await (await fetchAuthenticated('get', 'company?notPaginated=true&fields=_id,name')).json();
      setCompanies(data.data);
      setLoadingCompanies(false);
    }; fn();
  }, []);


  return (
    <Grid>
      <Paper elevation={0} classes={{ root: classes.topContainerPaper }}>
        {/* Title */}
        <Grid container style={{ flexDirection: 'column' }}>
          <Typography className={classes.title}>Transferir equipamentos</Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography className={classes.fieldTitle} style={{ marginBottom: 16 }}>Tipo de transferência</Typography>
              <RadioGroupField
                onChange={(e) => {
                  setTransferType(e.target.value);
                }}
                row
                required
                default={transferType}
                options={[
                  { value: 'Manual', label: 'Manual' },
                  { value: 'Por empresa', label: 'Por empresa' },
                ]}
                labelProps={{
                  style: {
                    transform: 'translate(0, 1.5px) scale(0.75)',
                    transformOrigin: 'top left',
                  },
                }}
                formControlLabelProps={{
                  labelPlacement: 'start',
                }}
                marginLeft={44}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 73, minWidth: 273 }}>
              <Typography className={classes.fieldTitle}>Destino</Typography>
              <AutocompleteField
                innerRef={companyRef}
                label='Empresa'
                name='company'
                options={companies}
                isLoading={loadingCompanies}
                onChange={(v) => {
                  setCompanyToTransfer(v);
                }}
                getOptionLabel={opt => opt.name}
                getOptionValue={opt => opt._id}
                noOptionsMessage={() => 'Nenhuma empresada encontrada.'}
                loadingMessage={() => 'Carregando empresas...'}
                isClearable={true}
              />
            </div>
          </div>
        </Grid>

      </Paper>
    </Grid>
  );
}

function TransferEquipment(props) {
  useAccessLog('Acesso a ferramenta de transferência de equipamentos');
  const classes = useStyles();
  const companyRef = React.createRef(null);
  const [transferType, setTransferType] = useState("Manual");
  const [text, setText] = useState("");
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [loadingEquipments, setLoadingEquipments] = useState(false);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [preSelectedEquipments, setPreSelectedEquipments] = useState([]);
  const [equipmentsMissing, setEquipmentsMissing] = useState([]);
  const [companyToTransfer, setCompanyToTransfer] = useState(null);
  const [refreshCheckbox, setRefreshCheckbox] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [originCompany, setOriginCompany] = useState({});

  const getImei = (item) => {
    return item.shortImei;
  }

  const handleRemoveOne = (item) => {
    setSelectedEquipments(selectedEquipments.filter((e) => e._id !== item._id));
  }

  const onChange = (e) => {
    setText(e.target.value);
  }

  const loadEquipments = async (company) => {
    if (!company) { setEquipments([]); }
    else {
      setOriginCompany(company)
      setLoadingEquipments(true);
      const _filter = {
        'currentCompany._id': {
          value: company._id,
          toObjectId: false,
        }
      };
      const data = await (await fetchAuthenticated('get', `equipment?notPaginated=true&fields=_id,imei,shortImei,currentCompany&filter=${JSON.stringify(_filter)}`)).json();
      setEquipments(data.data);
      setLoadingEquipments(false);
    }
  }

  useEffect(() => {
    const preSelected = equipments.filter(e => e.checked);
    setPreSelectedEquipments(preSelected);
  }, [equipments]);

  const isIconDisabled = () => {
    if (transferType === 'Manual') {
      return text === '';
    } else {
      return preSelectedEquipments.length === 0;
    }
  }

  const getMissingEquipments = (selected, found) => {
    const missing = [];
    selected.forEach(s => {
      let isFound = false;
      found.forEach(f => {
        if (s === f.shortImei) isFound = true;
      })
      if (!isFound) {
        missing.push(s);
      }
    });
    return missing;
  }

  const onTransfer = async () => {
    const newEquipments = [];

    // filter by dupplicated equipments
    const pushNotDupplicated = (list) => {
      list.forEach(e => {
        if (!selectedEquipments.some(item => e._id === item._id)) {
          newEquipments.push(e)
        }
      });
    }

    if (transferType === 'Por empresa') {
      pushNotDupplicated(preSelectedEquipments);
    }

    if (transferType === 'Manual') {
      setIsTransferLoading(true);
      const shortImeis = text.split('\n');
      const filter = {
        shortImei: { $in: shortImeis },
      };
      const data = await (await fetchAuthenticated('get', `equipment/list?filter=${JSON.stringify(filter)}`)).json();
      const missingEq = getMissingEquipments(shortImeis, data);
      if (missingEq.length > 0) {
        setEquipmentsMissing(missingEq);
        setIsModalVisible(true);
      }
      pushNotDupplicated(data);
      setIsTransferLoading(false);
    }
    setSelectedEquipments([
      ...selectedEquipments,
      ...newEquipments,
    ]);
    setPreSelectedEquipments([]);
  }

  const onSubmit = async () => {
    setIsTransferLoading(true);
    try {
      const response = await fetchAuthenticated('post', 'equipment/transfer', {
        equipments: selectedEquipments.map(e => e._id),
        company: companyToTransfer
      });

      if (!response.ok) {
        throw new Error('Response not ok');
      } else {

        const logMessages = [];

        for(const selectedEquipment of selectedEquipments) {
          let currentCompany = ''

          if('currentCompany' in selectedEquipment && 'name' in selectedEquipment['currentCompany']) {
            currentCompany = selectedEquipment['currentCompany']['name']
          } else {
            currentCompany = 'Empresa não associada'
          }

          logMessages.push({description: `Transferir Equipamentos / De: "${currentCompany}" Para: "${companyToTransfer['name']}"`, equipment: selectedEquipment.imei})
        }

        logAction(null, null, logMessages)

        emitEvent('showSnack', { message: 'Equipamentos transferidos com sucesso.', type: 'success' });
        setPreSelectedEquipments([]);
        setSelectedEquipments([]);
        setEquipments([]);
        setCompanyToTransfer(null);
      }
    } catch (err) {
      console.log(err);
      emitEvent('showSnack', { message: 'Erro ao tentar transferir equipamentos.', type: 'error' });
    } finally {
      setIsTransferLoading(false);
    }
  }

  return (
    <div className={classes.root}>
      <CircularLoading isLoading={isTransferLoading} />
      <ModalPopup isVisible={isModalVisible} setIsVisible={setIsModalVisible} title='Equipamentos não encontrados'>
        <Typography className={classes.missingText}>
          {`${equipmentsMissing.length === 1 ? 'Não foi encontrado ' : 'Não foram encontrados '}`}
          <span style={{ fontWeight: 'bold' }}>{`${equipmentsMissing.length} ${equipmentsMissing.length === 1 ? 'equipamento' : 'equipamentos'}:`}</span>
        </Typography>
        {equipmentsMissing.map((e, index) => (
          <Typography key={e + index} className={classes.missingText} style={{ marginTop: 5 }}>
            {e}
          </Typography>
        ))}
      </ModalPopup>
      <TransferOption
        companyRef={companyRef}
        transferType={transferType}
        setTransferType={setTransferType}
        companyToTransfer={companyToTransfer}
        setCompanyToTransfer={setCompanyToTransfer}
      />
      <Grid container direction="row" alignItems="stretch" className={classes.transferContainer}>
        <Grid item style={{ width: 502, minWidth: 502 }} container direction="row">
          <Paper elevation={0} classes={{ root: classes.paperRoot }} style={{ maxWidth: 426, position: 'relative' }}>
            <Typography className={classes.title}>Transferir equipamentos</Typography>
            {transferType === "Manual" &&
              <textarea onChange={onChange} placeholder="Digite o IMEI dos equipamentos desejados" className={classes.textarea} rows="1">
              </textarea>
            }

            {transferType === "Por empresa" &&
              <>
                <AutoCompleteGray
                  menuContainerStyle={{
                    marginTop: 5
                  }}
                  fetchUrl='company?notPaginated=true&fields=_id,name'
                  placeholder='Buscar empresa'
                  showPlaceholder={true}
                  hideLabel={true}
                  name='company'
                  getOptionLabel={(opt) => opt.name}
                  loadingMessage="Carregando empresas..."
                  noOptionsMessage="Nenhuma empresa encontrada."
                  onChange={
                    ({ opt }) => {
                      loadEquipments(opt);
                      setRefreshCheckbox(!refreshCheckbox);
                    }
                  }
                />
                <PerfectScrollbar>
                  <CheckboxList
                    data={equipments}
                    setData={setEquipments}
                    getName={(item) => getImei(item)}
                    refreshCheckAll={refreshCheckbox}
                    labelStyle={{
                      fontSize: 16,
                      color: '#41475a',
                      fontWeight: 'normal',
                    }}
                    containerStyle={{
                      overflowY: 'scroll',
                      marginTop: 26,
                      maxHeight: 252,
                    }}
                    showCheckAll={equipments.length > 0}
                  />
                  {loadingEquipments &&
                    <CircularLoading isLoading={loadingEquipments} />
                  }
                  {equipments.length === 0 &&
                    <Typography className={classes.placeholder}>Nenhum equipamento encontrado.</Typography>
                  }
                </PerfectScrollbar>
              </>
            }
          </Paper>
          <div className={classes.iconContainer} >
            <IconButton
              title={'Transferir equipamentos'}
              disabled={isIconDisabled()}
              classes={{
                root: classes.activeIcon,
              }}
              onClick={onTransfer}>
              <img src={RightIcon} />
            </IconButton>
          </div>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Paper elevation={0} classes={{ root: classes.paperRoot }}>
            <Typography className={classes.title}>Confirmar transferência</Typography>
            {selectedEquipments.length === 0 &&
              <div className={classes.centerContainer}>
                <Typography className={classes.placeholder}>Nenhum equipamento selecionado para transferência.</Typography>
              </div>
            }
            <PerfectScrollbar>
              {selectedEquipments.length > 0 &&
                <div className={classes.equipmentContainer}>
                  {selectedEquipments.map(item => (
                    <div key={item._id} className={classes.itemContainer}>
                      <Typography className={classes.equipmentLabel}>{getImei(item)}</Typography>
                      <IconButton
                        title="Remover item"
                        classes={{ root: classes.icon }}
                        onClick={() => handleRemoveOne(item)}>
                        <Delete />
                      </IconButton>
                    </div>
                  ))}
                </div>
              }
            </PerfectScrollbar>
            <div style={{ display: 'flex', flex: 1, alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <ButtonSubmit
                label='Transferir'
                onSubmit={onSubmit}
                disabledCondition={!(companyToTransfer && selectedEquipments.length > 0)}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default TransferEquipment;
