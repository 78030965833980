import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditErb from "pages/Records/Erb/Edit";
import ListErb from "pages/Records/Erb/List";
import NewErb from "pages/Records/Erb/New";

export default function Equipments (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Erb",
      path: '/novo',
      component: NewErb,
    },
    {
      name: "Erb",
      path: '/:id',
      component: EditErb,
    },
    {
      name: "Erb",
      path: '',
      component: ListErb
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
