import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { defaultProps } from 'react-select/lib/Async';
import {
  Grid,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  btnRoot: {
    minWidth: 125,
    boxShadow: 'unset',
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export function ButtonSubmit(props) {
  // const { disabledCondition } = props;
  const classes = useStyles();

  const disabledCondition = props.disabledCondition
  let colorSecondary = false

  if(props.hasOwnProperty('colorSecondary')) {
    colorSecondary = true
  }

  return (
    <Button
      classes={{ root: classes.btnRoot }}
      type="submit"
      color={colorSecondary ? 'secondary' : 'primary'}
      variant="contained"
      size="small"
      disabled={disabledCondition}
    >
      {props.label}
    </Button>
  );
}
ButtonSubmit.defaultProps = {
  label: 'Salvar',
}

export function ButtonRemove(props) {
  const { disabledCondition, onClick } = props;
  const classes = useStyles();

  return (
    <Button
      classes={{ root: classes.btnRoot }}
      size="small"
      color="secondary"
      variant="contained"
      onClick={onClick}
      disabled={disabledCondition}
    >
      Remover
    </Button>
  );
}

export function ButtonGeneric(props) {
  const { disabled, onClick } = props;
  const classes = useStyles();

  return (
    <Button className={classes.btnRoot} size="small" onClick={onClick} disabled={disabled}>
    {props.children}
    </Button>
  );
}

export function ButtonCancel(props) {
  const { disabledCondition, onClick } = props;
  const classes = useStyles();

  return (
    <Button className={classes.btnRoot} size="small" onClick={onClick} disabled={disabledCondition}>
      Cancelar
    </Button>
  );
}

export function CommonButton(props) {
  const { disabledCondition, onClick, color } = props;
  const classes = useStyles();

  return (
    <Button className={classes.btnRoot} size="small" color={color} variant="contained" onClick={onClick} disabled={disabledCondition}>
      {props.label}
    </Button>
  );
}

export function ButtonDownload(props) {
  const { disabledCondition, onClick } = props;
  const classes = useStyles();

  return (
    <Button className={classes.btnRoot} size="small" onClick={onClick} disabled={disabledCondition}>
      Baixar arquivo
    </Button>
  );
}

export function ButtonsForm(props) {
  const { formDisabled } = props;

  return (
    <Grid container justify="flex-end" spacing={1}>
      {props.handleCancel &&
        <Grid item xs={12} md={'auto'}>
          <ButtonCancel
            onClick={props.handleCancel}
            disabledCondition={formDisabled}
          ></ButtonCancel>
        </Grid>
      }
      {props.handleRemove &&
        <Grid item xs={12} md={'auto'}>
          <ButtonRemove
            onClick={props.handleRemove}
            conditionDisabled={formDisabled}
          ></ButtonRemove>
        </Grid>
      }
      {props.handleSubmit &&
        <Grid item xs={12} md={'auto'}>
          <ButtonSubmit
            conditionDisabled={formDisabled}
          ></ButtonSubmit>
        </Grid>
      }
    </Grid>
  );
}
