import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
  TextField,
  Grid
} from '@material-ui/core';
import { get, fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import Switch from '@material-ui/core/Switch';
import Modal from 'components/modals/Modal';
import { RemoveRedEye, VisibilityOff } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  followContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 51,
    borderBottom: 'solid 1px #80849d4d',
  },
  followHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  followTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  contact: {
    borderBottom: 'solid 1px #80849d4d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
  },
  splitContact: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newContactDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcon: {
    width: 47,
  },

}));

const ModalRequirePermission = (props) => {
  const { isVisible, setIsVisible, currentPassword, setCurrentPassword, password, setPassword, confirmPassword, setConfirmPassword, userData, hasUnsavedChanges, setHasUnsavedChanges } = props;
  const classes = useStyles();

  const [showModal, setShowModal] = useState(isVisible);

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  useEffect(() => {
    setPassword(userData.decryptedSignaturePassword);
  }, []);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleSubmit = async (data) => {

    if(password === '') {
      emitEvent('showSnack', { message: 'O campo "Senha" precisa ser preenchido', type: 'warning' });
      return
    }

    const formData = {
      username: data.username,
      newPassword: password
    }

    const response = await fetchAuthenticated('post', 'user/change-signature-password', formData);

    if(response.ok) {
      emitEvent('showSnack', { message: 'Assinatura Eletrônica cadastrada com sucesso!', type: 'success' });

      if(setIsVisible) {
        setIsVisible(false);
      }

      setHasUnsavedChanges(true);
    } else {
      emitEvent('showSnack', { message: 'Senha Atual Incorreta!', type: 'error' });
      return
    }
  }

  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  }

  return (
    <Modal
      handleSubmit={() => handleSubmit(props.userData)}
      onSuccessMessage="Assinatura Eletrônica atualizada com sucesso."
      isVisible={showModal} setIsVisible={setShowModal} title={"Assinatura Eletrônica"} buttonLabel="Salvar"
      onClose={onClose}
      preventDefaultSubmit
    >
      <Typography className={classes.subsubheader} style={{ marginTop: '-20px', marginBottom: '30px' }}>
        Cadastrar assinatura eletrônica para comandos GPRS
      </Typography>
      <Grid container justify="flex-start">
        {/* {
          'signaturePasswordIsEnabled' in props.userData && props.userData.signaturePasswordIsEnabled === true && props.userData.signaturePassword !== null ?
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              className={classes.textField}
              name={'current-password'}
              label="Senha Atual"
              value={currentPassword}
              type={"password"}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Grid>
          : null
        } */}
        {/* <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            className={classes.textField}
            name={'password'}
            label="Nova Senha"
            value={password}
            type={"password"}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            className={classes.textField}
            name={'password'}
            label="Senha"
            type={passwordIsVisible ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {
              passwordIsVisible ?
              <IconButton title="Visualizar senha" classes={{root: classes.icon}} onClick={() => setPasswordIsVisible(false)}><RemoveRedEye /></IconButton> :
              <IconButton title="Ocultar senha" classes={{root: classes.icon}} onClick={() => setPasswordIsVisible(true)}><VisibilityOff /></IconButton>
            }
        </Grid>
      </Grid>
    </Modal>
  )
}

export default ModalRequirePermission;
