import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Filter from "components/filter/Filter";
import FilterAutoComplete from "components/filter/FilterAutoComplete";
import DateTimePicker from "components/pickers/DateTimePicker";

import { defaultQuery, updateQueryFilter } from "utils/query";
import ReportFilterContext from "pages/Reports/ReportFilterContext";

const useStyles = makeStyles((theme) => ({
  filter: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
}));

function ReportFilters(props) {
  const classes = useStyles();
  const filterContext = useContext(ReportFilterContext);
  const {
    containerStyle,
    query,
    setQuery,
    path,
    secondPath,
    onInitialDateChange,
    onFinalDateChange,
    onCompanyChange,
    onEquipmentChange,
    values,
    secondQuery,
    setSecondQuery,
    ...rest
  } = props;

  const _onCompanyChange = ({
    opt,
    filterHandler,
    fields,
    isLoading,
    setIsLoading,
  }) => {
    fields.equipments.current.resetField();
    setIsLoading({ ...isLoading, equipments: true });
    onCompanyChange({ opt, filterHandler });
  };

  const _filters = [
    {
      name: "companies",
      component: (args) => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Empresa"
          showPlaceholder
          path="company?notPaginated=true&fields=_id,name"
          name="companies"
          getOptionLabel={(opt) => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={_onCompanyChange}
          value={values.company}
        />
      ),
    },
    {
      name: "equipments",
      component: (args) => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Equipamento"
          showPlaceholder
          path="equipment/list?fields=_id,imei,currentCompany"
          name="equipments"
          loadingMessage="Carregando equipamentos..."
          noOptionsMessage="Nenhum equipamento encontrado."
          getOptionLabel={(opt) => opt.imei}
          onChange={onEquipmentChange}
          value={values.equipment}
          whenIsLoading={({
            fetchUrl,
            path: defaultPath,
            setFetchUrl,
            fields,
          }) => {
            const companyField =
              fields.companies.current.value || filterContext.company;
            if (companyField) {
              setFetchUrl(
                updateQueryFilter(fetchUrl, {
                  "currentCompany._id": {
                    value: companyField._id,
                    toObjectId: false,
                  },
                })
              );
            } else {
              setFetchUrl(defaultPath);
            }
          }}
        />
      ),
    },
    {
      name: "initialDate",
      component: (args) => (
        <DateTimePicker
          {...args}
          placeholder="Data ínicio"
          onChange={onInitialDateChange}
          value={values.initialDate}
        />
      ),
    },
    {
      name: "finalDate",
      component: (args) => (
        <DateTimePicker
          {...args}
          placeholder="Data término"
          onChange={onFinalDateChange}
          className={classes.filter}
          value={values.finalDate}
        />
      ),
    },
  ];

  return (
    <Filter
      noSearch
      hideButton
      containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
      {...rest}
      path={path}
      secondPath={secondPath}
      query={query}
      setQuery={setQuery}
      secondQuery={secondQuery}
      setSecondQuery={setSecondQuery}
      asyncFields={_filters}
    />
  );
}

ReportFilters.defaultProps = {
  onInitialDateChange: () => null,
  onFinalDateChange: () => null,
  onCompanyChange: () => null,
  onEquipmentChange: () => null,
  path: "",
  secondPath: "",
  query: defaultQuery,
  setQuery: () => null,
  secondQuery: defaultQuery,
  setSecondQuery: () => null,
  containerStyle: { paddingLeft: 0, paddingRight: 0 },
  values: {},
};

export default ReportFilters;
