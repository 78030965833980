import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  paper: { ...theme.custom.paper.root, paddingTop: 16 },
  container: {
    display: 'flex',
    flex: 1,
    paddingBottom: theme.main.padding.bottom,
  },
  relative: {
    position: 'relative',
    width: '100%',
    height: '600px',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF88',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 700,
  },
}));

const ChartContainer = forwardRef((
  {
    children,
    isLoading,
    containerStyle,
    paperStyle,
    HeaderComponent,
  },
  ref,
) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={{ ...containerStyle }}>
      <Paper classes={{ root: classes.paper }} elevation={0} style={{ ...paperStyle }}>
        {HeaderComponent}
        <div className={classes.relative}>
          <div ref={ref} className={classes.absolute}>{children}</div>
          {isLoading && (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
});

ChartContainer.defaultProps = {
  isLoading: false,
  containerStyle: {},
  paperStyle: {},
  HeaderComponent: null,
};

export default ChartContainer;
