import React, { useState, useEffect } from "react";

import { withRouter } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  DialogContentText,
} from "@material-ui/core";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import ModalConfirm from "components/modals/ModalConfirm";
import CommandPv1GprsFields from "components/commands/CommandPv1GprsFields";
import BulkCommandPv2GprsFields from "components/commands/BulkCommandPv2GprsFields";
import Filter from "components/filter/Filter";
import FilterAutoComplete from "components/filter/FilterAutoComplete";
import SelectFilter from "components/filter/SelectFilter";
import { emitEvent } from "utils/events";
import ModalRequirePermission from "components/modals/ModalRequirePermission";
import { useEffectSkipFirst } from 'hooks/common';

import { fetchAuthenticated, put } from "services/fetch";
import { getField } from "utils/helpers";
import { useAccessLog } from "hooks/logs";
import { logAction } from "utils/logs";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    paddingTop: theme.main.padding.top,
    paddingBottom: 25,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  main: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: 15,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 15,
    },
  },
  paperRoot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    borderRadius: 22,
    padding: "25px 32px",
  },
  title: {
    color: theme.palette.gray.gray_4,
    fontWeight: theme.typography.fontWeightBold,
  },
  sendCommandBtn: {
    marginTop: 10,
    fontWeight: 600,
    [theme.breakpoints.up("md")]: {
      alignSelf: "flex-end",
    },
  },
  radioLabel: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.gray.gray_5,
  },
  input: {
    borderBottom: theme.input.borderStyle.borderBottom,
  },
  inputLabel: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.gray.gray_5,
    margin: 0,
  },
  focused: {
    color: theme.palette.gray.gray_5,
  },
  innerField: {
    marginLeft: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  innerRadio: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  radioLabelResponsive: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      marginLeft: 0,
    },
  },
  outerField: {
    marginLeft: 100,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 40,
    },
  },
  shrink: {
    color: theme.palette.gray.gray_5,
    fontWeight: "bold",
    fontSize: 16,
    transform: "translate(0, 1.5px)",
  },
  formControl: {
    marginTop: 20,
  },
  smallFullWidth: {
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  leftFilterButton: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 16,
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const initialFilters = {
  V1: [
    {
      label: "RF",
      value: -1,
      field: "rfTriangulation",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "Alarme beacon",
      value: -1,
      field: "status.decouplingSensorMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "Alarme movimento",
      value: -1,
      field: "status.movementSensorMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "Alarme temperatura",
      value: -1,
      field: "status.temperatureAlarmMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "Período de transmissão",
      value: -1,
      field: "positionSendInterval",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Emergência", value: 5 },
        { label: "Normal", value: 60 },
      ],
    },
  ],
  V2: [
    {
      label: "Modo GPS",
      value: -1,
      field: "status.gpsMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "RF (434MHZ)",
      value: -1,
      field: "status.transmitter434Mhz",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "RF (928MHZ)",
      value: -1,
      field: "status.transmitter928Mhz",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "Alarme movimento",
      value: -1,
      field: "status.movementSensorMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "Alarme beacon",
      value: -1,
      field: "status.decouplingSensorMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "Alarme temperatura",
      value: -1,
      field: "status.temperatureAlarmMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
    {
      label: "LORA",
      value: -1,
      field: "status.hibernationMode",
      options: [
        { label: "Não Filtrar", value: -1 },
        { label: "Desligado", value: 0 },
        { label: "Ligado", value: 1 },
      ],
    },
  ],
};

function BulkCommand(props) {
  useAccessLog("Acesso a ferramenta de comando em massa");
  const classes = useStyles();

  const protocolOptions = [
    { value: "V1", label: "Protocolo v1" },
    { value: "V2", label: "Protocolo v2" },
  ];

  const [confirmCommand, setConfirmCommand] = useState(false);
  const [dataToSend, setDataToSend] = useState({});
  const [isSendingCommand, setIsSendingCommand] = useState(false);
  const [modalRequirePermissionIsVisible, setModalRequirePermissionIsVisible] = useState(false);
  const [signaturePasswordValidated, setSignaturePasswordValidated] = useState(null);

  const [commandsV1ToLog, setCommandsV1ToLog] = useState();
  const [commandsV2ToLog, setCommandsV2ToLog] = useState();

  // Commands
  const [protocol, setProtocol] = useState(protocolOptions[0]);

  // Filters
  const [filtersProtocolV1, setFiltersProtocolV1] = useState(
    JSON.parse(JSON.stringify(initialFilters.V1))
  );
  const [filtersProtocolV2, setFiltersProtocolV2] = useState(
    JSON.parse(JSON.stringify(initialFilters.V2))
  );

  const [currentFilters, setCurrentFilters] = useState({
    filters: filtersProtocolV1,
    updateFilters: setFiltersProtocolV1,
  });

  const path =
    "equipment?notPaginated=true&fields=_id,imei,shortImei,lastEvent";
  const [query, setQuery] = useState({
    "currentCompany._id": "",
    protocolVersion: "V1",
    _id: "",
  });

  const [isLoading, setIsLoading] = useState({ equipments: true });


  useEffectSkipFirst(() => {
    if (signaturePasswordValidated === true) {
      // setShowModal(false);
      // if (props.setIsVisible) {
      //   props.setIsVisible(false);
      // }

      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(false);

      try {
        const bulk = {
          bulk: true,
          imeis: checked,
          logMessage: createLogMessage(),
          ...currentDataToSend,
        };
        setIsSendingCommand(true);
        const response = fetchAuthenticated(
          "post",
          "equipment-event/command",
          bulk
        );
        setIsSendingCommand(false);
        setConfirmCommand(false);

        if (response.status >= 400) {
          emitEvent("showSnack", {
            message: "Não foi possível realizar esta ação",
            type: "error",
          });
        } else {
          setCurrentDataToSend(null);
          logAction(`Enviou comandos em massa`);
          emitEvent("showSnack", {
            message: "Ação realizada com sucesso",
            type: "success",
          });
        }
      } catch (e) {

      }
    } else if (signaturePasswordValidated === false) {
      emitEvent('showSnack', { message: 'Assinatura Eletrônica Inválida!', type: 'error' });
    }
  }, [signaturePasswordValidated]);

  const createLogMessage = () => {
    const message = [];
    if (protocol.value === "V1") {
      for (const c in commandsV1ToLog) {
        message.push(
          `${commandsV1ToLog[c].label}: ${commandsV1ToLog[c].value}`
        );
      }
    } else {
      for (const c in commandsV2ToLog) {
        message.push(
          `${commandsV2ToLog[c].label}: ${commandsV2ToLog[c].value}`
        );
      }
    }

    return message.join(" / ");
  };

  const handleModifyV1Commands = (newCommandsState) => {
    setCommandsV1ToLog(newCommandsState);
  };

  const handleModifyV2Commands = (newCommandsState) => {
    setCommandsV2ToLog(newCommandsState);
  };

  const updateFilters = (value, index, filters, fn) => {
    const _filters = [...filters];
    _filters[index].value = value;
    fn(_filters);
  };

  // Handle protocol filter change
  const handleProtocolChange = ({ opt, filterHandler }) => {
    if (opt && opt.value === "V2") {
      setCurrentFilters({
        filters: filtersProtocolV2,
        updateFilters: setFiltersProtocolV2,
      });
    } else {
      setCurrentFilters({
        filters: filtersProtocolV1,
        updateFilters: setFiltersProtocolV1,
      });
    }

    setFiltersProtocolV2(JSON.parse(JSON.stringify(initialFilters.V2)));
    setFiltersProtocolV1(JSON.parse(JSON.stringify(initialFilters.V1)));

    setQuery({ ...query, protocolVersion: (opt && opt.value) || "" });
    setProtocol(opt);
    setIsLoading({ ...isLoading, equipments: true });
  };

  // Handle company filter change
  const handleCompanyChange = ({ opt }) => {
    setQuery({
      ...query,
      "currentCompany._id": {
        value: (opt && opt._id) || "",
        toObjectId: false,
      },
    });
    setIsLoading({ ...isLoading, equipments: true });
  };

  useEffect(() => {
    const fn = async () => {
      setLoadingEquipments(true);

      if (!query["currentCompany._id"]) {
        setEquipments([]);
        setLoadingEquipments(false);
        return;
      }

      const filter = {};
      if (query["currentCompany._id"]) {
        filter["currentCompany._id"] = query["currentCompany._id"];
      }
      if (query.protocolVersion) filter.protocolVersion = query.protocolVersion;
      if (query._id) filter._id = query._id;

      const fetchPath = `${path}&filter=${JSON.stringify(filter)}`;
      const response = await fetchAuthenticated("get", fetchPath);

      const _equips = await response.json();
      setEquipments(_equips.data);
      setShowEquipments(_equips.data);
      setChecked([]);
      setLoadingEquipments(false);
    };
    fn();
  }, [query["currentCompany._id"], query.protocolVersion, query._id]);

  // Equipments
  const [equipments, setEquipments] = useState([]);
  const [showEquipments, setShowEquipments] = useState([]);
  const [loadingEquipments, setLoadingEquipments] = useState(true);
  const [currentDataToSend, setCurrentDataToSend] = useState(null);

  // Filter equipments
  const filterEquipments = (equipments, filters) => {
    setLoadingEquipments(true);
    const filteredEquipments = equipments.filter((e) => {
      if (!e.lastEvent) return false;
      for (const f of filters) {
        if (f.value !== -1 && getField(e.lastEvent, f.field) != f.value) {
          return false;
        }
      }
      return true;
    });
    setShowEquipments(filteredEquipments);
    setChecked([]);
    setLoadingEquipments(false);
  };

  // Deal with checkboxes
  const [checked, setChecked] = useState([]);
  const handleCheck = (item, checked) => {
    const checkedIndex = checked.indexOf(item);
    const checkedOther = [...checked];

    if (checkedIndex === -1) {
      checkedOther.push(item);
    } else {
      checkedOther.splice(checkedIndex, 1);
    }
    setChecked(checkedOther);
  };

  const handleCheckAll = (checked, equipments) => {
    const checkedOther = [];
    if (checked.length !== equipments.length) {
      equipments.map((e) => checkedOther.push(e.imei));
    }
    setChecked(checkedOther);
  };

  const confirmAction = (data) => {
    setConfirmCommand(true);
    setDataToSend(data);
  };

  const handleSubmit = async (data) => {
    try {
      data = data.data;

      if ('temperatureAlarmMode' in data.status && data.status.temperatureAlarmMode) {
        data.status.temperatureRange = {
          min: data.status.temperatureRange[0],
          max: data.status.temperatureRange[1]
        };
      }

      if ('temperatureAlarmMode' in data.status) {
        if (data.status.temperatureAlarmMode) {
          const response = await put(`equipment/update-temperature-range/${JSON.stringify(checked)}`, {
            temperatureRangeEnabled: 'Sim',
            temperatureRange: data.status.temperatureRange,
            temperatureRangeType: parseInt(data.status.temperatureRangeType),
          });
        } else {
          const response = await put(`equipment/update-temperature-range/${JSON.stringify(checked)}`, {
            temperatureRangeEnabled: 'Não',
            temperatureRange: { min: 10, max: 20 },
            temperatureRangeType: 1,
          });
        }
      }

      setCurrentDataToSend(data);

      const userId = JSON.parse(localStorage.getItem('user'))._id;
      const userRequest = await fetchAuthenticated('get', `user/${userId}`);
      let user = {};

      await userRequest.json().then(userResponse => {
        user = userResponse;
      });

      if (user && user.signaturePasswordIsEnabled && user.profiles.some(p => p.enableSignaturePasswordOption)) {
        setModalRequirePermissionIsVisible(null);
        setModalRequirePermissionIsVisible(true);
      } else {
        const bulk = {
          bulk: true,
          imeis: checked,
          logMessage: createLogMessage(),
          ...data,
        };

        const responseLastGPRSCommand = put(
          `equipment/update-lastgprscommand/${JSON.stringify(checked)}`,
          {
            lastGprsCommand: data
          },
          // {
          //   successMessage,
          //   errorMessage,
          //   logMessage,
          // }
        );


        setIsSendingCommand(true);
        const response = fetchAuthenticated(
          "post",
          "equipment-event/command",
          bulk
        );
        setIsSendingCommand(false);
        setConfirmCommand(false);

        if (response.status >= 400) {
          emitEvent("showSnack", {
            message: "Não foi possível realizar esta ação",
            type: "error",
          });
        } else {

          const translateCommands = {
            gpsMode: 'Modo GPS',
            hibernationMode: 'LORA',
            movementSensorMode: 'Alarme Movimento',
            positionSendInterval: 'Período Envio de Dados',
            positionSendIntervalMotion: 'Período Envio modo motion',
            positionSendIntervalSleep: 'Período Envio modo sleep',
            temperatureAlarmMode: 'Alarme Temperatura',
            transmitter434Mhz: 'RF',
            transmitter928Mhz: 'RF Bip Triplo'
          };

          const logMessages = []

          if (protocol.value === "V1") {
            for (const imei of checked) {
              for (const command in commandsV1ToLog) {
                logMessages.push({'description': `Comandos em massa / ${translateCommands[command]} /  ${commandsV2ToLog[command].value}`, 'equipment': imei})
              }
            }
          } else {
            for (const imei of checked) {
              for (const command in commandsV2ToLog) {
                logMessages.push({'description': `Comandos em massa / ${translateCommands[command]} /  ${commandsV2ToLog[command].value}`, 'equipment': imei})
              }
            }
          }

          logAction(null, null, logMessages)

          emitEvent("showSnack", {
            message: "Ação realizada com sucesso",
            type: "success",
          });
        }
      }
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  // Filter async fields
  const asyncFields = [
    {
      name: "companies",
      component: (args) => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Empresa"
          showPlaceholder={true}
          hideLabel={true}
          path="company?notPaginated=true&fields=_id,name"
          name="companies"
          getOptionLabel={(opt) => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={handleCompanyChange}
        />
      ),
    },
    {
      name: "protocols",
      component: (args) => (
        <SelectFilter
          {...args}
          options={protocolOptions}
          getOptionLabel={(opt) => opt.label}
          getOptionValue={(opt) => opt.value}
          placeholder="Protocolo"
          onChange={handleProtocolChange}
          value={protocol}
        />
      ),
    },
    {
      name: "equipments",
      component: (args) => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Equipamentos"
          showPlaceholder={true}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          hideLabel={true}
          path="equipment?notPaginated=true&fields=_id,imei&"
          additionalStartPath='filter={"isActive":true}'
          name="equipments"
          getOptionLabel={(opt) => opt.imei}
          loadingMessage="Carregando equipamentos..."
          noOptionsMessage="Nenhum equipamento encontrado."
          onChange={(args) => {
            const { opt } = args;
            setQuery({ ...query, _id: (opt && opt._id) || "" });
          }}
          whenIsLoading={({ path: defaultPath, setFetchUrl, fields }) => {
            const companyField = fields.companies.current.value;
            if (companyField) {
              const _filter = {
                "currentCompany._id": {
                  value: companyField._id,
                  toObjectId: false,
                },
                isActive: true,
                protocolVersion: protocol.value,
              };
              setFetchUrl(`${defaultPath}filter=${JSON.stringify(_filter)}`);
            } else {
              setFetchUrl(
                `${defaultPath}filter=${JSON.stringify({ isActive: true })}`
              );
            }
          }}
        />
      ),
    },
  ];

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>

      <ModalRequirePermission
        isVisible={modalRequirePermissionIsVisible}
        setIsVisible={setModalRequirePermissionIsVisible}
        signaturePasswordValidated={signaturePasswordValidated}
        setSignaturePasswordValidated={setSignaturePasswordValidated}
      />

      {/* Top filters */}
      <Grid container item>
        <Filter
          containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
          buttonText="Buscar"
          asyncFields={asyncFields}
          noSearch
          hideButton
        />
      </Grid>
      {/* Main Content */}
      <Grid container item style={{ flex: 1 }} alignItems="flex-start">
        {/*  Left filters */}
        <Grid container item sm={12} md={3} xl={2} style={{ flex: 1 }}>
          <Paper
            classes={{ root: classes.paperRoot }}
            elevation={0}
            style={{ maxHeight: "fit-content" }}
          >
            <Typography variant="h6" className={classes.title}>
              Filtros
            </Typography>
            <Grid container style={{ flex: 1 }} direction="column">
              <Grid container item style={{ flex: 1 }} direction="column">
                {currentFilters.filters.map((f, index) => (
                  <FormControl className={classes.formControl} key={index}>
                    <InputLabel classes={{ shrink: classes.shrink }}>
                      {f.label}
                    </InputLabel>
                    <Select
                      disableUnderline
                      IconComponent={KeyboardArrowDownIcon}
                      value={f.value}
                      onChange={(e) => updateFilters(
                        e.target.value,
                        index,
                        currentFilters.filters,
                        currentFilters.updateFilters
                      )
                      }
                    >
                      {f.options.map((f) => (
                        <MenuItem value={f.value} key={f.value}>
                          {f.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}
              </Grid>
              <Divider />
              <Button
                variant="text"
                color="primary"
                style={{ marginTop: 10 }}
                disableRipple
                className={classes.leftFilterButton}
                onClick={() => filterEquipments(equipments, currentFilters.filters)
                }
              >
                Filtrar
              </Button>
            </Grid>
          </Paper>
        </Grid>
        {/* Equipments and commands */}
        <Grid
          container
          item
          direction="column"
          style={{ flex: 1 }}
          className={classes.main}
        >
          <Grid container item style={{ minHeight: 175 }}>
            <Paper classes={{ root: classes.paperRoot }} elevation={0}>
              <Grid container direction="row" alignItems="center">
                <Typography variant="h6" className={classes.title}>
                  Equipamentos
                </Typography>
                {loadingEquipments && (
                  <CircularProgress size={20} style={{ marginLeft: 10 }} />
                )}
              </Grid>
              <Grid container>
                {!loadingEquipments && showEquipments.length > 0 && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color="primary"
                        disableRipple
                        value="all"
                        onChange={() => handleCheckAll(checked, showEquipments)}
                        checked={checked.length === showEquipments.length}
                      />
)}
                    label="Todos"
                    style={{ width: "100%" }}
                  />
                )}
                {!loadingEquipments &&
                  showEquipments.length > 0 &&
                  showEquipments.map((e, index) => (
                    <FormControlLabel
                      key={index}
                      control={(
                        <Checkbox
                          color="primary"
                          disableRipple
                          value={e.imei}
                          onChange={() => handleCheck(e.imei, checked)}
                          checked={checked.indexOf(e.imei) !== -1}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
)}
                      label={e.shortImei}
                    />
                  ))}
                {!loadingEquipments && showEquipments.length === 0 && (
                  <p>Nenhum equipamento encontrado</p>
                )}
              </Grid>
            </Paper>
          </Grid>

          <Grid container item style={{ flex: 1, marginTop: 15 }}>
            <Paper classes={{ root: classes.paperRoot }} elevation={0}>
              <Typography variant="h6" className={classes.title}>
                Comandos
              </Typography>
              <Grid container style={{ flex: 1 }} direction="column">
                {/* Protocol v1 */}
                {protocol.value !== "V2" && (
                  <CommandPv1GprsFields
                    handleSubmit={confirmAction}
                    canDisable
                    handleModifyCommands={handleModifyV1Commands}
                  />
                )}
                {protocol.value === "V2" && (
                  <BulkCommandPv2GprsFields
                    handleSubmit={confirmAction}
                    canDisable
                    handleModifyCommands={handleModifyV2Commands}
                  />
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {confirmCommand && (
        <ModalConfirm
          title="Confirmar envio de comando"
          isOpen={confirmCommand}
          setIsOpen={setConfirmCommand}
          onCancel={() => setConfirmCommand(false)}
          onConfirm={() => handleSubmit(dataToSend)}
          confirmButtonLabel="Sim, confirmar"
          isLoading={isSendingCommand}
        >
          <Typography
            style={{
              fontSize: 16,
              color: "#41475a",
            }}
          >
            Tem certeza que deseja enviar este comando?
          </Typography>
          {(checked.length < 2 && (
            <Typography
              style={{
                fontSize: 16,
                color: "#41475a",
              }}
            >
              {checked.length > 0 ? checked.length : "Nenhum"} equipamento será
              afetado
            </Typography>
          )) || (
            <Typography
              style={{
                fontSize: 16,
                color: "#41475a",
              }}
            >
              {checked.length} equipamentos serão afetados
            </Typography>
          )}
        </ModalConfirm>
      )}
    </Grid>
  );
}

export default withRouter(BulkCommand);
