import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { RemoveRedEye, VisibilityOff } from '@material-ui/icons';

import { emitEvent } from 'utils/events';
import CircularLoading from 'components/loading/CircularLoading';
import Logo1 from 'assets/img/header_xglobal.jpg';
import Logo2 from 'assets/img/secondary-logo2.png';

const URL = process.env.REACT_APP_SERVER_URL;

const useStyles = makeStyles(theme => ({
  followContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 51,
    borderBottom: 'solid 1px #80849d4d',
  },
  followHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  followTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  contact: {
    borderBottom: 'solid 1px #80849d4d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
  },
  splitContact: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newContactDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcon: {
    width: 47,
  },

}));

function PasswordRecover({ match, history }) {
  const classes = useStyles();
  const [form, setForm] = useState({
    password: { value: '', error: false, message: '' },
    passwordConfirm: { value: '', error: false, message: '' },
    email: { error: false, message: '' },
  });

  const [errors, setErrors] = useState({
    password: { error: false, message: '' },
    passwordConfirm: { error: false, message: '' },
    email: { error: false, message: '' },
  });

  const [isLoading, setIsLoading] = useState(false);

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [confirmPasswordIsVisible, setConfirmPasswordIsVisible] = useState(false);

  const onChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: { ...form[name], value } });
    setErrors({ ...errors, [name]: { error: false, message: '' } });
  };

  const validateForm = () => {
    let isValid = true;
    let _errors = { ...errors };
    if (match.params.hash) {
      if (!form.password.value) {
        _errors = { ..._errors, password: { error: true, message: 'Preenchimento obrigatório' } };
        isValid = false;
      }

      if (form.passwordConfirm.value !== form.password.value) {
        _errors = {
          ..._errors,
          passwordConfirm: { error: true, message: 'Confirmação de senha incorreta' },
        };
        isValid = false;
      }

      if (!form.passwordConfirm.value) {
        _errors = {
          ..._errors,
          passwordConfirm: { error: true, message: 'Preenchimento obrigatório' },
        };
        isValid = false;
      }
    } else {
      if (!form.email.value) {
        _errors = { ..._errors, email: { error: true, message: 'Preenchimento obrigatório' } };
        isValid = false;
      }
    }


    if (!isValid) setErrors(_errors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        setIsLoading(true);

        let response = {};
        if ( match.params.hash ) {
          response = await fetch(`${URL}auth/update-signature-password`, {
            method: 'post',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ hash: match.params.hash, password: form.password.value }),
          });
        } else {
          response = await fetch(`${URL}user/recover-password`, {
            method: 'post',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: form.email.value }),
          });
        }

        if (!response.ok) {
          throw new Error();
        } else {
          emitEvent('showSnack', {
            message: `${match.params.hash ? "Nova senha cadastrada com sucesso" : "Solicitação de recuperação realizada"}`,
            type: 'success',
            autoHide: 1000,
          });
          setTimeout(() => {
            history.push('/');
          }, 1500);
        }
      } catch (err) {
        emitEvent('showSnack', {
          message: `Não foi possível realizar esta ação`,
          type: 'error',
        });
        setIsLoading(false);
      }
    }
  };

  let logo;
  const urlAtual = window.location.href;

  // if (urlAtual.includes("url1.xglobal.com")) {
  //   logo = Logo1;
  // } else if (urlAtual.includes("url2.xglobal.com")) {
  //   logo = Logo2;
  // }

  if (urlAtual.includes(process.env.SECONDARY_URL)) {
    logo = Logo2;
  } else {
    logo = Logo1;
  }

  return (
    <Grid container justify="center" alignItems="center">
      <Grid
        container
        // direction="column"
        component={Paper}
        elevation={0}
        style={{ maxWidth: 400, padding: 25, borderRadius: 22 }}
      >
        <Link to="/" style={{ alignSelf: 'center', marginBottom: 25 }}>
          <img alt="Logo XGlobal" src={logo} style={{ height: 56 }} />
        </Link>
        {match.params.hash ? (
          <>
            <TextField
              required
              label="Nova senha"
              type={passwordIsVisible ? 'text' : 'password'}
              name="password"
              style={{ marginBottom: 15, width: '85%' }}
              autoFocus
              onChange={onChange}
              error={errors.password.error}
              helperText={errors.password.message}
            />
            {
              passwordIsVisible ?
              <IconButton title="Visualizar senha" classes={{root: classes.icon}} onClick={() => setPasswordIsVisible(false)}><RemoveRedEye /></IconButton> :
              <IconButton title="Ocultar senha" classes={{root: classes.icon}} onClick={() => setPasswordIsVisible(true)}><VisibilityOff /></IconButton>
            }




            <TextField
              required
              label="Confirmar nova senha"
              style={{ width: '85%' }}
              type={confirmPasswordIsVisible ? 'text' : 'password'}
              name="passwordConfirm"
              onChange={onChange}
              error={errors.passwordConfirm.error}
              helperText={errors.passwordConfirm.message}
            />
            {
              confirmPasswordIsVisible ?
              <IconButton title="Visualizar senha" classes={{root: classes.icon}} onClick={() => setConfirmPasswordIsVisible(false)}><RemoveRedEye /></IconButton> :
              <IconButton title="Ocultar senha" classes={{root: classes.icon}} onClick={() => setConfirmPasswordIsVisible(true)}><VisibilityOff /></IconButton>
            }

          </>
        ) : (
          <TextField
            required
            label="Email para recuperação de senha"
            type="email"
            name="email"
            onChange={onChange}
            error={errors.email.error}
            helperText={errors.email.message}
          />
        )}
        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: 30, boxShadow: 'unset', width: '85%' }}
          onClick={handleSubmit}
        >
          {match.params.hash ? 'Salvar' : 'Enviar'}
        </Button>
        <CircularLoading isLoading={isLoading} />
      </Grid>
    </Grid>
  );
}

export default PasswordRecover;
