import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { get, put } from 'services/fetch';
import Modal from 'components/modals/Modal';
import { Grid, Typography } from '@material-ui/core';
import { emitEvent } from "utils/events";
import { AutocompleteField, RadioGroupField } from '../fields';
import { postWithErrorHandler, fetchAuthenticated } from "services/fetch";
import { useFetch, useAuthenticated } from 'hooks/fetch';
import { useFields } from "hooks/fields";
import { TextField } from '../fields'
import Form, { FormSection } from 'components/forms/Form';
import Slider from '@material-ui/core/Slider';
import Permission from 'components/permissions/Permission';
import { validatePermission } from "services/permissions";
import { useEffectSkipFirst } from 'hooks/common';
import { buildTemperatureRangeLogString } from 'utils/helpers';

import ModalRequirePermission from "components/modals/ModalRequirePermission";
import { logAction } from "utils/logs";

const useStyles = makeStyles(theme => ({
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
    marginBottom: 10,
  },
}));

const ModalTemperatureRangeEdit = (props) => {
  const { equipments, onSubmit, setVisibleModal, needReloadData } = props;
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const classes = useStyles();

  const [modalRequirePermissionIsVisible, setModalRequirePermissionIsVisible] = useState(false);
  const [signaturePasswordValidated,  setSignaturePasswordValidated] = useState(null);
  const [lastCommand, setLastCommand] = useState({});

  const [isLoading, setIsLoading] = useState({
    mobileOperators: false,
    colors: false,
    exemplaries: false,
    packings: false,
    models: false,
    equipmentType: false,
    baitType: false,
  });

  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [baitTypes, setBaitTypes] = useState([]);

  const [temperatureRange, setTemperatureRange] = useState([10, 20]);
  const [temperatureRangeEnabled, setTemperatureRangeEnabled] = useState(false);
  const [temperatureRangeType, setTemperatureRangeType] = useState(1);

  const fields = useFields(validatePermission('EquipmentEnableDisable') ? [
    'temperatureRangeEnabled',
    'temperatureRangeType',
    'temperatureRange',
    'isActive',
  ] : [
    'temperatureRangeEnabled',
    'temperatureRangeType',
    'temperatureRange'
    ]);
  
  const handleTemperatureRangeChange = (event, value) => {
    setTemperatureRange(value)
  }
  
  const handleTemperatureRangeEnabledChange = (event) => {
    const isEnabled = event.target.value === 'Sim' ? true : false
    setTemperatureRangeEnabled(isEnabled)
  }

  const handleTemperatureRangeTypeChange = (event) => {
    const type = parseInt(event.target.value)
    setTemperatureRangeType(type)
  }

  useEffectSkipFirst(() => {

    if(signaturePasswordValidated === true) {
      if (props.setIsVisible) {
        props.setIsVisible(false);
      }

      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(false);


      doSubmit();



    } else if(signaturePasswordValidated === false) {
      emitEvent('showSnack', {message: 'Assinatura Eletrônica Inválida!', type: 'error'});
    }

  }, [signaturePasswordValidated])

  useEffect(() => {
    // if(!props.isVisible) {
    //   setTemperatureRangeEnabled(false);
    // }
    setIsVisible(props.isVisible);
  }, [props.isVisible]);


  useEffect(() => {

    if(isVisible) {
      setTemperatureRangeEnabled(false);
      setTemperatureRange([10, 20]);
      setTemperatureRangeType(1);
    }

  }, [isVisible])

  const doSubmit = async () => {

    setIsVisible(false);
    setVisibleModal('Edit', false);

    const data = {}
    
    data.ids = equipments;

    const equipmentsList = await get(`equipment/imeis-by-id-list?ids=${JSON.stringify(data.ids)}`)

    const temperatureRangeLastState = {}
    const idImeisList = {}

    for(const equipment of equipmentsList) {
      temperatureRangeLastState[equipment.imei] = {
        temperatureRange: 'temperatureRange' in equipment ? equipment.temperatureRange : null,
        temperatureRangeEnabled: 'temperatureRangeEnabled' in equipment ? equipment.temperatureRangeEnabled : null,
        temperatureRangeType: 'temperatureRangeType' in equipment ? equipment.temperatureRangeType : null
      }
      idImeisList[equipment.id] = equipment.imei
    }

    if(temperatureRangeEnabled === true) {
      data['temperatureRangeEnabled'] = 'Sim'
      data['temperatureRange'] = {
        min: temperatureRange[0],
        max: temperatureRange[1]
      }
    } else{
      data['temperatureRangeEnabled'] = 'Não'
      data['temperatureRange'] = null
    }

    data['temperatureRangeType'] = temperatureRangeType

    try {
      const response = await put('equipment/updateMany', data);

      const logMessages = [];

      for(const equipmentId of data.ids) {
        const oldData = temperatureRangeLastState[idImeisList[equipmentId]];
        const newData = {
          temperatureRange: data.temperatureRange,
          temperatureRangeEnabled: data.temperatureRangeEnabled,
          temperatureRangeType: data.temperatureRangeType
        }
        
        let logString = buildTemperatureRangeLogString(oldData, newData, idImeisList[equipmentId])

        if(logString !== '') {
          logMessages.push({description: logString, equipment: idImeisList[equipmentId]})
        }
      }

      logAction(null, null, logMessages)

      emitEvent('showSnack', {message: 'Equipamentos editados com sucesso!', type: 'success'});
      setModalRequirePermissionIsVisible(false);
      return true;
    } catch (e) {
      console.log(e);
      emitEvent('showSnack', {message: 'Erro ao tentar editar os equipamentos!', type: 'error'});
      setModalRequirePermissionIsVisible(false);
      return false;
    } finally {

      if (needReloadData) {
        await needReloadData();
      }

      if (onSubmit) {
        onSubmit();
      }
    }
  }

  const handleSubmit = async () => {

    const userId = JSON.parse(localStorage.getItem('user'))._id
    const userRequest = await fetchAuthenticated('get', `user/${userId}`)
    let user = {}

    await userRequest.json().then(userResponse => {
      user = userResponse
    })

    if(user && user.signaturePasswordIsEnabled && user.profiles.some(p => p.enableSignaturePasswordOption)) {
      
      setVisibleModal('Edit', false);
      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(true);
    } else {
      return doSubmit();
    }
    
  }

  const onClose = () => {
    setVisibleModal('Edit', false);
  }
  return (
  <div>
    <ModalRequirePermission 
      isVisible={modalRequirePermissionIsVisible}
      setIsVisible={setModalRequirePermissionIsVisible}
      signaturePasswordValidated={signaturePasswordValidated}
      setSignaturePasswordValidated={setSignaturePasswordValidated}
    />

      <Modal
        handleSubmit={handleSubmit}
        // onSuccessMessage="Equipamentos editados com sucesso."
        // onErrorMessage="Erro ao tentar editar equipamentos."
        isVisible={isVisible} setIsVisible={setIsVisible} title="Editar Range de Temperatura" buttonLabel="Salvar"
        onClose={onClose}
      >

        <Grid container justify='flex-start' spacing={2} style={{ width: '100%' }}>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <RadioGroupField
            innerRef={fields.temperatureRangeEnabled}
            row
            required
            title="Habilitar Range de Temperatura ?"
            default="Não"
            options={[
              { value: 'Não', label: 'Não' },
              { value: 'Sim', label: 'Sim' },
            ]}
            onChange={handleTemperatureRangeEnabledChange}
            labelProps={{
              style: {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
              },
            }}
            formControlLabelProps={{
              labelPlacement: 'start',
            }}
          />
        </Grid>
        {temperatureRangeEnabled === true ? <Grid item xs={12} sm={6} md={6} lg={6}>
          <RadioGroupField
            innerRef={fields.temperatureRangeType}
            row
            required
            title="Tipo de Temperatura"
            default="1"
            options={[
              { value: '1', label: 'Temperatura do Baú' },
              { value: '2', label: 'Temperatura' },
            ]}
            onChange={handleTemperatureRangeTypeChange}
            labelProps={{
              style: {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
              },
            }}
            formControlLabelProps={{
              labelPlacement: 'start',
            }}
          />
        </Grid> : null}
        {temperatureRangeEnabled === true ? <Grid item xs={12} sm={6} md={4} lg={3}>
          <legend className="MuiFormLabel-root" style={{transform: 'translate(0px, 1.5px) scale(0.75)', transformOrigin: 'left top', marginBottom: '-30px'}}>Range de Temperatura</legend>
          <FormSection>
            <Grid container justify="flex-start" spacing={2}>
              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Slider
                  name="temperatureRange"
                  innerRef={fields.temperatureRange}
                  value={temperatureRange}
                  min={-40}
                  max={90}
                  step={2}
                  valueLabelFormat={(value) => `${value}ºC`}
                  marks={[{value: -40, label: '-40ºC'}, {value: 90, label: '90ºC'}]}
                  onChange={handleTemperatureRangeChange}
                  valueLabelDisplay="on"
                  getAriaValueText={(value) => value}
                />
              </Grid>
            </Grid>
          </FormSection>
        </Grid> : null}
          
        </Grid>
        
      </Modal>
    
  </div>
  )
}

export default ModalTemperatureRangeEdit;
