import { fetchAuthenticated } from 'services/fetch';
export async function logAction(description, equipment = "", lotData = null) {
  try {
    if(lotData !== null) {
      fetchAuthenticated('post', 'logs', lotData);
    } else {
      fetchAuthenticated('post', 'logs', { 
        description: description,
        equipment: equipment
      });
    }
  } catch (err) {
    console.log(err);
  }
}