import * as moment from 'moment';

export const datetimeFormatter = (datetime, format = 'DD/MM/YYYY HH:mm') => {
  if (datetime) {
    return moment(datetime).format(format);
  }
  return '-';
};

export const datetimeFormatterUTC = (datetime, format = 'DD/MM/YYYY HH:mm') => {
  if (datetime) {
    return moment(datetime).add(3, 'hours').format(format);
  }
  return '-';
};

export const durationFormatter = (
  duration,
  format = 'HH:mm',
  unit = 'minutes'
) => {
  if (duration) {
    const _duration = moment.duration(Number(duration), unit);
    return moment.utc(_duration.as('milliseconds')).format(format);
  }
  return '-';
};

export const percentageFormatter = (percentage, defaultValue = '-') => {
  try {
    if (percentage || percentage == 0) {
      return Number(percentage).toLocaleString('pt-BR', {
        style: 'percent',
      });
    }
    return defaultValue;
  } catch (err) {
    return defaultValue;
  }
};

export const temperatureFormatter = temperature => {
  if (temperature || temperature == 0) {
    return `${parseFloat(temperature).toFixed(1)}ºC`;
  }
  return '-';
};

export const latlongFormatter = (latitude, longitude) => {
  latitude = String(latitude || '-').substring(0, 8);
  longitude = String(longitude || '-').substring(0, 8);
  return `${latitude} / ${longitude}`;
};

export const speedFormatter = speed => {
  if (speed || speed == 0) {
    return `${parseFloat(speed).toFixed(1)}Km/h`;
  }
  return '-';
};

export const ISODateToNormalDate = date => {
  try {
    let formattedDateTime = date.replace('T', ' ').split('.')[0]
    let formattedDate = formattedDateTime.split(' ')[0]
    let formattedTime = formattedDateTime.split(' ')[1]
    let day = formattedDate.split('-')[2]
    let month = formattedDate.split('-')[1]
    let year = formattedDate.split('-')[0]
    
    return `${day}/${month}/${year} ${formattedTime}`

  } catch(e) {
    return '-'
  }
}


export const defaultFormatter = (value, defaultValue = '-') => {
  if (!value || (typeof value === 'string' && !value.length)) {
    return defaultValue;
  }
  return value;
};

export const mapPoffStatus = poffCommand => {
  if (poffCommand) {
    switch (poffCommand.status) {
      case 'COMPLETED':
        return 'Expirado';
      case 'RECEIVED':
        return 'Agendado';
      case 'SCHEDULED':
        return 'Aguardando ativação';
      case 'CANCELED':
        return 'Cancelado';
      case 'RUNNING':
        return 'Em execução';
      default:
        return '-';
    }
  } else {
    return '-';
  }
};

export const imeiFormatter = equipment => {
  if (!equipment) return '-';
  return equipment.imei.substring(
    equipment.imei.length,
    equipment.imei.length - 8
  );
};

export const poffFormatter = equipment => {
  if (!equipment) return '- / -';
  if (!equipment.poffCommands || !equipment.poffCommands.length) return '- / -';
  return `${equipment.poffCommands[0].runningDays}/${equipment.poffCommands[0].totalDays}`;
};

export const lastValidPositionDateFormatter = (date, hour) => {
  const TIMEZONE_BR = 0;
  if (date && hour) {
    const myHour = hour.split(':');
    const currentHour = `${parseInt(myHour[0]) - TIMEZONE_BR}:${myHour[1]}`;
    return `${date} ${currentHour}`;
  }
  return '-';
};

export const lastValidPositionDateFormatterToISOFormat = (date, hour) => {

  // const TIMEZONE_BR = 3;
  if (date && hour) {
    // const myHour = hour.split(':');
    // const currentHour = `${parseInt(myHour[0])}:${myHour[1]}`;

    const newDate = `20${date.split('/')[2]}-${date.split('/')[1]}-${date.split('/')[0]} ${hour}`

    const __date = new Date(newDate)

    __date.setHours(__date.getHours() + 3);

    const formattedDate = [
      __date.getFullYear(), // Year
        ('0' + (__date.getMonth() + 1)).slice(-2), // Month (zero-indexed so add 1)
        ('0' + __date.getDate()).slice(-2) // Day
    ].join('-') + ' ' + [
        ('0' + __date.getHours()).slice(-2), // Hours
        ('0' + __date.getMinutes()).slice(-2), // Minutes
        ('0' + __date.getSeconds()).slice(-2) // Seconds
    ].join(':');

    return formattedDate

    // const dateObject = new Date(newDate)
    // dateObject.setHours(dateObject.getHours() - 3)
    // return dateObject.toISOString()
    // return `${newDate}T${currentHour}:00`;
  }
  return null;
};

