import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  FormControl,
} from '@material-ui/core';
import Form, { FormSection } from "components/forms/Form";
import { get, put } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';


import { ButtonSubmit, ButtonCancel } from 'components/buttons/ButtonsForm';

import { TextField } from '@material-ui/core';

const GeneralParametersForm = (props) => {
  const { classes } = props;
  const [isLoading, setIsLoading] = useState({
    form: false,
  });
  const [isDisabled, setDisabled] = useState({ form: false });
  const [submitForm, setSubmitForm] = useState(false);

  const [hideFarthestPosition, setHideFarthestPosition] = useState(false);
  const [farthestPositionRay, setFarthestPositionRay] = useState('');

  useEffect(() => {
    const fn = async () => {
      const result = await get('settings');
      setHideFarthestPosition((result.hasOwnProperty('hideFarthestPosition') && result.hideFarthestPosition) || false);
      if (result.hasOwnProperty('farthestPositionRay')) {
        setFarthestPositionRay(result.farthestPositionRay);
      }
    }

    fn();
  }, []);

  /**================
   * FORM SUBMISSION
   * ================ */

  // Validate all fields when form is submited
  const validateForm = async (event) => {
    event.preventDefault();
    if (hideFarthestPosition) {
      if (isNaN(Number(farthestPositionRay)) || Number(farthestPositionRay) === 0) {
        emitEvent('showSnack', { message: 'O campo "Raio" precisa ser preenchido.', type: 'error' });
      }

      if (Number(farthestPositionRay) < 100) {
        emitEvent('showSnack', { message: 'O valor mínimo para o campo "Raio" é de 100 metros.', type: 'error' });
      }
    }

    setSubmitForm(true);
  }

  // Handle form submission
  useEffect(() => { if (submitForm) { (async () => { await handleSubmit() })(); } }, [submitForm]);

  const handleSubmit = async () => {

    const data = {
      hideFarthestPosition
    }

    if (hideFarthestPosition) {
      data['farthestPositionRay'] = Number(farthestPositionRay)
    } else {
      data['farthestPositionRay'] = null;
    }

    // Send to server
    try {
      const response = await put('settings', data)

      emitEvent('showSnack', { message: 'Operação realizada com sucesso!', type: 'success' });
      setSubmitForm(false);
    } catch (err) {
      emitEvent('showSnack', { message: 'Erro ao processar formulário', type: 'error' });
      setDisabled({ ...isDisabled, form: false });
      setIsLoading({ ...isLoading, form: false });
      setSubmitForm(false);
    }
  }

  const handleFarthestPositionChange = (e) => {
    setHideFarthestPosition(e.target.checked);
  }

  return (
    <Form title={props.title || 'Configurações gerais da plataforma'}>
      <form onSubmit={validateForm} noValidate>
        <FormSection divider={true} justify="flex-start">
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <div className={classes.label}>Filtro de posição distante habilitado ?</div>
              <Switch
                name="farthest-position-enabled"
                checked={hideFarthestPosition}
                onChange={handleFarthestPositionChange}
                color="primary"
              />

            </Grid>
            {hideFarthestPosition && (
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <TextField
                  required
                  fullWidth
                  value={farthestPositionRay}
                  onChange={(e) => setFarthestPositionRay(e.target.value)}
                  className={classes.fullWidthField}
                  label="Raio (em metros)"
                  name="ray"
                  type="number"  // Define o tipo de entrada como numérico
                  InputProps={{ inputProps: { min: 100 } }}  // Adiciona uma validação mínima (opcional)
                />
              </Grid>
            )}
          </Grid>
        </FormSection>

        <Grid container justify-content="flex-end" spacing={1}>
          {props.handleCancel
            && (
              <Grid item xs={12} md="auto">
                <ButtonCancel
                  onClick={props.handleCancel}
                  disabledCondition={isDisabled.form}
                ></ButtonCancel>
              </Grid>
            )
          }


          <Grid item xs={12} md={'auto'}>
            <ButtonSubmit disabledCondition={isDisabled.form}></ButtonSubmit>
          </Grid>
        </Grid>
      </form>
    </Form>
  )
};

const styles = theme => ({
  btnRoot: {
    minWidth: 125,
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});

export default withStyles(styles)(GeneralParametersForm);
