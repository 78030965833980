import React, { useEffect } from 'react';

import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';

// import RfImportForm from 'components/forms/NewRfImportForm';
import NewErbForm from 'components/forms/NewErbForm';
import { logAction } from 'utils/logs';
import NewBipForm from 'components/forms/NewBipForm';
import moment from 'moment';

export default function NewErb(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/bips', label: 'Cadastro Bips' },
      { path: '', label: 'Novo Cadastro' }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs])

  // Handle form submit
  const handleSubmit = async (data) => {

    if(moment.isMoment(data.date)) {
      data['date'] = data.date.format('YYYY-MM-DD')
    } else {
      const dateYear = data['date'].split('/')[2]
      const dateMonth = data['date'].split('/')[1]
      const dateDay = data['date'].split('/')[0]

      data['date'] = `${dateYear}-${dateMonth}-${dateDay}`
    }

    console.log(data['date'])

    const response = await fetchAuthenticated('post', 'bip', data);

    if (!response.ok) {
      throw new Error('');
    } else {
      const data = await response.json();
      emitEvent('showSnack', { message: 'Cadastro realizado com sucesso!', type: 'success' });
      // logAction(`Criação do equipamento ${data.shortImei}`, data.imei)
      history.push(`/cadastros/bips/${data._id}`);
    }
  }

  // Handle cancel click
  const handleCancel = async () => {
    history.push('/cadastros/bips');
  }



  return (
    <div>
      <NewBipForm
        title='Novo cadastro'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isEditing={false}
      />
    </div>
  )
}
