import { buildQuery } from 'utils/query';
export default class FilterHandler {
  constructor(query, setQuery, location, secondQuery = null, setSecondQuery = null) {
    this.query = query;
    this.setQuery = setQuery;
    this.location = location;
    this.secondQuery = secondQuery;
    this.setSecondQuery = setSecondQuery;
  }

  concatFilters = newFilter => {
    const currentQueryFilter = this.query.queryObject.filter || {};
    return { ...currentQueryFilter, ...newFilter };
  };

  updateQueryFilter = newFilter => {
    newFilter = this.concatFilters(newFilter);
    this.setQuery(
      buildQuery(this.location, { ...this.query.queryObject, filter: newFilter, page: 1 }),
    );
    if(this.secondQuery !== null) {
      this.setSecondQuery(
        buildQuery(this.location, { ...this.secondQuery.queryObject, filter: newFilter, page: 1 }),
      );
    }
  };

  removeFilter = key => {
    const currentQueryFilter = this.query.queryObject.filter || {};
    delete currentQueryFilter[key];
    this.setQuery(buildQuery(this.location, { ...this.query.queryObject }));
    if(this.secondQuery !== null) {
      const currentSecondQueryFilter = this.secondQuery.queryObject.filter || {};
      delete currentSecondQueryFilter[key];
      this.setSecondQuery(buildQuery(this.location, { ...this.secondQuery.queryObject }));
    }
  };

  removeFilters = keys => {
    const currentQueryFilter = this.query.queryObject.filter || {};
    keys.forEach(k => delete currentQueryFilter[k]);
    this.setQuery(buildQuery(this.location, { ...this.query.queryObject }));

    if(this.secondQuery !== null) {
      const currentSecondQueryFilter = this.secondQuery.queryObject.filter || {};
      keys.forEach(k => delete currentSecondQueryFilter[k]);
      this.setSecondQuery(buildQuery(this.location, { ...this.secondQuery.queryObject }));
    }

  };

  hasFilter = key => {
    return Object.keys(this.query.queryObject.filter || {}).indexOf(key) > -1;
  };

  getFilter = key => {
    return (this.queryObject.filter || {})[key];
  };

  addFilter = filter => {};
}
