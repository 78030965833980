import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, TextField, Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  get,
  fetchAuthenticated,
  putWithErrorHandler,
  postWithErrorHandler,
} from 'services/fetch';
import { emitEvent } from 'utils/events';
import Switch from '@material-ui/core/Switch';
import Modal from 'components/modals/Modal';
import CheckboxList from 'components/lists/CheckboxList';
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  followContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 51,
    borderBottom: 'solid 1px #80849d4d',
  },
  followHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  followTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  contact: {
    borderBottom: 'solid 1px #80849d4d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
  },
  splitContact: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newContactDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcon: {
    width: 47,
  },
}));

const ModalShowLORAInfo = props => {
  const { isVisible, setIsVisible, data } = props;
  const classes = useStyles();

  const [showModal, setShowModal] = useState(isVisible);

  const columns = [
    { label: 'Data / Hora', value: data.timestamp || '-' },
    { label: 'Lat / Long', value: data.latlong || '-' },
    { label: 'Sinal', value: data.signal || '-' },
  ];

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleSubmit = async data => {
    //
  };

  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  };

  return (
    <Modal
      isVisible={showModal}
      setIsVisible={setShowModal}
      title={'XGlobal - Lora'}
      buttonLabel="Salvar"
      onClose={onClose}
      preventDefaultSubmit
      hideButton
    >
      {/* <Typography
        className={classes.subsubheader}
        style={{ marginTop: '-20px', marginBottom: '30px' }}
      >
        XGlobal - Lora: <b>{props.profileName}</b>
      </Typography> */}
      <Grid item xs={12} className={classes.checkboxContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map((col, index) => {
                if (!col.label) {
                  return (
                    <TableCell
                      key={index}
                      style={{
                        color: '#41475a',
                        fontWeight: 'bold',
                        fontSize: 14,
                        // width: '200px',
                      }}
                    />
                  );
                }
                if (typeof col.label === 'function') {
                  return col.label(index);
                }
                return (
                  <TableCell
                    style={{
                      color: '#41475a',
                      fontWeight: 'bold',
                      fontSize: 14,
                      // width: '200px',
                    }}
                    align={'left'}
                    key={col.label}
                    // sortDirection={sort[col.sort] === 1 ? 'asc' : 'desc'}
                    classes={{ root: classes.tableCellRoot }}
                  >
                    {(col.sort && (
                      <TableSortLabel
                        active={true}
                        direction={'asc'}
                        // onClick={onSortClick(col)}
                      >
                        <Typography className={classes.header}>
                          {col.label}
                        </Typography>
                      </TableSortLabel>
                    )) || (
                      <Typography className={classes.header}>
                        {col.label}
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
            // hover={(props.onRowClick && true) || false}
            // key={row._id}
            // style={{
            //   cursor: (props.onRowClick && 'pointer') || 'default',
            // }}
            // onMouseEnter={event =>
            //   props.popover && handlePopoverOpen(event, row)
            // }
            // onMouseLeave={event =>
            //   props.popover && handlePopoverClose(event, row)
            // }
            >
              {/* {props.popover && props.isPopoverVisible(row) && (
                <Popover
                  className={classes.popover}
                  classes={{
                    paper: classes.popoverPaper,
                  }}
                  open={Boolean(popover[row._id])}
                  anchorEl={popover[row._id]}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onClose={event => handlePopoverClose(row)}
                  disableRestoreFocus
                  disableAutoFocus
                >
                  {props.popover(row)}
                </Popover>
              )} */}

              {columns.map((col, index) => {
                {
                  /* if (col.component) {
                  return col.component(row, col);
                  // <TableMenu row={row} rowActions={col.rowActions}></TableMenu>
                } */
                }

                return (
                  <TableCell
                    // onClick={() => handleTableCellClick(row, col)}
                    align={'left'}
                    key={1 + Math.random().toString()}
                    classes={{ root: classes.tableCellRoot }}
                    style={{ ...(col.cellStyle || {}) }}
                  >
                    <div className={classes.textContainer}>
                      <Typography className={classes.text}>
                        {col.value}
                      </Typography>
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
        {/* {profileId !== '' && fieldsAvailable.length > 0 ? (
          <CheckboxList
            data={fieldsAvailable}
            setData={setFieldsAvailable}
            getName={item => item.label}
            labelStyle={{
              fontSize: 16,
              color: '#41475a',
              fontWeight: 'normal',
            }}
            checkAll={checkAll}
            containerStyle={{
              flexDirection: 'column',
              display: 'flex',
              flexWrap: 'wrap',
            }}
            showCheckAll={fieldsAvailable.length > 0}
          />
        ) : null} */}
      </Grid>
    </Modal>
  );
};

export default ModalShowLORAInfo;
