import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'components/modals/Modal';
import { TextFieldModalStyle as TextField } from 'components/fields/Text.field';
import { fetchAuthenticated } from "services/fetch";
import { emitEvent } from "utils/events";
import { Formik } from 'formik';
import ErrorMessage from 'components/fields/ErrorMessage';
import ModalRequirePermission from "components/modals/ModalRequirePermission";
import * as Yup from 'yup';
import * as moment from 'moment';
import { useEffectSkipFirst } from 'hooks/common';

const ERROR_MESSAGES = {
  required: 'Campo obrigatório',
};

const useStyles = makeStyles(theme => ({
  textarea: {
    borderRadius: 22,
    border: 'solid 1px #808492',
    resize: 'none',
    outline: 'none',
    padding: 22,
    fontFamily: 'Raleway',
    fontSize: 15,
    width: '100%',
    fontWeight: '400',
    marginTop: 48,
  },
}));

const StatusSchema = Yup.object().shape({
  ignoreTime: Yup.string().required(ERROR_MESSAGES.required),
});

const ModalStatus = (props) => {
  const { isVisible, onSubmit, equipment, status } = props;
  const [ isOpen, setIsOpen ] = useState(isVisible);
  const [ ignoreTime, setIgnoreTime ] = useState('00:10');
  const [ description, setDescription] = useState("");
  const [ currentV, setCurrentV ] = useState(null);
  const classes = useStyles();

  const [modalRequirePermissionIsVisible, setModalRequirePermissionIsVisible] = useState(false);
  const [signaturePasswordValidated,  setSignaturePasswordValidated] = useState(null);


  const _onSubmit = async (v) => {

    setCurrentV(v)

    const userId = JSON.parse(localStorage.getItem('user'))._id
    const userRequest = await fetchAuthenticated('get', `user/${userId}`)
    let user = {}

    await userRequest.json().then(userResponse => {
      user = userResponse
    })

    if(user && user.signaturePasswordIsEnabled && user.profiles.some(p => p.enableSignaturePasswordOption)) {
      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(true);
    } else {
      setIsOpen(false);

      const now = moment();
      let ignoreTimeMoment = v.ignoreTime.split(':');
      ignoreTimeMoment = now.add(ignoreTimeMoment[0], 'hours').add(ignoreTimeMoment[1], 'minutes');

      onSubmit(equipment, {ignoreTimeMoment, description}); 
    }
  }
  const onChange = (v) => {
    setIgnoreTime(v);
  }
  const onClose = () => {
    setIsOpen(false);
  }

  const onTextChange = (e) => {
    setDescription(e.target.value);
  }

  useEffectSkipFirst(() => {

    if(signaturePasswordValidated === true) {
      setIsOpen(false);

      const now = moment();
      let ignoreTimeMoment = currentV.ignoreTime.split(':');
      ignoreTimeMoment = now.add(ignoreTimeMoment[0], 'hours').add(ignoreTimeMoment[1], 'minutes');

      onSubmit(equipment, {ignoreTimeMoment, description}); 
    } else if(signaturePasswordValidated === false) {
      emitEvent('showSnack', {message: 'Assinatura Eletrônica Inválida!', type: 'error'});
    }

  }, [signaturePasswordValidated])

  return (
    <Formik
      initialValues={{
        ignoreTime: '00:10',
      }}
      validationSchema={StatusSchema}
      onSubmit={_onSubmit}
    >
    {props => (
    <Modal
      handleSubmit={props.handleSubmit}
      preventDefaultSubmit
      isVisible={isOpen} setIsVisible={setIsOpen} title="Alterar status" buttonLabel="Salvar"
      onClose={onClose}
    >
      <ModalRequirePermission 
        isVisible={modalRequirePermissionIsVisible}
        setIsVisible={setModalRequirePermissionIsVisible}
        signaturePasswordValidated={signaturePasswordValidated}
        setSignaturePasswordValidated={setSignaturePasswordValidated}
      />
      <TextField
        label={`Manter equipamento no status ${status} por`}
        name="ignoreTime"
        defaultValue={props.values.ignoreTime}
        onChange={props.handleChange('ignoreTime')}
        containerStyle={{
          marginTop: 60,
        }}
        timeMask={true}
      />
      <ErrorMessage name="ignoreTime"></ErrorMessage>
      <textarea onChange={onTextChange} placeholder="Descrição" className={classes.textarea} rows="7">
      </textarea>
    </Modal>
    )}
    </Formik>
  )
}

export default ModalStatus;
