import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ModalConfirm from 'components/modals/ModalConfirm';
import {
  Typography,
  Link,
  TextField,
  Grid,
} from '@material-ui/core';
// import { get, fetchAuthenticated } from 'services/fetch';
import { postWithErrorHandler, fetchAuthenticated } from "services/fetch";
import Dialog from '@material-ui/core/Dialog';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RemoveRedEye, VisibilityOff } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import { emitEvent } from 'utils/events';
import Switch from '@material-ui/core/Switch';
import Modal from 'components/modals/Modal';
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { logAction } from 'utils/logs';

import {
  AutocompleteField,
} from 'components/fields';
import { MyCheckbox as Checkbox } from 'components/lists/CheckboxList';

import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from 'assets/icon/lixeira.svg';
import ReactSVG from 'react-svg'
import { SignalCellularNullSharp } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  followContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 51,
    borderBottom: 'solid 1px #80849d4d',
  },
  followHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  followTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  contact: {
    borderBottom: 'solid 1px #80849d4d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
  },
  splitContact: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newContactDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcon: {
    width: 47,
  },

}));

const ModalRequirePermission = (props) => {
  const { isVisible, setIsVisible, equipment, setIconActive, onSubmit, signaturePasswordValidated, setSignaturePasswordValidated } = props;
  const classes = useStyles();

  const [showModal, setShowModal] = useState(isVisible);
  const [password, setPassword] = useState('');

  const contactRef = React.createRef(null);
  const [user, setUser] = useState(null);

  const [contacts, setContacts] = useState([]);

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const onDialogClose = () => {

  }

  const handleSubmit = async () => {

    const response = await fetchAuthenticated('post', `user/validate-signature-password`, { password })

    if(response.ok) {
      setSignaturePasswordValidated(null);
      setSignaturePasswordValidated(true);
    } else {
      setSignaturePasswordValidated(null);
      setSignaturePasswordValidated(false);
    }

  }

  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  }

  const closeDialog = () => {

  }

  const openDialog = () => {
    setIsDialogVisible(true);
  }

  const handleCancel = () => {

  }

  const handleDialogSubmit = () => {
    setIsDialogVisible(false);
    fetchAuthenticated('post', 'user/recover-signature-password', {});
    emitEvent('showSnack', {message: 'Link encaminhado por email com sucesso!', type: 'success'});
  }

  return (
    <div>
      <Modal
        handleSubmit={handleSubmit}
        onSuccessMessage="Validação realizada com sucesso!"
        isVisible={showModal} setIsVisible={setShowModal} title={"Validar Permissão"} buttonLabel="Enviar"
        preventDefaultSubmit
        // onClose={onClose}
      >
        <Grid
          container
          direction="column"
          justify="space-between"
          component="form"
          onSubmit={null}
        >
          <Grid container justify="space-between" wrap="nowrap">
            <Grid
              item
              xs={12}
              component={TextField}
              type={passwordIsVisible ? 'text' : 'password'}
              name="signature-password"
              label="Assinatura Eletrônica"
              value={password}
              onChange={event => setPassword(event.target.value)}
              style={{ paddingRight: 20 }}
            />

            {
              passwordIsVisible ?
              <IconButton title="Visualizar senha" classes={{root: classes.icon}} onClick={() => setPasswordIsVisible(false)}><RemoveRedEye /></IconButton> :
              <IconButton title="Ocultar senha" classes={{root: classes.icon}} onClick={() => setPasswordIsVisible(true)}><VisibilityOff /></IconButton>
            }
          </Grid>

          <Grid item>
            <FormControl margin="none" fullWidth>
              {/*  */}
              <Link className={classes.link} onClick={openDialog} style={{ cursor: 'pointer' }}>
                Recuperar Assinatura Eletrônica
              </Link>
            </FormControl>
          </Grid>
        </Grid>

      </Modal>

      <ModalConfirm
        title={`Recuperar Assinatura Eletrônica`}
        isOpen={isDialogVisible}
        setIsOpen={setIsDialogVisible}
        onCancel={handleCancel}
        onConfirm={handleDialogSubmit}
        confirmButtonLabel={'Sim, confirmar'}
        isLoading={false}
      >
        <Typography style={{
          fontSize: 16,
          color: '#41475a',
        }}>Tem certeza que deseja recuperar sua assinatura eletrônica ?</Typography>
      </ModalConfirm>
    </div>
  )
}

export default ModalRequirePermission;
