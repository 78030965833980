import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { get, put } from 'services/fetch';
import Modal from 'components/modals/Modal';
import { Grid, Typography } from '@material-ui/core';
import { AutocompleteField, RadioGroupField } from '../fields';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import { useFields } from "hooks/fields";
import { TextField } from '../fields'
import Permission from 'components/permissions/Permission';
import { validatePermission } from "services/permissions";
import { buildLogString } from "utils/helpers";
import { logAction } from "utils/logs";
import ModalRemove from 'components/modals/ModalRemove';
import { emitEvent } from 'utils/events';

const useStyles = makeStyles(theme => ({
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
    marginBottom: 10,
  },
}));

const ModalEquipmentEdit = (props) => {
  const { equipments, onSubmit, setVisibleModal, needReloadData } = props;
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState({
    mobileOperators: false,
    colors: false,
    exemplaries: false,
    packings: false,
    models: false,
    equipmentType: false,
    baitType: false,
  });

  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [baitTypes, setBaitTypes] = useState([]);

  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const fields = useFields(validatePermission('EquipmentEnableDisable') ? [
    'color',
    'packing',
    'model',
    'exemplary',
    'antiJamming',
    'battery',
    'mobileOperator',
    'baitType',
    'equipmentType',
    'isActive',
  ] : [
      'color',
      'packing',
      'model',
      'exemplary',
      'antiJamming',
      'battery',
      'mobileOperator',
      'baitType',
      'equipmentType',
    ]);

  useEffect(() => {
    setIsVisible(props.isVisible);
  }, [props.isVisible]);

  useEffect(() => {
    setIsLoading({ ...isLoading, equipmentType: true, baitType: true });

    const eTypes = [
      { id: 1, name: 'Isca' },
      { id: 2, name: 'Veículo Passeio' },
      { id: 3, name: 'Truck' },
      { id: 4, name: 'Carreta' },
      { id: 5, name: 'Moto' },
      { id: 6, name: 'Cavalo' },
      { id: 7, name: 'Bloqueador' },
    ].sort((a, b) => a.name > b.name ? 1 : -1);

    setEquipmentTypes(eTypes);

    setIsLoading({ ...isLoading, equipmentType: false });

    const bTypes = [
      { id: 1, name: 'Retornável' },
      { id: 2, name: 'Descartável' },
      { id: 3, name: 'Customizada' },
      { id: 4, name: 'Teste' },
    ].sort((a, b) => a.name > b.name ? 1 : -1);

    setBaitTypes(bTypes);

    setIsLoading({ ...isLoading, baitType: false });
  }, []);

  // Load colors
  const { data: colors } = useAuthenticated(useFetch, 'equipment-color/list', {
    beforeFetch() { setIsLoading({ ...isLoading, colors: true }); },
    afterFetch() { setIsLoading({ ...isLoading, colors: false }); },
    defaultValue: [],
  });

  // Load exemplaries
  const { data: exemplaries } = useAuthenticated(useFetch, 'equipment-exemplary/list', {
    beforeFetch() { setIsLoading({ ...isLoading, exemplaries: true }); },
    afterFetch() { setIsLoading({ ...isLoading, exemplaries: false }); },
    defaultValue: [],
  });

  // Load colors
  const { data: models } = useAuthenticated(useFetch, 'equipment-model/list', {
    beforeFetch() { setIsLoading({ ...isLoading, models: true }); },
    afterFetch() { setIsLoading({ ...isLoading, models: false }); },
    defaultValue: [],
  });

  // Load packings
  const { data: packings } = useAuthenticated(useFetch, 'equipment-packing/list', {
    beforeFetch() { setIsLoading({ ...isLoading, packings: true }); },
    afterFetch() { setIsLoading({ ...isLoading, packings: false }); },
    defaultValue: [],
  });

  // Load mobile operators
  const { data: mobileOperators } = useAuthenticated(useFetch, 'equipment-mobile-operator/list', {
    beforeFetch() { setIsLoading({ ...isLoading, mobileOperators: true }); },
    afterFetch() { setIsLoading({ ...isLoading, mobileOperators: false }); },
    defaultValue: [],
  });

  const handleRemoveSubmit = async () => {
    setShowDialog(true);
  }

  const handleRemoveConfirm = async () => {

    setLoadingDialog(true);
    setIsVisible(false);
    setVisibleModal('Edit', false);

    try {

      const data = {}

      data.ids = equipments;
      data.isActive = false;
      data.deleted = true;

      const response = await put('equipment/updateMany', data);

      if(response.ok) {
        emitEvent('showSnack', {
          message: 'Equipamentos deletados com sucesso!',
          type: 'success',
        });

        const logMessages = [];

        for(const equipment of equipments) {
          logMessages.push({'description': `Exclusão em Massa do Equipamento`, 'equipment': equipment.imei})
        }

        logAction(null, null, logMessages)

        setLoadingDialog(false);

      } else {
        emitEvent('showSnack', {
          message: 'Ocorreu um erro ao deletar os equipamentos!',
          type: 'error',
        });
      }

    } catch(e) {

    } finally {
      if (needReloadData) {
        await needReloadData();
      }
    }
  }

  const handleSubmit = async () => {
    setIsVisible(false);
    setVisibleModal('Edit', false);

    // Get all fiels
    const data = {}
    for (const [key, value] of Object.entries(fields)) {
      if (value.current.raw) {
        // remove empty fields
        data[key] = key === 'isActive' ? value.current.raw === 'true' : value.current.raw;
      }
    }
    data.ids = equipments;

    try {
      const response = await put('equipment/updateMany', data);

      const translateKeys = {
        status: 'Status',
        imei: 'IMEI',
        code: 'Código',
        originalImei: 'Original IMEI',
        iccid: 'ICCID',
        equipmentType: 'Tipo de Equipamento',
        protocolVersion: 'Protocolo',
        shortImei: 'IMEI Curto',
        currentCompany: 'Empresa',
        antiJamming: 'Anti-Jammer',
        baitType: 'Tipo de Isca',
        battery: 'Bateria',
        color: 'Cor',
        exemplary: 'Exemplar',
        expirationDate: 'Tempo para Expiração',
        lineNumber: 'Número da Linha',
        mobileOperator: 'Operadora',
        model: 'Modelo',
        packing: 'Embalagem',
        rfNumber: 'Número RF',
        vehicle: 'Veículo'
      }

      const defaultData = {
        status: null,
        imei: null,
        code: null,
        originalImei: null,
        iccid: null,
        equipmentType: null,
        protocolVersion: null,
        shortImei: null,
        currentCompany: null,
        antiJamming: null,
        baitType: null,
        battery: null,
        color: null,
        exemplary: null,
        expirationDate: null,
        lineNumber: null,
        mobileOperator: null,
        model: null,
        packing: null,
        rfNumber: null,
        vehicle: null
      }

      const equipments = await get(`equipment/imeis-by-id-list?ids=${JSON.stringify(data.ids)}`)

      const logMessages = [];

      for(const equipment of equipments) {
        const updateItemsStr = buildLogString(defaultData, data, translateKeys, ['ids'], {'false': 'Não', 'true': 'Sim'}, true)

        logMessages.push({description: `Edição em Massa do Equipamento${updateItemsStr}`, equipment: equipment.imei})
      }

      logAction(null, null, logMessages)
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      if (needReloadData) {
        await needReloadData();
      }

      if (onSubmit) {
        onSubmit();
      }
    }
  }
  const onClose = () => {
    setVisibleModal('Edit', false);
  }
  return (
    <div>
      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='dos equipamentos'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='os equipamentos'
        extraContent='Ao realizar está ação os equipamentos não estarão mais disponíveis.'
      />
    <Modal
      handleSubmit={handleSubmit}
      onSuccessMessage="Equipamentos editados com sucesso."
      onErrorMessage="Erro ao tentar editar equipamentos."
      isVisible={isVisible} setIsVisible={setIsVisible} title="Editar equipamentos em massa" buttonLabel="Salvar" showSecondaryButton={true} secondaryButtonLabel="Remover" 
      handleSecondarySubmit={handleRemoveSubmit}
      onClose={onClose}
    >
      <Typography className={classes.subheader}>
        Os campos vazios serão desconsiderados.
      </Typography>

      <Grid container justify='flex-start' spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.exemplary}
            label='Exemplar de equipamento'
            name='exemplary'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={exemplaries}
            isLoading={isLoading.exemplaries}
            noOptionsMessage={() => 'Nenhum exemplar encontrado'}
            loadingMessage={() => 'Carregando exemplares...'}
            isClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.model}
            label='Modelo'
            name='model'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={models}
            isLoading={isLoading.models}
            noOptionsMessage={() => 'Nenhum modelo encontrado'}
            loadingMessage={() => 'Carregando modelos...'}
            isClearable={true}
          />
        </Grid>
      </Grid>
      <Grid container justify='flex-start' spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.packing}
            label='Embalagem'
            name='packing'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={packings}
            isLoading={isLoading.packings}
            noOptionsMessage={() => 'Nenhuma embalagem encontrada'}
            loadingMessage={() => 'Carregando embalagem...'}
            isClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.color}
            label='Cor'
            name='color'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={colors}
            isLoading={isLoading.colors}
            noOptionsMessage={() => 'Nenhuma cor encontrada'}
            loadingMessage={() => 'Carregando cores...'}
            isClearable={true}
          />
        </Grid>
      </Grid>
      <Grid container justify='flex-start' spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.mobileOperator}
            label='Operadora'
            name='mobileOperator'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt._id}
            options={mobileOperators}
            isLoading={isLoading.mobileOperators}
            noOptionsMessage={() => 'Nenhuma operadora encontrada'}
            loadingMessage={() => 'Carregando operadoras...'}
            isClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <TextField
            ref={fields.battery}
            fullWidth
            className={classes.fullWidthField}
            label='Bateria (mAh)'
            name='battery'
          />
        </Grid>
      </Grid>

      <Grid container justify='flex-start' spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.equipmentType}
            label='Tipo de Equipamento'
            name='equipmentType'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt.id}
            options={equipmentTypes}
            isLoading={isLoading.equipmentType}
            noOptionsMessage={() => 'Nenhuma tipo encontrado'}
            loadingMessage={() => 'Carregando tipos de equipamento...'}
            isClearable={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <AutocompleteField
            innerRef={fields.baitType}
            label='Tipo de Isca'
            name='baitType'
            getOptionLabel={opt => opt.name}
            getOptionValue={opt => opt.id}
            options={baitTypes}
            isLoading={isLoading.baitType}
            noOptionsMessage={() => 'Nenhuma tipo encontrado'}
            loadingMessage={() => 'Carregando tipos de iscas...'}
            isClearable={true}
          />
        </Grid>
      </Grid>

      <Grid container justify='flex-start' spacing={2} style={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <RadioGroupField
            ref={fields.antiJamming}
            row
            title="Anti-Jammer"
            options={[
              { value: 'Não', label: 'Não' },
              { value: 'Sim', label: 'Sim' },
            ]}
            labelProps={{
              style: {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
              },
            }}
            formControlLabelProps={{
              labelPlacement: 'start',
            }}
          />
        </Grid>

        <Permission name='EquipmentEnableDisable'>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <RadioGroupField
              ref={fields.isActive}
              row
              title="Ativo"
              options={[
                { value: 'false', label: 'Não' },
                { value: 'true', label: 'Sim' },
              ]}
              labelProps={{
                style: {
                  transform: 'translate(0, 1.5px) scale(0.75)',
                  transformOrigin: 'top left',
                },
              }}
              formControlLabelProps={{
                labelPlacement: 'start',
              }}
            />
          </Grid>
        </Permission>
      </Grid>
    </Modal>
    </div>
  )
}

export default ModalEquipmentEdit;
