import React, { 
  useState, 
  useEffect, 
  useImperativeHandle,
  useRef
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, InputAdornment, Typography, Paper, TextField } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar'
import DatePicker from "components/pickers/DatePicker";
import moment from 'moment';
import {
  ButtonSubmit,
  ButtonRemove,
  ButtonCancel,
  ButtonGeneric,
  ButtonDownload,
  CommonButton,
} from 'components/buttons/ButtonsForm';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RightIcon from 'assets/icon/right.png';
import ItemTableForm from 'components/table/TableForm';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  IconButton,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import {
  Close
} from '@material-ui/icons';

import Permission from 'components/permissions/Permission';
import Form, { FormSection } from './Form';
// import { TextField, RfFileUpload } from '../fields';
import { AutocompleteField, RadioGroupField } from '../fields';
import { useFields } from '../../hooks/fields';
import { useFetch, useAuthenticated } from '../../hooks/fetch';
import { emitEvent } from '../../utils/events';
import { Rule, validate } from "../../utils/formValidator";

import theme from 'styles/theme';
import { EQUIPMENT_STATUS } from 'utils/statuses';
import NumberInput from 'components/fields/NumberInput.field';
import { postWithErrorHandler } from 'services/fetch';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    marginTop: 12,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  buttonLabel: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    fontWeight: theme.typography.fontWeight['700'],
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  textRoot: {
    minHeight: 'auto'
  },
  formSectionTitle: {
    color: theme.palette.gray.gray_6,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 30,
    fontSize: 20,
  },
  textarea: {
    borderRadius: 22,
    border: 'solid 1px #808492',
    resize: 'none',
    outline: 'none',
    padding: 22,
    fontFamily: 'Raleway',
    fontSize: 15,
    width: '100%',
    fontWeight: '400',
    marginTop: 10,
    marginBottom: 20
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  dateLabel: {
    fontSize: 12,
    // fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
}));

const NewBipForm = props => {
  const classes = useStyles({});
  const ref = useRef(null);
  const [isLoading, setIsLoading] = useState({
    companies: false,
    mobileOperators: false,
    colors: false,
    exemplaries: false,
    packings: false,
    models: false,
    equipmentType: false,
    baitType: false,
    form: false,
  });
  const [submitForm, setSubmitForm] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [tableItems, setTableItems] = useState([{ id: 1, imei: '', rf: '', lora: '' }]);


  const [file, setFile] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [baitTypes, setBaitTypes] = useState([]);
  const [orderNumber, setOrderNumber] = useState('');
  const [date, setDate] = useState('');
  const [battery, setBattery] = useState('');
  const [observations, setObservations] = useState('');
  const reader = useRef(new FileReader());
  const [validations, setValidations] = useState({
    rules: [
      Rule(
        'isEmpty', [], false,
        'Campo de preenchimento obrigatório',
         value => value ? value.name : ''
      )
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const fields = useFields([
    'company',
    'equipmentType',
    'baitType',
    'exemplary',
    'color',
    'packing',
    'model'
  ]);

  const validateField = () => {
    const _rules = (false || file) ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(file, _rules)
    });
  };

  async function urltoFile({url, filename, mimetype}){
    try {
      const res = await fetch(url);
      const buf = await res.arrayBuffer();
      setFile(new File([buf], filename, {type: mimetype}));
    } catch (err) {
      console.log(err);
    }
  }

  const handleChangeOrderNumber = (e) => {
    setOrderNumber(e.target.value); // Atualiza o estado do nome com o valor atual do campo
  };

  const setFieldValue = async (value) => {
    if (value && value.url && value.mimetype && value.filename) {
      await urltoFile(value);
    } else {
      console.log(`${value} is not a valid value`);
    }
  }

  useImperativeHandle(ref, () => ({
    value: file,
    raw: !file ? {} : {filename: file.name, mimetype: file.type, url: reader.current.result},
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue
  }));

  /**======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    setFormDisabled({ form: true });
    // setTimeout(() => {
    if (props.data) {
      for (const [key, value] of Object.entries(props.data)) {
        if(['date', 'orderNumber', 'battery', 'observations', 'equipments'].includes(key)) {
          if(key === 'date') {
            
            let dateString = value.split('T')[0]
            const dateYear = dateString.split('-')[0]
            const dateMonth = dateString.split('-')[1]
            const dateDay = dateString.split('-')[2]
            const __dateString = `${dateDay}/${dateMonth}/${dateYear}`
            const __date = moment(__dateString, 'DD/MM/YYYY')
            setDate(__date)
          } else if(key === 'orderNumber') {
            setOrderNumber(value)
          } else if(key === 'battery') {
            setBattery(value)
          } else if(key === 'observations') {
            setObservations(value)
          } else if(key === 'equipments') {
            const __tableData = []
            for(const [index, row] of value.entries()) {
              __tableData.push({
                id: index+1,
                imei: row['imei'],
                rf: row['rf'],
                lora: row['lora']
              })
            }
            setTableItems(__tableData)
          }
        } else {
          if (fields[key] && fields[key].current) {
            if (fields[key].current.setFieldValue) {
              fields[key].current.setFieldValue(value);
            }
          }
        }
      }
    } else {
      let currentDate = moment()
      setDate(currentDate)
    }
  // }, 300)
    setFormDisabled(false);
  }, [props.data]);

  // Load companies
  const { data } = useAuthenticated(useFetch, 'company?notPaginated=true', {
    beforeFetch() {
      setIsLoading({ ...isLoading, companies: true });
    },
  });

  // Sort companies
  useEffect(() => {
    const sortedCompanies =
      (data && data.data.sort((a, b) => (a.name > b.name ? 1 : -1))) || [];
    setCompanies(sortedCompanies);
    setIsLoading({ ...isLoading, companies: false });
  }, [data]);

  // Load equipment types
  useEffect(() => {
    setIsLoading({ ...isLoading, equipmentType: true });

    const eTypes = [
      { id: 1, name: 'Isca' },
      { id: 2, name: 'Veículo Passeio' },
      { id: 3, name: 'Truck' },
      { id: 4, name: 'Carreta' },
      { id: 5, name: 'Moto' },
      { id: 6, name: 'Cavalo' },
      { id: 7, name: 'Bloqueador' },
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    setEquipmentTypes(eTypes);
    setIsLoading({ ...isLoading, equipmentType: false });
  }, []);

  // Load bait types
  useEffect(() => {
    setIsLoading({ ...isLoading, baitType: true });

    const bTypes = [
      { id: 1, name: 'Retornável' },
      { id: 2, name: 'Descartável' },
      { id: 3, name: 'Customizada' },
      { id: 4, name: 'Teste' },
    ].sort((a, b) => (a.name > b.name ? 1 : -1));

    setBaitTypes(bTypes);
    setIsLoading({ ...isLoading, baitType: false });
  }, []);

  // Load exemplaries
  const { data: exemplaries } = useAuthenticated(
    useFetch,
    'equipment-exemplary/list',
    {
      beforeFetch() {
        setIsLoading({ ...isLoading, exemplaries: true });
      },
      afterFetch() {
        setIsLoading({ ...isLoading, exemplaries: false });
      },
      defaultValue: [],
    }
  );

  // Load colors
  const { data: colors } = useAuthenticated(useFetch, 'equipment-color/list', {
    beforeFetch() {
      setIsLoading({ ...isLoading, colors: true });
    },
    afterFetch() {
      setIsLoading({ ...isLoading, colors: false });
    },
    defaultValue: [],
  });

  // Load pakckings
  const { data: packings } = useAuthenticated(
    useFetch,
    'equipment-packing/list',
    {
      beforeFetch() {
        setIsLoading({ ...isLoading, packings: true });
      },
      afterFetch() {
        setIsLoading({ ...isLoading, packings: false });
      },
      defaultValue: [],
    }
  );

  // Load models
  const { data: models } = useAuthenticated(useFetch, 'equipment-model/list', {
    beforeFetch() {
      setIsLoading({ ...isLoading, models: true });
    },
    afterFetch() {
      setIsLoading({ ...isLoading, models: false });
    },
    defaultValue: [],
  });

  const validateForm = async event => {
    event.preventDefault();

    const nonValidatedFields = Object.values(fields).filter(
      f => !f.current.alreadyValidated
    );
    nonValidatedFields.map(f => f.current.validateField());

    setSubmitForm(true);
  };

  const onDateChange = e => {
    if(e !== null && e.hasOwnProperty('date')) {
      console.log('entering here')
      setDate(e.date.format('DD/MM/YYYY'))
    } else {
      setDate(null)
    }
    // if(e !== null)
    // console.log(e.format('DD/MM/YYYY'))
  }

  // Handle form submission
  useEffect(() => {
    if (submitForm) {
      (async () => {
        await handleSubmit();
        setSubmitForm(false)
      })();
    }
  }, [submitForm]);

  const validateRemainingFields = () => {

    if(date === '' || date === null) {
      emitEvent('showSnack', {
        message: 'O campo Data de Saída é obrigatório',
        type: 'error',
      });
      return false
    }

    if(battery === '' || battery === null) {
      emitEvent('showSnack', {
        message: 'O campo Bat(mAh) é obrigatório',
        type: 'error',
      });
      return false
    }

    if(orderNumber === '' || orderNumber === null) {
      emitEvent('showSnack', {
        message: 'O campo Nº do pedido é obrigatório',
        type: 'error',
      });
      return false
    }


    return true
  }

  const validateTableData = () => {

    const errorRows = []

    let invalidMessage = 'Existem campos não preenchidos nas linhas '

    for(const [index, row] of tableItems.entries()) {
      // let rowMessage = ''
      // if(row['imei'] === '' && row['rf'] === '') {
      //   rowMessage = 'Imei e Rf não preenchidos'
      // } else if(row['imei'] === '' && row['rf'] !== '') {
      //   rowMessage = 'Imei não preenchido'
      // } else if(row['imei'] !== '' && row['rf'] === '') {
      //   rowMessage = 'RF não preenchidos'
      // }

      // invalidMessage += `Linha: ${index+1} - ${rowMessage}\n`

      if(row['imei'] === '' || row['rf'] === ''){
        errorRows.push(index+1)
      }
    }

    if(errorRows.length > 0) {
      invalidMessage += errorRows.join(', ') + ' da tabela.'

      emitEvent('showSnack', {
        message: invalidMessage,
        type: 'error',
      });

      return false
    } else {
      return true
    }
  }

  const handleEndProduction = async () => {
    props.handleEndProduction()
  }

  const handleSubmit = async () => {

    const invalidFields = Object.values(fields).filter(
      f => f.current.isInvalid
    );

    if (invalidFields.length > 0) {
      emitEvent('showSnack', {
        message: 'Erro ao validar formulário',
        type: 'error',
      });
      setSubmitForm(false);
    } else {
      if(validateRemainingFields() && validateTableData()) {
        const data = {};
        Object.entries(fields).forEach(([key, value]) => {
          data[key] = value.current.raw;
        });

        data['date'] = date
        data['orderNumber'] = orderNumber
        data['battery'] = battery
        data['observations'] = observations
        data['equipments'] = tableItems
        data['equipmentCount'] = tableItems.length

        props.handleSubmit(data)
      }
    }
  };

  const handleTableAddRow = () => {
    if(tableItems.length === 2000) {
      emitEvent('showSnack', {
        message: 'O limite de linhas é 2000',
        type: 'error',
      });
    } else {
      const newItem = { id: tableItems.length + 1, imei: '', rf: '', lora: '' };
      setTableItems([...tableItems, newItem]);
    }
  }

  const handleTableRemoveRow = (id) => {
    setTableItems(tableItems.filter(item => item.id !== id));
  }

  const handleTableChange = (id, field, value) => {
    const newItems = tableItems.map(item => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setTableItems(newItems);
  }

  return (
    <Form title={props.title}>
    <Typography
        className={classes.formSectionTitle}
      >
      Informações gerais</Typography>
      <Grid container spacing={6}>
        {/* Left half - ItemTableForm */}
        <Grid item xs={12} sm={6}>
          <Paper>
            <ItemTableForm
            items={tableItems}
            onAddRow={handleTableAddRow}
            onRemoveRow={handleTableRemoveRow}
            onChange={handleTableChange}
             />
          </Paper>
        </Grid>

        {/* Right half - The form */}
        <Grid item xs={12} sm={6}>
          <form onSubmit={validateForm} noValidate>
            <FormSection divider>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                {/* <FormControl fullWidth className={classes.fullWidthField}> */}
                  <AutocompleteField
                    required
                    innerRef={fields.company}
                    label="Empresa"
                    name="company"
                    options={companies}
                    isLoading={isLoading.companies}
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt._id}
                    noOptionsMessage={() => 'Nenhuma empresa encontrada'}
                    loadingMessage={() => 'Carregando empresas...'}
                    isClearable={true}
                    disabled={formDisabled}
                  />
                  </Grid>
                {/* </FormControl> */}
                {date && <Grid item xs={12} sm={6}>
                  <Typography className={classes.dateLabel}>Data de Saída:</Typography>
                  <DatePicker
                    // {...args}
                    format="DD/MM/YYYY"
                    placeholder="Data de Saída"
                    // innerRef={fields.date}
                    // onChange={onInitialDateChange}
                    onChange={onDateChange}
                    value={date}
                  />
                </Grid>}
                <Grid item xs={12} sm={6}>
                  {/* <TextField
                    required
                    type="text"
                    fullWidth
                    className={classes.fullWidthField}
                    label="Tipo de Equipamento"
                    name="description"
                    disabled={formDisabled}
                  /> */}
                  <AutocompleteField
                    required
                    innerRef={fields.equipmentType}
                    label="Tipo de Equipamento"
                    name="equipmentType"
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt.id}
                    options={equipmentTypes}
                    isLoading={isLoading.equipmentType}
                    noOptionsMessage={() => 'Nenhuma tipo encontrado'}
                    loadingMessage={() => 'Carregando tipos de equipamento...'}
                    isClearable={true}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    required
                    innerRef={fields.baitType}
                    label="Tipo de Isca"
                    name="baitType"
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt.id}
                    options={baitTypes}
                    isLoading={isLoading.baitType}
                    noOptionsMessage={() => 'Nenhuma tipo encontrado'}
                    loadingMessage={() => 'Carregando tipos de iscas...'}
                    isClearable={true}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    innerRef={fields.exemplary}
                    label="Exemplar de equipamento"
                    name="exemplary"
                    required
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt._id}
                    options={exemplaries}
                    isLoading={isLoading.exemplaries}
                    noOptionsMessage={() => 'Nenhum exemplar encontrado'}
                    loadingMessage={() => 'Carregando exemplares...'}
                    isClearable={true}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    innerRef={fields.color}
                    label="Cor"
                    name="color"
                    required
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt._id}
                    options={colors}
                    isLoading={isLoading.colors}
                    noOptionsMessage={() => 'Nenhuma cor encontrada'}
                    loadingMessage={() => 'Carregando cores...'}
                    isClearable={true}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    innerRef={fields.packing}
                    label="Embalagem"
                    name="packing"
                    required
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt._id}
                    options={packings}
                    isLoading={isLoading.packings}
                    noOptionsMessage={() => 'Nenhuma embalagem encontrada'}
                    loadingMessage={() => 'Carregando embalagem...'}
                    isClearable={true}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    innerRef={fields.model}
                    label="Modelo"
                    name="model"
                    required
                    getOptionLabel={opt => opt.name}
                    getOptionValue={opt => opt._id}
                    options={models}
                    isLoading={isLoading.models}
                    noOptionsMessage={() => 'Nenhum modelo encontrado'}
                    loadingMessage={() => 'Carregando modelos...'}
                    isClearable={true}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    type="text"
                    fullWidth
                    className={classes.fullWidthField}
                    label="Nº do pedido"
                    name="orderNumber"
                    value={orderNumber}
                    // onChange={handleChangeOrderNumber}
                    onChange={e => setOrderNumber(e.target.value)}
                    disabled={formDisabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    type="text"
                    fullWidth
                    className={classes.fullWidthField}
                    label="BAT(mAh)"
                    name="battery"
                    value={battery}
                    onChange={e => setBattery(e.target.value)}
                    // setValue={setBattery}
                    disabled={formDisabled}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection>
              <Grid container justify="flex-start" spacing={4}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>OBS:</Typography>
                  <textarea className={classes.textarea} value={observations} onChange={e => setObservations(e.target.value)} name="observation" rows="5"></textarea>
                </Grid>
              </Grid>
            </FormSection>

            <Grid container justify="flex-end" spacing={1}>
            <Grid item xs={12} md="auto">
              <ButtonCancel
                onClick={props.handleCancel}
                disabledCondition={formDisabled}
              ></ButtonCancel>
            </Grid>
            <Grid item xs={12} md="auto">
              <ButtonSubmit
                label="Salvar BIP"
                onClick={props.handleSubmit}
                disabledCondition={formDisabled}
                colorSecondary={true}
              ></ButtonSubmit>
            </Grid>
            {props.isEditing && 
            <Grid item xs={12} md="auto">
              <CommonButton
                label = "Finalizar Produção"
                onClick={props.handleEndProduction}
                disabledCondition={formDisabled}
                color={'primary'}
              ></CommonButton>
            </Grid>}
            <Grid item xs={12} md="auto">
              <ButtonRemove
                label="Remover BIP"
                onClick={props.handleRemove}
                disabledCondition={formDisabled}
              ></ButtonRemove>
            </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Form>
  );
};

const styles = theme => ({
  btnRoot: {
    minWidth: 125,
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});
export default withStyles(styles)(NewBipForm);
