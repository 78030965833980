/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { usePaginatedData } from 'hooks/common';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactSVG from 'react-svg';

import Typography from '@material-ui/core/Typography';
import {
  IconButton, Fade
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import Grid from '@material-ui/core/Grid';

import { useQuery } from 'hooks/fetch';

import { getUser } from 'services/user';
import ClockIcon from 'assets/img/clock.png';
import ModalDashboardSettings from 'components/modals/ModalDashboardSettings';

import TotalIcon from 'assets/icon/dashboard/total_equipment.svg';
import StableIcon from 'assets/icon/dashboard/stable.svg';
import LowBatteryIcon from 'assets/icon/dashboard/low_battery.svg';
import TimeWithNoPositioningIcon from 'assets/icon/dashboard/time_with_no_position.svg';
import ScheduledIcon from 'assets/icon/dashboard/scheduled.svg';
import ExpiredIcon from 'assets/icon/dashboard/expired.svg';
import JammerIcon from 'assets/icon/dashboard/jammer.svg';
import JammerWhiteIcon from 'assets/icon/dashboard/jammer_white.svg';
import NoBatteryIcon from 'assets/icon/dashboard/no_battery.svg';
import AntennaIcon from 'assets/icon/dashboard/antenna.svg';

import SimpleTable from 'components/table/Table';
import TableMenu, { TableColumn } from 'components/table/TableMenu';
import StatusColumn, {
  StatusBall,
} from 'components/table/columns/StatusColumn';
import CardContainer from 'components/dashboard/CardContainer';
import Permission from 'components/permissions/Permission';

import { emitEvent } from 'utils/events';
import { EMERGENCY_LABELS, getEmergencyLabel } from 'utils/labels';

import { useAccessLog } from 'hooks/logs';
import { get, fetchAuthenticated } from 'services/fetch';
import { getEquipmentRowActions } from 'utils/equipment';

import XGlobalLoading from 'components/loading/XGlobalLoading';

import * as moment from 'moment';
import 'moment/locale/pt-br';

const REFRESH_INTERVAL = 60;

const styles = theme => ({
  container: {
    flexGrow: 1,
  },
  dashboardContainer: {
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    paddingTop: theme.main.padding.top,
    height: '100%',
    width: '100%',
  },
  paper: {
    borderRadius: theme.border.radius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  dashboardMonitoringContainer: {
    width: '100%',
  },
  dashboardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  dashboardHeaderText: {
    fontSize: theme.text.header.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  dashboardText: {
    fontSize: theme.text.content.fontSize,
    fontWeight: theme.text.content.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  dashboardTextBold: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  dashboardUpdatedText: {
    fontSize: theme.text.content.fontSize,
    fontWeight: 600,
    color: theme.palette.gray.gray_4,
  },
  dashboardClockIcon: {
    width: 16,
    height: 16,
  },
  notificationContainer: {
    background: theme.palette.background.white,
    height: '100%',
    paddingLeft: 59,
    paddingRight: 59,
  },
  notificationHeaderLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: 10,
    paddingTop: 25,
  },
  notificationHeaderText: {
    fontSize: theme.text.header.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  expandA: {
    fontSize: theme.text.subheader.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.text.blue.blue_1,
  },
  jammerItem: {
    paddingBottom: 20,
    paddingTop: 28,
    display: 'flex',
    paddingLeft: 19,
    paddingRight: 19,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 'solid 1px rgba(128, 132, 146, 0.3)',
  },
  jammerIcon: {
    width: 38,
    height: 38,
  },
  jammerInfo: {},
  jammerLabel: {
    fontSize: theme.text.subheader.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  jammerHourLabel: {
    fontSize: theme.text.content.fontSize,
    fontWeight: theme.text.content.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  jammerStatus: {
    cursor: 'pointer',
    fontSize: theme.text.subheader.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.text.blue.blue_1,
  },
  followingItem: {
    cursor: 'pointer',
    paddingBottom: 25,
    paddingTop: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px rgba(128, 132, 146, 0.3)',
  },
  followingLabel: {
    fontSize: theme.text.subheader.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
});

function NotificationBar(props) {
  const { classes, followingList, refresh } = props;
  const [notifications, setNotifications] = useState([]);
  const [effect, setEffect] = useState({});

  const now = moment();

  useEffect(() => {
    const fn = async () => {
      const _data = '';
      /* const _data = await fetchAuthenticated(
        get,
        'notification?filter={"wasSeen": false}'
      ); */
      // console.log(_data);
      setNotifications(_data);
      const effects = {};
      _data.forEach(n => {
        effects[n._id] = true;
      });
      setEffect(effects);
    };
    // fn();
  }, [refresh]);

  const getNotificationDuration = time => {
    const duration = moment.duration(now.diff(moment(time)));
    return duration.humanize();
  };

  const onSeenClick = async notification => {
    await fetchAuthenticated('put', 'notification/mark-as-seen', {
      _id: notification._id,
    });
  };

  return (
    <div className={classes.notificationContainer}>
      {notifications.length === 0 && followingList.length === 0 && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <ReactSVG
            src={require('assets/icon/placeholder_notifications.svg')}
            onClick={() => {
              console.log('wrapper onClick');
            }}
          />
          <Typography
            style={{
              textAlign: 'center',
              fontSize: 16,
              color: '#8E96B0',
            }}
          >
            Nenhuma atividade para ser <br />
            notificada.
          </Typography>
        </div>
      )}
      {/* {notifications.length > 0 && (
        <>
          <div className={classes.notificationHeaderLine}>
            <Typography className={classes.notificationHeaderText}>
              Notificações
            </Typography>
          </div>
          <PerfectScrollbar style={{ maxHeight: 500, height: 'unset' }}>
            {notifications.map(item => (
              <Fade
                in={effect[item._id]}
                key={item._id}
                timeout={{
                  enter: 1000,
                  exit: 400,
                }}
                unmountOnExit
              >
                <div className={classes.jammerItem}>
                  <img src={JammerIcon} className={classes.jammerIcon} alt="" />
                  <div className={classes.jammerInfo}>
                    <Typography className={classes.jammerLabel}>
                      {EMERGENCY_LABELS[item.text]}
                    </Typography>
                    <Typography className={classes.jammerHourLabel}>
                      há {getNotificationDuration(item.date)}
                    </Typography>
                  </div>
                  <Typography
                    className={classes.jammerStatus}
                    onClick={() => {
                      setEffect({ ...effect, ...{ [item._id]: false } });
                      onSeenClick(item);
                    }}
                  >
                    Ok
                  </Typography>
                </div>
              </Fade>
            ))}
            {notifications.length === 0 && (
              <Typography className={classes.jammerLabel}>
                0 Notificações
              </Typography>
            )}
          </PerfectScrollbar>
        </>
      )} */}
      {followingList.length > 0 && (
        <>
          <div className={classes.notificationHeaderLine}>
            <Typography className={classes.notificationHeaderText}>
              Seguindo
            </Typography>
            <a className={classes.expandA} />
          </div>
          {followingList.map(item => (
            <div
              key={item._id}
              className={classes.followingItem}
              onClick={() => {
                props.history.push(`detalhes-equipamento/${item._id}`);
              }}
            >
              <Typography className={classes.followingLabel}>
                {item.imei.substring(item.imei.length, item.imei.length - 8)}
              </Typography>
              {/* <StatusBall status={item.handlingStatus} /> */}
              <StatusBall status={item.currentHandlingStatus} />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

function MonitoringDashboard(props) {
  const { classes, refresh } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [equipmentCount, setEquipmentCount] = useState(0);
  const cardContainerRef = useRef(null);
  const aspectRatioY = 4.66;
  const aspectRatioX = 5.43;
  const minHeight = 235;
  const minWidth = 177;

  const [cardWidth, setCardWidth] = useState(188);
  const [cardHeight, setCardHeight] = useState(235);

  useEffect(() => {
    const handleResize = () => {
      const { width } = cardContainerRef.current.getBoundingClientRect();
      const newWidth = width / aspectRatioX;
      const newHeight = width / aspectRatioY;
      if (newWidth >= minWidth) {
        setCardWidth(width / aspectRatioX);
      }
      if (newHeight >= minHeight) {
        setCardHeight(width / aspectRatioY);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cardContainerRef]);

  useEffect(() => {
    const fn = async () => {
      /* const _data = await get('equipment', {
        'currentCompany._id': { $exists: true },
        lastEvent: { $exists: true },
      });
      setEquipmentCount(_data.totalItems); */
    };
    fn();
  }, [refresh]);

  const onSettingsClick = () => {
    setIsVisibleModal(true);
  };

  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let _seconds = seconds;
    const id = setInterval(() => {
      if (_seconds === REFRESH_INTERVAL) {
        _seconds = 0;
      }
      setSeconds(_seconds + 1);
      _seconds++;
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <div className={classes.dashboardMonitoringContainer}>
      <div ref={cardContainerRef} className={classes.dashboardHeader}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <ModalDashboardSettings
              isVisible={isVisibleModal}
              setIsVisible={setIsVisibleModal}
            />
            <Typography className={classes.dashboardHeaderText}>
              Monitoramento
            </Typography>
            <Permission name="DashboardConfiguration">
              <IconButton
                aria-label="Configuração dashboard"
                onClick={onSettingsClick}
              >
                <SettingsIcon />
              </IconButton>
            </Permission>
          </div>
          <div className={classes.dashboardHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className={classes.dashboardClockIcon}
                src={ClockIcon}
                alt=""
              />
              <Typography className={classes.dashboardUpdatedText}>
                {' '}
                &nbsp; Atualizado há {seconds} segundos
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <CardContainer
        cardHeight={cardHeight}
        cardWidth={cardWidth}
        cardContainerRef={cardContainerRef}
        cards={[
          {
            _id: '0',
            url: 'equipment/situation',
            filter: {
              isActive: true,
              'currentCompany._id': { $exists: true },
              lastEvent: { $exists: true },
            },
            linkTo: '/dashboard/total-ativos',
            color: '#548cc1',
            label: 'Total de equipamentos',
            icon: TotalIcon,
          },
          {
            _id: '1',
            url: 'equipment/situation',
            filter: {
              $nor: [
                // Bateria abaixo do esperado
                {
                  $or: [
                    {
                      'lastEvent.protocolVersion': { $ne: 'V2' },
                      'lastEvent.rfTriangulation': false,
                    },
                    {
                      dashboardCard: 'NOT_SET',
                      situation: 'LOW_BATTERY',
                      handlingStatus: { $ne: 'RF_ACTIVE' },
                    },
                    {
                      dashboardCard: 'LOW_BATTERY',
                    },
                  ],
                },
                // Tempo sem posicionar
                {
                  isActive: true,
                  'currentCompany._id': { $exists: true },
                  lastEvent: { $exists: true },
                  situation: 'NO_POSITIONING',
                  'lastEvent.internalBatteryPercentage': { $gt: 1 }
                },
                // Aguardando ativação
                {
                  situation: 'SCHEDULED',
                },
                // Equipamentos expirados
                {
                  isActive: true,
                  'currentCompany._id': { $exists: true },
                  lastEvent: { $exists: true },
                  "poffCommands.0.status": "COMPLETED",
                  "status": "registered",
                  "baitType.name": { "$in": ["Descartável", "Retornável", "Teste", "Customizada"] },
                  $nor: [
                    {
                      situation: { $ne: 'STABLE' },
                      currentHandlingStatus: 'emergency',
                      'currentCompany._id': { $exists: true },
                      lastEvent: { $exists: true },
                      emergencyType: { $ne: 'NO_BATTERY' },
                      isActive: true,
                    }
                  ]
                },
                // Sem bateria
                {
                  currentHandlingStatus: 'emergency',
                  situation: 'NO_BATTERY',
                  emergencyType: 'NO_BATTERY',
                  'currentCompany._id': { $exists: true },
                  handlingStatus: { $ne: 'RF_ACTIVE' },
                  isActive: true,
                  lastEvent: { $exists: true },
                  'lastEvent.status.temperatureAlarm': false,
                  'lastEvent.status.temperatureAlarmMode': false,
                  'lastEvent.status.movementSensorAlarm': false,
                  'lastEvent.status.jammingDetected': false
                },
                // Emergências
                {
                  situation: { $ne: 'STABLE' },
                  currentHandlingStatus: 'emergency',
                  'currentCompany._id': { $exists: true },
                  lastEvent: { $exists: true },
                  emergencyType: { $ne: 'NO_BATTERY' },
                  isActive: true,
                },
                // RF Ativo
                {
                  $or: [
                    {
                      situation: 'STABLE',
                      isActive: true,
                      'currentCompany._id': { $exists: true },
                      lastEvent: { $exists: true },
                      $or: [
                        {
                          'lastEvent.protocolVersion': 'V2',
                          $or: [
                            { 'lastEvent.status.transmitter434Mhz': true },
                            { 'lastEvent.status.transmitter928Mhz': true },
                          ],
                        },
                        {
                          'lastEvent.protocolVersion': { $ne: 'V2' },
                          'lastEvent.rfTriangulation': true,
                        },
                      ],
                    },
                  ],
                }
              ],
              // Adicione aqui quaisquer outras condições gerais que se aplicam a todos os Equipamentos Estáveis
              // Por exemplo, se todos os Equipamentos Estáveis devem estar ativos:
              isActive: true,
              'currentCompany._id': { $exists: true },
              lastEvent: { $exists: true },
            },
            linkTo: '/dashboard/estaveis',
            color: '#5bf293',
            label: 'Equipamentos estáveis',
            icon: StableIcon,
          },
          {
            _id: '2',
            url: 'equipment/situation',
            filter: {
              $or: [
                {
                  'lastEvent.protocolVersion': 'V2',
                  'lastEvent.status.transmitter434Mhz': false,
                  'lastEvent.status.transmitter928Mhz': false,
                  'lastEvent.status.temperatureAlarm': false,
                  'lastEvent.status.temperatureAlarmMode': false,
                  'lastEvent.status.movementSensorAlarm': false,
                  'lastEvent.status.jammingDetected': false
                },
                {
                  'lastEvent.protocolVersion': { $ne: 'V2' },
                  'lastEvent.rfTriangulation': false,
                },
              ],
              $or: [
                {
                  dashboardCard: 'NOT_SET',
                  isActive: true,
                  'currentCompany._id': { $exists: true },
                  lastEvent: { $exists: true },
                  situation: 'LOW_BATTERY',
                  handlingStatus: { $ne: 'RF_ACTIVE' },
                },
                {
                  dashboardCard: 'LOW_BATTERY',
                },
              ],
            },
            linkTo: '/dashboard/bateria-baixa',
            color: '#fbe622',
            label: 'Bateria abaixo do esperado',
            icon: LowBatteryIcon,
          },
          {
            _id: '3',
            url: 'equipment/situation',
            filter: {
              isActive: true,
              'currentCompany._id': { $exists: true },
              lastEvent: { $exists: true },
              situation: 'NO_POSITIONING',
              'lastEvent.internalBatteryPercentage': { $gt: 1 }
            },
            linkTo: '/dashboard/sem-posicionar',
            color: '#ffc500',
            label: 'Tempo sem posicionar',
            icon: TimeWithNoPositioningIcon,
          },
          {
            _id: '4',
            url: 'equipment/situation',
            filter: {
              isActive: true,
              'currentCompany._id': { $exists: true },
              lastEvent: { $exists: true },
              situation: 'SCHEDULED',
            },
            linkTo: '/dashboard/aguardando-ativacao',
            color: '#ffa364',
            label: 'Aguardando ativação',
            icon: ScheduledIcon,
          },
          {
            _id: '6',
            url: 'equipment/situation',
            filter: {
              isActive: true,
              lastEvent: { $exists: true },
              'currentCompany._id': { $exists: true },
              "poffCommands.0.status": "COMPLETED",
              "status": "registered",
              "baitType.name": {
                "$in": [
                  "Descartável",
                  "Retornável",
                  "Teste",
                  "Customizada"
                ]
              },
            },
            linkTo: '/dashboard/expirados',
            color: '#e58645',
            label: 'Equipamentos expirados',
            icon: ExpiredIcon,
          },
          {
            _id: '7',
            url: 'equipment/situation',
            filter: {
              currentHandlingStatus: 'emergency',
              situation: 'NO_BATTERY',
              emergencyType: 'NO_BATTERY',
              'currentCompany._id': { $exists: true },
              handlingStatus: { $ne: 'RF_ACTIVE' },
              isActive: true,
              lastEvent: { $exists: true },
              'lastEvent.status.temperatureAlarm': false,
              'lastEvent.status.temperatureAlarmMode': false,
              'lastEvent.status.movementSensorAlarm': false,
              'lastEvent.status.jammingDetected': false
            },
            linkTo: '/dashboard/sem-bateria',
            color: '#ec6a6a',
            label: 'Sem bateria',
            icon: NoBatteryIcon,
          },
          {
            _id: '8',
            url: 'equipment/situation',
            filter: {
              situation: { $ne: 'STABLE' },
              currentHandlingStatus: 'emergency',
              'currentCompany._id': { $exists: true },
              lastEvent: { $exists: true },
              emergencyType: { $ne: 'NO_BATTERY' },
              isActive: true,
            },
            linkTo: '/dashboard/jammer-detectado',
            color: '#e44a4a',
            label: 'Emergências',
            icon: JammerWhiteIcon,
          },
          {
            _id: '9',
            url: 'equipment/situation',
            filter: {
              $or: [
                {
                  situation: 'STABLE',
                  isActive: true,
                  'currentCompany._id': { $exists: true },
                  lastEvent: { $exists: true },
                  $or: [
                    {
                      'lastEvent.protocolVersion': 'V2',
                      $or: [
                        {
                          'lastEvent.status.transmitter434Mhz': true,
                        },
                        {
                          'lastEvent.status.transmitter928Mhz': true,
                        },
                      ],
                    },
                    {
                      'lastEvent.protocolVersion': { $ne: 'V2' },
                      'lastEvent.rfTriangulation': true,
                    },
                  ],
                },
              ],
            },
            linkTo: '/dashboard/rf-ativo',
            color: '#dd0c0b',
            label: 'RF Ativo',
            icon: AntennaIcon,
          }
        ]}
        refresh={refresh}
      />
    </div>
  );
}

function Dashboard(props) {
  useAccessLog('Acesso ao dashboard');
  const { classes, location } = props;

  const [refreshPage, setRefreshPage] = useState(false);
  const _refreshPage = useRef(refreshPage);

  const [suspectEquipments, setSuspectEquipments] = usePaginatedData();
  const [emergencyEquipments, setEmergencyEquipments] = usePaginatedData();
  const path = 'equipment';

  const [querySuspect, setQuerySuspect, isSuspectLoading] = useQuery(
    path,
    setSuspectEquipments,
    undefined,
    {
      filter: {
        // handlingStatus: 'suspect',
        currentHandlingStatus: 'suspect',
        'currentCompany._id': { $exists: true },
        lastEvent: { $exists: true },
        isActive: true,
      },
    },
    undefined,
    refreshPage
  );
  const [queryEmergency, setQueryEmergency, isEmergencyLoading] = useQuery(
    path,
    setEmergencyEquipments,
    undefined,
    {
      filter: {
        currentHandlingStatus: 'emergency',
        'currentCompany._id': { $exists: true },
        lastEvent: { $exists: true },
        situation: { $ne: 'STABLE' },
        emergencyType: { $ne: 'NO_BATTERY' },
        isActive: true,
      },
    },
    undefined,
    refreshPage
  );

  const [followedEquipments, setFollowedEquipments] = useState([]);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const id = setInterval(() => {
      emitEvent('showGlobalLinearProgress');
      setRefreshPage(!_refreshPage.current);
      setTimeout(() => {
        emitEvent('hideGlobalLinearProgress');
      }, 500);
    }, REFRESH_INTERVAL * 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    _refreshPage.current = refreshPage;
  }, [refreshPage]);

  // useEffectSkipFirst(() => {
  //   const fn = async () => {
  //     const _data = await fetchAuthenticated(get, 'equipment?filter={"handlingStatus":"suspect"}');
  //     setSuspectEquipments(_data);
  //   }
  //   fn();
  // }, [refreshPage]);

  // useEffectSkipFirst(() => {
  //   const fn = async () => {
  //     const _data = await fetchAuthenticated(get, 'equipment?filter={"handlingStatus":"emergency"}');
  //     setEmergencyEquipments(_data);
  //   }
  //   fn();
  // }, [refreshPage]);

  useEffect(() => {
    let id;
    const fn = async () => {
      let currentUser = await getUser();
      currentUser = await fetchAuthenticated(get, `user/${currentUser._id}`);
      setFollowedEquipments(currentUser.followedEquipments || []);

      const { followedEquipmentsWithSoundAlarm } = currentUser;

      // console.log(followedEquipmentsWithSoundAlarm);

      // if(followedEquipmentsWithSoundAlarm.length > 0 && followedEquipmentsWithSoundAlarm.some((eq) => eq.handlingStatus === 'emergency')) {
      if (followedEquipmentsWithSoundAlarm.length > 0 && followedEquipmentsWithSoundAlarm.some((eq) => eq.currentHandlingStatus === 'emergency')) {
        const audio = new Audio('https://xglobal-blob.vise.app.br:3010/downloads/alarm.mp3');
        // TODO: Ajustar o alerta sonoro

        if (!audioPlaying) {
          audio.play();
          setAudioPlaying(true);
          setTimeout(() => { audio.pause(); }, 2200);
        }

        id = setInterval(() => {
          if (audioPlaying) {
            audio.pause();
            setAudioPlaying(false);
          } else {
            audio.play();
            setAudioPlaying(true);
            setTimeout(() => { audio.pause(); setAudioPlaying(false); }, 2200);
          }
        }, 5000);
      }
    };
    fn();
    return () => clearInterval(id);
  }, [refreshPage]);

  useEffect(() => {
    if (!isEmergencyLoading && !isSuspectLoading) {
      setIsLoading(false);
    }
  }, [isEmergencyLoading, isSuspectLoading]);

  const onMenuItemClick = () => {
    setRefreshPage(!refreshPage);
  };

  const rowActions = getEquipmentRowActions(onMenuItemClick);
  const columns = [
    {
      label: 'Imei',
      value: row => row.imei.substring(row.imei.length, row.imei.length - 8),
    },
    { label: 'Local', value: row => row.lastEvent && row.lastEvent.local },
    {
      label: 'Status',
      component: (row, col) => (
        <Permission
          key={row._id}
          name="EditEquipmentStatus"
          ifNoPermission={(
            <TableColumn>
              {/* <StatusColumn edit={false} status={row.handlingStatus} /> */}
              <StatusColumn edit={false} status={row.currentHandlingStatus} />
            </TableColumn>
)}
        >
          <TableMenu
            row={row}
            rowActions={rowActions}
            // icon={<StatusColumn status={row.handlingStatus} />}
            icon={<StatusColumn status={row.currentHandlingStatus} />}
          />
        </Permission>
      ),
    },
  ];

  return (
    <Grid className={classes.container} container>
      <XGlobalLoading isLoading={isLoading} />
      <Grid className={classes.dashboardContainer} item xs={9}>
        <MonitoringDashboard classes={classes} refresh={refreshPage} />
        <Grid container style={{ marginTop: 31 }}>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography className={classes.dashboardHeaderText}>
              Equipamentos em suspeita
            </Typography>
            <SimpleTable
              containerStyle={{ paddingRight: 24, paddingLeft: 0 }}
              query={querySuspect}
              setQuery={setQuerySuspect}
              columns={columns}
              data={suspectEquipments}
              path={path}
              isLoading={isSuspectLoading}
              onRowClick={(row, col) => {
                props.history.push(`/detalhes-equipamento/${row._id}`, {
                  from: location,
                });
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography className={classes.dashboardHeaderText}>
              Equipamentos em emergência
            </Typography>
            <SimpleTable
              containerStyle={{
                paddingLeft: 0,
                paddingRight: 0,
                minHeight: 200,
              }}
              columns={[
                ...columns,
                {
                  label: 'Emergência',
                  value: row => getEmergencyLabel(row),
                },
              ]}
              query={queryEmergency}
              setQuery={setQueryEmergency}
              data={emergencyEquipments}
              isLoading={isEmergencyLoading}
              path={path}
              onRowClick={(row, col) => {
                props.history.push(`/detalhes-equipamento/${row._id}`, {
                  from: location,
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.rootNotificationBar}>
        <NotificationBar
          refresh={refreshPage}
          history={props.history}
          followingList={followedEquipments}
          classes={classes}
        />
      </Grid>
    </Grid>
  );
}

export default withRouter(withStyles(styles)(Dashboard));
