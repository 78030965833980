import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  FormControl,
} from '@material-ui/core';
import Form, { FormSection } from "components/forms/Form";
import { post } from 'services/fetch';
import { useFields } from "hooks/fields";
import { emitEvent } from 'utils/events';
import { getItem, setItem } from 'services/local-storage';
import { useFetch, useAuthenticated } from 'hooks/fetch';
import CollapseCheckboxList from 'components/fields/CollapseCheckboxList';
import { withStyles } from '@material-ui/core';
import { MyCheckbox as Checkbox } from 'components/lists/CheckboxList';

import { ButtonSubmit, ButtonRemove, ButtonCancel } from 'components/buttons/ButtonsForm';

import {
  TextField,
} from 'components/fields';

const AccessProfileForm = (props) => {
  const { classes } = props;
  const [ isLoading, setIsLoading ] = useState({
    form: false,
  });
  const [ isDisabled, setDisabled ] = useState({form: false});
  const [ submitForm, setSubmitForm ] = useState(false);

  const [ permissionGroup, setPermissionGroup ] = useState([]);

  const { data: permissions } = useAuthenticated(useFetch, 'authorization/permissions', {
    beforeFetch: () => { emitEvent('showGlobalLinearProgress') },
    afterFetch:  () => { emitEvent('hideGlobalLinearProgress'); }
  });

  const [isAdmin, setIsAdmin] = useState(false);
  const [defaultForAutomaticUserCreation, setDefaultForAutomaticUserCreation] = useState(false);
  const [enableSignaturePasswordOption, setEnableSignaturePasswordOption] = useState(false);

  // after permissions and accessProfile are loaded
  // mark as checked accessProfile associated permissions
  useEffect(() => {
    if(permissions) {
      permissions.forEach((perm) => {
        perm.label = perm._id;
        let anyChecked = false;
        perm.items.forEach((item) => {
          if(props.initialData) {
            item.checked = props.initialData.permissions.some((p) => {
              return p._id == item._id;
            });
          } else {
            item.checked = false;
          }
          anyChecked = item.checked || anyChecked;
        });
        perm.checked = anyChecked;
      })
      setPermissionGroup(permissions);
      setIsAdmin(props.initialData && props.initialData.isAdmin || false);
      setDefaultForAutomaticUserCreation(props.initialData && props.initialData.defaultForAutomaticUserCreation|| false);
      setEnableSignaturePasswordOption(props.initialData && props.initialData.enableSignaturePasswordOption|| false);
    }
  }, [permissions, props.initialData]);


  // Declare form fields
  const fields = useFields([
    'name',
  ]);

  /**======================
   * LOAD INITIAL FORM DATA
   * ====================== */
  useEffect(() => {
    if(props.initialData) {
      setIsLoading({...isLoading, form: true});
      setDisabled({form: true});
      for (const [key, value] of Object.entries(props.initialData)) {
        if (fields[key] && fields[key].current) {
          if (fields[key].current.setFieldValue) {
            fields[key].current.setFieldValue(value);
      } } }
      setIsLoading({...isLoading, form: false});
      setDisabled({form: false});
    }
 }, [props.initialData])

  /**================
   * FORM SUBMISSION
   * ================ */

  // Validate all fields when form is submited
  const validateForm = async (event) => {
    event.preventDefault();
    const nonValidatedFields = Object.values(fields).filter(f => !f.current.alreadyValidated);
    nonValidatedFields.map(f => f.current.validateField());
    setSubmitForm(true);
    console.log('Validating');
  }

  // Handle form submission
  useEffect(() => { if (submitForm) { (async () => { await handleSubmit() })(); } }, [submitForm]);
  const handleSubmit = async () => {

    // Check for invalid fields
    const invalidFields = Object.values(fields).filter(f => f.current.isInvalid);
    if (invalidFields.length > 0) {
      emitEvent('showSnack', {message: 'Erro ao validar formulário', type: 'error'});
      setSubmitForm(false)
    }

    // All fields are valid
    // Commit changes to the server
    else {
      setDisabled({...isDisabled, form: true});
      setIsLoading({...isLoading, form: true});

      // Get all fields
      const data = {}
      data.isAdmin = isAdmin;
      data.defaultForAutomaticUserCreation= defaultForAutomaticUserCreation;
      data.enableSignaturePasswordOption= enableSignaturePasswordOption;
      for (const [key, value] of Object.entries(fields)) { data[key] = value.current.raw; }

      console.log(data);

      const _permissions = permissions.reduce((first, perm) => {
        const checkedPerms = [];
        perm.items.forEach(item => {
          if(item.checked) checkedPerms.push(item._id);
        });
        return [...first, ...checkedPerms];
      }, []);

      data.permissions = _permissions;

      // Send to server
      try {
        await props.handleSubmit(data);
        setDisabled({...isDisabled, form: false});
        setIsLoading({...isLoading, form: false});
        setSubmitForm(false);
        props.reload(true);
      } catch (err) {
        emitEvent('showSnack', {message: 'Erro ao processar formulário', type: 'error'});
        setDisabled({...isDisabled, form: false});
        setIsLoading({...isLoading, form: false});
        setSubmitForm(false);
      }
    }
  };

  return (
    <Form title={props.title || 'Novo Perfil de Acesso'}>
      <form onSubmit={validateForm} noValidate>
        <FormSection divider={true} justify="flex-start">
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                required
                ref={fields.name}
                fullWidth
                className={classes.fullWidthField}
                label='Nome'
                name='name'
              />
            </Grid>
            <Grid style={{display: 'flex', alignItems: 'center'}} item xs={12} sm={6} md={4} lg={3}>
              <Checkbox
                value={isAdmin}
                setValue={setIsAdmin}
                header='Apenas disponível para usuários Administradores'
              />
            </Grid>
            <Grid style={{display: 'flex', alignItems: 'center'}} item xs={12} sm={6} md={4} lg={3}>
              <Checkbox
                value={defaultForAutomaticUserCreation}
                setValue={setDefaultForAutomaticUserCreation}
                header='Padrão para usuários cadastrados automaticamente'
              />
            </Grid>
            <Grid style={{display: 'flex', alignItems: 'center'}} item xs={12} sm={6} md={4} lg={3}>
              <Checkbox
                value={enableSignaturePasswordOption}
                setValue={setEnableSignaturePasswordOption}
                header='Habilitar opção de Assinatura Eletrônica'
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSection title={'Permissões'} divider={true}>
          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <CollapseCheckboxList data={permissionGroup} setData={setPermissionGroup}></CollapseCheckboxList>
            </Grid>
          </Grid>
        </FormSection>
        <Grid container justify="flex-end" spacing={1}>
          {props.handleCancel
            && (
            <Grid item xs={12} md="auto">
              <ButtonCancel
                onClick={props.handleCancel}
                disabledCondition={isDisabled.form}
              ></ButtonCancel>
            </Grid>
            )
          }

          {props.handleRemove
            && (
            <Grid item xs={12} md="auto">
              <ButtonRemove
                onClick={props.handleRemove}
                disabledCondition={isDisabled.form}
              >
              </ButtonRemove>
            </Grid>
            )
          }


          <Grid item xs={12} md={'auto'}>
            <ButtonSubmit disabledCondition={isDisabled.form}></ButtonSubmit>
          </Grid>
        </Grid>
      </form>
    </Form>
  )
};

const styles = theme => ({
  btnRoot: {
    minWidth: 125,
    minHeight: 45,
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    // Small screens
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  fullWidthField: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
    },
  },
});

export default withStyles(styles)(AccessProfileForm);
