/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { put } from 'services/fetch';
import { emitEvent } from "utils/events";
import ModalStatus from 'components/modals/ModalStatus';
import ModalConfirm from 'components/modals/ModalConfirm';
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { StatusBall } from 'components/table/columns/StatusColumn';
import { logAction } from 'utils/logs';

const STATUS_MAP = {
  emergency: 'emergência',
  suspect: 'suspeito',
  active: 'Estável'
};

const SITUATION_MAP = {
  'LOW_BATTERY': 'Bateria abaixo do esperado',
  'NO_POSITIONING': 'Tempo sem posicionar',
  'SCHEDULED': 'Aguardando ativação',
  'COMPLETED': 'Expirados',
  'NO_BATTERY': 'Sem bateria',
  'JAMMING_DETECTED': 'Em emergência',
  'STABLE': 'Estável'
}

export const getEquipmentRowActions = (callback) => [
  {
    label: 'Estável',
    labelComponent: (<StatusBall containerStyle={{ marginRight: 10 }} status="active" />),
    component: (props) => (
      <ModalStatus
        status="Estável"
        onSubmit={(equipment, data) => {
          updateStatus(equipment, { status: 'active', ignoreTime: data.ignoreTimeMoment, description: data.description }, callback);
        }}
        isVisible={true}
        equipment={props}
      />
    ),
  },
  {
    label: 'Em suspeita',
    labelComponent: (<StatusBall containerStyle={{ marginRight: 10 }} status="suspect" />),
    component: (props) => (
      <ModalStatus
        status="suspeito"
        onSubmit={(equipment, data) => {
          updateStatus(equipment, { status: 'suspect', ignoreTime: data.ignoreTimeMoment, description: data.description }, callback);
        }}
        isVisible={true}
        equipment={props}
      />
      // <StatusModalConfirm  content='Ao alterar o status para "Em suspeita" os usuários pré-definidos serão notificados.' onConfirm={() => { updateStatus(equipment, { status: 'suspect' }, callback); }}/>
    ),
  },
  {
    label: 'Em emergência',
    labelComponent: (<StatusBall containerStyle={{ marginRight: 10 }} status="emergency" />),
    component: (props) => (
      <ModalStatus
        status="emergência"
        onSubmit={(equipment, data) => {
          updateStatus(equipment, { status: 'emergency', ignoreTime: data.ignoreTimeMoment, description: data.description }, callback);
        }}
        isVisible={true}
        equipment={props}
      />
      // <StatusModalConfirm  content='Ao alterar o status para "Em emergência" os usuários pré-definidos serão notificados.' onConfirm={() => { updateStatus(equipment, { status: 'emergency' }, callback); }}/>
    )
  },
];

const updateStatus = async (equipment, data, callback) => {

  console.log(equipment.situation)
  console.log(data)

  const path = 'equipment/update-status/';
  try {
    await put(path + equipment._id, data);
    if (callback) { callback(); }
    // let lastSituation = SITUATION_MAP?.[equipment.situation]
    let lastSituation = equipment.situation in SITUATION_MAP ? SITUATION_MAP[equipment.situation] : '-'
    logAction(`Alterou o status do equipamento ${equipment.shortImei} De: ${lastSituation} para ${STATUS_MAP[data.status]}`, equipment.imei);
    emitEvent('showSnack', { message: 'Status alterado.', type: 'success' });
  } catch (e) {
    emitEvent('showSnack', { message: 'Erro ao tentar alterar o status', type: 'error' });
  }
};

/* const updateDashboardCard = async (equipment, data, callback) => {
  const path = 'equipment/update-dashboard-card/';
  try {
    await put(path + equipment._id, data);
    if (callback) { callback(); }
    logAction(`Alterou card do equipamento ${equipment.shortImei} para ${STATUS_MAP[data.dashboardCard]}`);
    emitEvent('showSnack', { message: 'Card alterado.', type: 'success' });
  } catch (e) {
    emitEvent('showSnack', { message: 'Erro ao tentar alterar o status', type: 'error' });
  }
}; */

const StatusModalConfirm = ({ content, onConfirm }) => {
  const [openModal, setOpenModal] = useState(true);

  const useStyles = makeStyles(theme => ({
    text: {
      fontSize: 16,
      color: theme.palette.gray.gray_4,
      maxWidth: 800,
    }
  }));
  const classes = useStyles();

  const _onConfirm = () => {
    setOpenModal(false);
    onConfirm();
  };

  return (
    <ModalConfirm
      isOpen={openModal}
      setIsOpen={setOpenModal}
      onConfirm={_onConfirm}
      title="Tem certeza que deseja alterar o status?"
    >
      <div>
        <Typography className={classes.text}>
          {content}
        </Typography>
      </div>
    </ModalConfirm>
  );
};
