import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';

import ModalRemove from 'components/modals/ModalRemove';
import EquipmentPackingForm from 'components/forms/EquipmentPackingForm';
import { buildLogString } from "utils/helpers";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

export default function EditEquipmentPackings(props) {
  const classes = useStyles();
  const { match, history, setBreadcrumbs } = props;
  const [equipmentPackings, setEquipmentPackings] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Use base equipmentPackings url
  console.log(match);
  const equipmentPackingsURL = `equipment-packing/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/embalagens', label: 'Embalagens' },
      { path: '', label: `${equipmentPackings && equipmentPackings.name || ''}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, equipmentPackings]);

  // Load equipmentPackings info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const response = await fetchAuthenticated('get', equipmentPackingsURL);

      // Any error will redirect to equipmentPackingss list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/empresas');
      } else {
        const data = await response.json();

        // Update protocol version to expected format

        setEquipmentPackings(data);
        logAction(`Acesso ao cadastro da embalagem ${data.name}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, equipmentPackingsURL]);

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', equipmentPackingsURL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      setEquipmentPackings(data);

      const translateKeys = {
        'name': 'Nome'
      }

      const updatedItemsStr = buildLogString(equipmentPackings, data, translateKeys, [])

      logAction(`Edição da embalagem ${data.name}${updatedItemsStr}`);
      emitEvent('showSnack', { message: 'Embalagem atualizada com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = () => {
    history.push('/cadastros/embalagens');
  }

  // Handle remove button click
  const handleRemove = () => {
    console.log('Handle remove');
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  // Handle remove confirmation
  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', equipmentPackingsURL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover a embalagem', type: 'error' });
      } else {
        logAction(`Remoção da embalagem ${equipmentPackings.name}`)
        emitEvent('showSnack', { message: 'Embalagem removida com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/embalagens');
      }
    }
  }

  return (
    <div>
      <EquipmentPackingForm
        title='Detalhes da Embalagem'
        data={equipmentPackings}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
      />
      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='da embalagem'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='a embalagem'
        extraContent='Ao realizar está ação a embalagem não estará mais disponível'
      />
    </div>
  );
}
