import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';

import ModalRemove from 'components/modals/ModalRemove';
import EquipmentColorForm from 'components/forms/EquipmentColorForm';
import { buildLogString } from "utils/helpers";


const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

export default function EditEquipmentColors(props) {
  const classes = useStyles();
  const { match, history, setBreadcrumbs } = props;
  const [equipmentColors, setEquipmentColors] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  // Use base equipmentColors url
  console.log(match);
  const equipmentColorsURL = `equipment-color/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/cores', label: 'Cores' },
      { path: '', label: `${equipmentColors && equipmentColors.name || ''}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, equipmentColors]);

  // Load equipmentColors info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const response = await fetchAuthenticated('get', equipmentColorsURL);

      // Any error will redirect to equipmentColorss list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/empresas');
      } else {
        const data = await response.json();

        // Update protocol version to expected format

        setEquipmentColors(data);
        logAction(`Acesso ao cadastro da cor ${data.name}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, equipmentColorsURL]);

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', equipmentColorsURL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      setEquipmentColors(data);

      const translateKeys = {
        'name': 'Nome'
      }

      const updatedItemsStr = buildLogString(equipmentColors, data, translateKeys, [])

      logAction(`Edição da cor ${data.name}${updatedItemsStr}`);
      emitEvent('showSnack', { message: 'Cor atualizada com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = () => {
    history.push('/cadastros/cores');
  }

  // Handle remove button click
  const handleRemove = () => {
    console.log('Handle remove');
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  // Handle remove confirmation
  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', equipmentColorsURL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover a cor', type: 'error' });
      } else {
        logAction(`Remoção da cor ${equipmentColors.name}`);
        emitEvent('showSnack', { message: 'Cor removida com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/cores');
      }
    }
  }

  return (
    <div>
      <EquipmentColorForm
        title='Detalhes da Cor'
        data={equipmentColors}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='da cor'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='a cor'
        extraContent='Ao realizar está ação a cor não estará mais disponível'
      />

    </div>
  );
}
