import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { MyCheckbox as Checkbox } from 'components/lists/CheckboxList';
import { AutocompleteField, RadioGroupField } from '../fields';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 'solid 1px #e3e4e8',
    marginTop: 15,
    flex: 1,
    paddingBottom: 20,
  },
  labelStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_5,
  },
  labelStatusIsOn: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.gray.gray_5,
    marginTop: 5,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  labelIsOn: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.gray.gray_6,
    marginTop: 5,
  },
}));

const CommandToggle = (props) => {
  const classes = useStyles();
  const { data, status, setStatus, isEnabled, toggleEnabled, temperatureRange, setTemperatureRange, temperatureRangeType, setTemperatureRangeType, equipmentData, setEquipmentData } = props;

  const [firstPageLoad, setFirstPageLoad] = useState(true);
  const [currentImmutableTemperatureRangeEnabled, setCurrentImmutableTemperatureRangeEnabled] = useState(false);
  const [currentImmutableTemperatureRange, setCurrentImmutableTemperatureRange] = useState(false);
  const [currentTemperatureRangeEnabled, setCurrentTemperatureRangeEnabled] = useState("Não");
  const [currentTemperatureRange, setCurrentTemperatureRange] = useState(false);

  // const [temperatureRangeEnabled, setTemperatureRangeEnabled] = useState('Não');

  // const [ checked, setChecked ] = useState(status[data.name] && status[data.name] || false);
  let { containerProps, currentStatus, showStatus, showTemperatureAlarmOptions, temperatureAlarmOptionsAvailable } = props;

  const handleTemperatureRangeChange = (event, value) => {
    let _temperatureRange = {};
    _temperatureRange['min'] = value[0]
    _temperatureRange['max'] = value[1]

    setEquipmentData({...equipmentData, temperatureRange: _temperatureRange})
    setTemperatureRange(value)
  }

  const handleTemperatureRangeTypeChange = (event) => {
    const type = parseInt(event.target.value)
    let _temperatureRangeType;
    _temperatureRangeType = type

    setEquipmentData({...equipmentData, temperatureRangeType: _temperatureRangeType})
    setTemperatureRangeType(type)
  }

  if (!data.isOnLabel) {
    data.isOnLabel = 'Ligado';
  }
  if (!data.isOffLabel) {
    data.isOffLabel = 'Desligado';
  }
  if (!containerProps) {
    containerProps = {};
  }

  useEffect(() => {
    if(equipmentData !== undefined && JSON.stringify(equipmentData) !== '{}') {
      setCurrentTemperatureRangeEnabled(equipmentData.temperatureRangeEnabled)
      setCurrentTemperatureRange(equipmentData.temperatureRange)

      if(firstPageLoad) {
        let __isEnabled = equipmentData.temperatureRangeEnabled === 'Sim' ? 'Ligado' : 'Desligado'
        setCurrentImmutableTemperatureRangeEnabled(__isEnabled)
        setCurrentImmutableTemperatureRange(equipmentData.temperatureRange)
        setFirstPageLoad(false)
      }
    }
  }, [equipmentData])

  // useEffect(() => {
  // setStatus({...status, [data.name] : checked});
  // }, [checked]);

  return (
    <div className={classes.container} style={{ opacity: !isEnabled ? 0.4 : 1 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {toggleEnabled && (
          <div>
            <Checkbox setValue={toggleEnabled} checked={isEnabled} value={isEnabled} />
          </div>
        )}
        <div style={{ width: 240 }}>
          <div className={classes.label}>{data.label}</div>
          <div className={classes.labelIsOn}>
            {/* {
              () => {
                if(equipmentData !== undefined) {
                  return currentTemperatureRangeEnabled === 'Sim' ? 'Ligado' : 'Desligado'
                } else {
                  return (currentStatus && data.isOnLabel) || data.isOffLabel
                }
              }
            } */}
            {/* {currentTemperatureRangeEnabled === 'Sim' ? 'Ligado' : (status && data.isOnLabel) || data.isOffLabel} */}
            {(status[data.name] && data.isOnLabel) || data.isOffLabel}
          </div>
        </div>
        {showStatus && (
          <div style={{ width: 200 }}>
            <div className={classes.labelStatus}>Status atual</div>
            <div className={classes.labelStatusIsOn}>
              {/* {} */}
              {equipmentData !== undefined && equipmentData.temperatureRangeEnabled !== undefined ? currentImmutableTemperatureRangeEnabled : (currentStatus && data.isOnLabel) || data.isOffLabel}
              {/* {currentTemperatureRangeEnabled === 'Sim' ? 'Ligado' : (currentStatus && data.isOnLabel) || data.isOffLabel} */}
              {/* {showTemperatureAlarmOptions ? currentTemperatureRangeEnabled === 'Sim' ? 'Ligado' : 'Desligado' : (currentStatus && data.isOnLabel) || data.isOffLabel} */}
              {/* {equipmentData !== undefined ? (status[data.name] && data.isOnLabel) || data.isOffLabel : (currentStatus && data.isOnLabel) || data.isOffLabel} */}
            </div>
          </div>
        )}
        {temperatureAlarmOptionsAvailable &&(currentTemperatureRangeEnabled === 'Sim' ? true : ((status[data.name] !== undefined && status[data.name]) || false)) && <div style={{ marginLeft: '10px', marginTop: '22px', width: 300 }}>
          <RadioGroupField
            // innerRef={fields.temperatureRangeEnabled}
            value={temperatureRangeType}
            default={temperatureRangeType}
            row
            required
            title="Tipo de Temperatura"
            options={[
              { value: '1', label: 'Baú' },
              { value: '2', label: 'Temperatura' },
            ]}
            onChange={handleTemperatureRangeTypeChange}
            labelProps={{
              style: {
                transform: 'translate(0, 1.5px) scale(0.75)',
                transformOrigin: 'top left',
              },
            }}
            formControlLabelProps={{
              labelPlacement: 'start',
            }}
          />
        </div>}
        {temperatureAlarmOptionsAvailable && (currentTemperatureRangeEnabled === 'Sim' ? true : ((status[data.name] !== undefined && status[data.name]) || false)) && <div style={{ width: 200, marginTop: '22px' }}>
          <Slider
            name="temperatureRange"
            // innerRef={fields.temperatureRange}
            value={temperatureRange}
            min={-40}
            max={90}
            step={2}
            valueLabelFormat={(value) => `${value}ºC`}
            marks={[{value: -40, label: '-40ºC'}, {value: 90, label: '90ºC'}]}
            onChange={handleTemperatureRangeChange}
            valueLabelDisplay="on"
            getAriaValueText={(value) => value}
          />
        </div>}
      </div>
      <Switch
        checked={currentTemperatureRangeEnabled === 'Sim' ? true : (status[data.name] !== undefined && status[data.name]) || false}
        onChange={e => {
          if(data.name !== 'temperatureAlarmMode') {
            setStatus({ ...status, [data.name]: e.target.checked })
          } else {
            setStatus({ ...status, [data.name]: e.target.checked })

            const _temperatureRangeEnabled = e.target.checked ? 'Sim' : 'Não'
            // setTemperatureRangeEnabled(_temperatureRangeEnabled)
            let _temperatureRange;
            let _temperatureRangeType;
            if(e.target.checked) {
              _temperatureRange = {
                min: temperatureRange[0],
                max: temperatureRange[1]
              }
              _temperatureRangeType = parseInt(temperatureRangeType)
            } else {
              _temperatureRange = null
              _temperatureRangeType = null
            }

            setEquipmentData({...equipmentData, temperatureRangeEnabled: _temperatureRangeEnabled, temperatureRangeType: _temperatureRangeType, temperatureRange: _temperatureRange})
          }
        }}
        // onChange={e => setStatus({ ...status, [data.name]: e.target.checked })}
        value={status[data.name]}
        color="primary"
        disabled={!isEnabled}
      />
    </div>
  );
};
CommandToggle.defaultProps = {
  isEnabled: true,
  toggleEnabled: null,
};
export default CommandToggle;

export const CommandSimpleToggle = (props) => {
  const classes = useStyles();
  const {
    data, state, setState, isEnabled, toggleEnabled,
  } = props;
  let { containerProps, currentStatus, showStatus } = props;

  if (!data.isOnLabel) {
    data.isOnLabel = 'Ligado';
  }
  if (!data.isOffLabel) {
    data.isOffLabel = 'Desligado';
  }
  if (!containerProps) {
    containerProps = {};
  }

  return (
    <div
      className={classes.container}
      {...containerProps}
      style={{ ...(containerProps.style || {}), opacity: !isEnabled ? 0.4 : 1 }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {toggleEnabled && (
          <div>
            <Checkbox setValue={toggleEnabled} checked={isEnabled} value={isEnabled} />
          </div>
        )}
        <div style={{ width: 253 }}>
          <div className={classes.label}>{data.label}</div>
          <div className={classes.labelIsOn}>{(state && data.isOnLabel) || data.isOffLabel}</div>
        </div>
        {showStatus && (
          <div>
            <div className={classes.labelStatus}>Status atual</div>
            <div className={classes.labelStatusIsOn}>
              {(currentStatus && data.isOnLabel) || data.isOffLabel}
            </div>
          </div>
        )}
      </div>
      <Switch
        checked={state}
        onChange={e => setState(e.target.checked)}
        value={state}
        color="primary"
        disabled={!isEnabled}
      />
    </div>
  );
};

CommandSimpleToggle.defaultProps = {
  isEnabled: true,
  toggleEnabled: null,
};
