import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from "react-router-dom";
import ListFence from './List';
import NewFence from './New';
import EditFence from './Edit';

import ListReferencePoint from '../ReferencePoints/List';
import NewReferencePoint from '../ReferencePoints/New';
import EditReferencePoint from '../ReferencePoints/Edit';
import SubRoutes from "components/SubRoutes";

import  Typography  from "@material-ui/core/Typography";

import SimpleFilterProvider from 'components/filter/SimpleFilterProvider';

function Cercas(props) {
  const { classes, match } = props;
  const [ breadcrumbs, setBreadcrumbs ] = useState([]);
  const pages = [
    {
      name: "Cercas",
      path: '/nova',
      component: NewFence,
    },
    {
      name: "Cercas",
      path: '/pontosdereferencia/novo',
      component: NewReferencePoint,
    },
    {
      name: "Cercas",
      path: '/pontosdereferencia/:id',
      component: EditReferencePoint,
    },
    {
      name: "Cercas",
      path: '/pontosdereferencia',
      component: ListReferencePoint,
    },
    {
      name: "Cercas",
      path: '/:id',
      component: EditFence,
    },
    {
      name: "Cercas",
      path: '',
      component: ListFence,
    },
  ];

  return (
    // <div className={classes.root}>
      // {/* Para definir as rotas precisamos importar o compoenente SubRoutes */}
    <SimpleFilterProvider>
      <SubRoutes
        setBreadcrumbs={setBreadcrumbs}
        defaultPage={0}
        pages={pages}
        match={match}
      />
    </SimpleFilterProvider>
    // </div>
  );
};

const styles = theme => {/** */};
export default withStyles(styles)(Cercas);
