import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
} from '@material-ui/core';
import { put, get, fetchAuthenticated } from 'services/fetch';
import Modal from 'components/modals/Modal';
import { getUser } from 'services/user';
import PositionIcon from 'assets/icon/dashboard/position.png';
import BatteryIcon from 'assets/icon/dashboard/battery.png';
import NoPositionIcon from 'assets/icon/dashboard/no_position.png';
import { emitEvent } from "utils/events";

import { MaskedPercentage, MaskedHour, MaskedTrying } from 'components/inputs/masked.input';
import { getUserCompany } from 'services/user';
import ModalRequirePermission from "components/modals/ModalRequirePermission";
import { useEffectSkipFirst } from 'hooks/common';
import { buildLogString } from "utils/helpers";
import { logAction } from "utils/logs";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    width: '100%',
  },
  configItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 23,
    borderBottom: theme.input.borderStyle.borderBottom,
    marginBottom: 29,
  },
  configItemIcon: {
    width: 32,
    height: 32,
    marginRight: 20,
  },
  configItemLabel: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
  },
  leftHalf: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightHalf: {
    flex: 1,
    display: 'flex',
  },
}));

const ConfigItem = ({
  onChange, icon, label, inputLabel, InputComponent, defaultValue, name
}) => {
  const classes = useStyles();
  return (
    <div className={classes.configItemContainer}>
      <div className={classes.leftHalf}>
        <img src={icon} className={classes.configItemIcon}></img>
        <Typography className={classes.configItemLabel}> {label} </Typography>
      </div>
      <div className={classes.rightHalf}>
        <ConfigItemInput
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          label={inputLabel}
          InputComponent={InputComponent}
        />
      </div>
    </div>
  )
}

const ConfigItemInput = ({ label, name, defaultValue, onChange, InputComponent }) => {
  const useStyles = makeStyles(theme => ({
    textField: {
      minHeight: 'unset',
      marginRight: 10,
      width: 200,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%'
      }
    },
    input: {
      borderBottom: theme.input.borderStyle.borderBottom,
      paddingBottom: 8,
    },
    label: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.gray.gray_4,
    }
  }));

  const classes = useStyles();

  const InputProps = {
    classes: {
      root: classes.input,
    },
    disableUnderline: true,
    inputComponent: InputComponent
  }

  const [value, setValue] = useState(defaultValue);

  const onTextChange = (v) => {
    setValue(v);
    onChange(v);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography className={classes.label}>{label}</Typography>
      <TextField
        className={classes.textField}
        name={name}
        value={value}
        onChange={(e) => onTextChange(e.target.value)}
        InputProps={InputProps}
      />
    </div>
  )
}


const ModalDashboardSettings = ({ isVisible, setIsVisible }) => {
  const classes = useStyles();

  const [config, setConfig] = useState({
    batteryBelowPercentage: '20',
    noPositioningTime: '2',
    delayedPositions: '3',
  });

  const [modalRequirePermissionIsVisible, setModalRequirePermissionIsVisible] = useState(false);
  const [signaturePasswordValidated,  setSignaturePasswordValidated] = useState(null);

  useEffectSkipFirst(async () => {

    if(signaturePasswordValidated === true) {

      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(false);

      const company = await getUserCompany();

      const parseToInt = (value) => {
        if (typeof value === 'string') {
          return parseInt(value.match(/\d/g).join(""), 10);
        }
        return value;
      }

      const response = await put('company/' + company._id, {
        dashboardSettings: {
          batteryBelowPercentage: parseToInt(config.batteryBelowPercentage),
          noPositioningTime: parseToInt(config.noPositioningTime),
          delayedPositions: parseToInt(config.delayedPositions),
        },
      });

      emitEvent('showSnack', {message: 'Alterações realizadas com sucesso!', type: 'success'});
    } else if(signaturePasswordValidated === false) {
      emitEvent('showSnack', {message: 'Assinatura Eletrônica Inválida!', type: 'error'});
    }

  }, [signaturePasswordValidated])

  useEffect(() => {
    // get current company
    const fetch = async () => {
      const company = await getUserCompany();
      const data = await fetchAuthenticated(get, 'company/' + company._id);
      if (data.dashboardSettings) {
        setConfig(data.dashboardSettings);
      }
    }
    fetch();
  }, []);



  const onChange = (newValue, fieldName) => {
    setConfig({ ...config, [fieldName]: newValue });
  }

  const handleSubmit = async () => {

    const userId = JSON.parse(localStorage.getItem('user'))._id
    const userRequest = await fetchAuthenticated('get', `user/${userId}`)
    let user = {}

    await userRequest.json().then(userResponse => {
      user = userResponse
    })

    if(user && user.signaturePasswordIsEnabled && user.profiles.some(p => p.enableSignaturePasswordOption)) {
      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(true);
    } else {
      const company = await getUserCompany();

      const parseToInt = (value) => {
        if (typeof value === 'string') {
          return parseInt(value.match(/\d/g).join(""), 10);
        }
        return value;
      }

      const response = await put('company/' + company._id, {
        dashboardSettings: {
          batteryBelowPercentage: parseToInt(config.batteryBelowPercentage),
          noPositioningTime: parseToInt(config.noPositioningTime),
          delayedPositions: parseToInt(config.delayedPositions),
        },
      });

      const translateKeys = {
        batteryBelowPercentage: 'Bateria abaixo do esperado',
        noPositioningTime: 'Tempo sem posicionar'
      }

      const oldData = {
        ...JSON.parse(localStorage.getItem('user'))['company']['dashboardSettings']
      }

      oldData['batteryBelowPercentage'] = oldData['batteryBelowPercentage'] + '%'
      oldData['noPositioningTime'] = oldData['noPositioningTime'] + ' horas'

      const newData = {
        batteryBelowPercentage: parseToInt(config.batteryBelowPercentage) + '%',
        noPositioningTime: parseToInt(config.noPositioningTime) + ' horas',
        delayedPositions: parseToInt(config.delayedPositions)
      }

      const updateItemsStr = buildLogString(oldData, newData, translateKeys, ['delayedPositions'])

      logAction(`Edição das configurações do dashboard${updateItemsStr}`)

      const localStorageUser = JSON.parse(localStorage.getItem('user'))

      localStorageUser['company']['dashboardSettings'] = {
        batteryBelowPercentage: parseToInt(config.batteryBelowPercentage),
        noPositioningTime: parseToInt(config.noPositioningTime),
        delayedPositions: parseToInt(config.delayedPositions),
      }

      localStorage.setItem('user', JSON.stringify(localStorageUser))

      emitEvent('showSnack', {message: 'Alterações realizadas com sucesso!', type: 'success'});
    }
  }

  return (
    <Modal
      handleSubmit={handleSubmit}
      onSuccessMessage="As configurações foram salvas."
      isVisible={isVisible} setIsVisible={setIsVisible} title="Configurações de monitoramento" buttonLabel="Salvar"
    >
      <div className={classes.root}>
        <ConfigItem
          onChange={(v) => onChange(v, 'batteryBelowPercentage')}
          icon={BatteryIcon}
          label='Bateria abaixo do esperado'
          name='batteryBelowPercentage'
          defaultValue={config.batteryBelowPercentage}
          inputLabel='Nível de bateria'
          InputComponent={MaskedPercentage}
        />
        <ConfigItem
          onChange={(v) => onChange(v, 'noPositioningTime')}
          icon={PositionIcon}
          label='Tempo sem posicionar'
          name='noPositioningTime'
          defaultValue={config.noPositioningTime}
          inputLabel='Tempo'
          InputComponent={MaskedHour}
        />
        {/* <ConfigItem
          onChange={(v) => onChange(v , 'delayedPositions')}
          icon={NoPositionIcon}
          label='Posições atrasadas'
          name='delayedPositions'
          defaultValue={config.delayedPositions}
          inputLabel='Tentativas de posição'
          InputComponent={MaskedTrying}
        /> */}
      </div>
    </Modal>
  )
}




export default ModalDashboardSettings;
