import React, { useEffect } from 'react';

import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';

import RfImportForm from 'components/forms/NewRfImportForm';
import { logAction } from 'utils/logs';

export default function NewRf(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/rf', label: 'Importação RF' },
      { path: '', label: 'Nova Importação' }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs])

  // Handle form submit
  const handleSubmit = async (data) => {

    if(data.description === '') {
      return
    }

    if(JSON.stringify(data.file) === '{}') {
      return
    }

    const response = await fetchAuthenticated('post', 'rf', data);

    if (!response.ok) {
      throw new Error('');
    } else {
      const data = await response.json();
      emitEvent('showSnack', { message: 'Importação de RF criada com sucesso', type: 'success' });
      // logAction(`Criação do equipamento ${data.shortImei}`, data.imei)
      history.push(`/cadastros/rf/${data._id}`);
    }
  }

  // Handle cancel click
  const handleCancel = async () => {
    history.push('/cadastros/rf');
  }



  return (
    <div>
      <RfImportForm
        title='Nova importação'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
