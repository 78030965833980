import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { fetchAuthenticated, postWithErrorHandler, post } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';
import { buildLogString } from "utils/helpers";

// import NewRfImportForm from 'components/forms/NewRfImportForm';
import NewErbForm from 'components/forms/NewErbForm';
import ModalRemove from 'components/modals/ModalRemove';
import ModalConfirm from "components/modals/ModalConfirm";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

export default function EditErb(props) {
  const classes = useStyles();
  const { match, history, setBreadcrumbs } = props;
  const [rfImport, setRfImport] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const [showEnableDisableDialog, setShowEnableDisableDialog,] = useState(false);

  // Use base equipment url
  const URL = `erb/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    console.log(rfImport)
    setBreadcrumbs([
      { path: '/cadastros/erb', label: 'Cadastro ERB' },
      { path: '', label: `${rfImport.lat} / ${rfImport.long}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, rfImport]);

  // Load equipment info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const response = await fetchAuthenticated('get', URL);

      // Any error will redirect to equipments list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/rf');
      } else {
        const data = await response.json();

        setRfImport(data);
        // logAction(`Acesso ao cadastro do equipamento ${data.shortImei}`, data.imei)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, URL]);

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', URL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      setRfImport(data);
      // logAction(`Edição da importação de RF ${data.shortImei}${updatedItemsStr}`, __equipment.imei);
      emitEvent('showSnack', { message: 'Registro atualizado com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = () => {
    history.push('/cadastros/erb');
  }

  // Handle remove button click
  const handleRemove = () => {
    console.log('Handle remove');
    setShowDialog(true);
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  // Handle remove confirmation
  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', URL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover o registro', type: 'error' });
      } else {
        // logAction(`Remoção do equipamento ${equipment.shortImei}`, equipment.imei);
        emitEvent('showSnack', { message: 'Registro removida com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/erb');
      }
    }
  }

  const handleEnableDisable = () => {
    setShowEnableDisableDialog(true);
  }
  const confirmEnableDisable = async () => {
    // const method = equipment.isActive ? 'deactivate'  : 'activate';
    // const path = `${URL}/${method}`;
    // try {
    //   const res = await fetchAuthenticated('put', path);
    //   const eqp = await res.json();
    //   setRfImport(eqp);
    //   setShowEnableDisableDialog(false);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <div>
      <NewErbForm
        title='Detalhes do Equipamento'
        data={rfImport}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
        handleEnableDisable={handleEnableDisable}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='do ERB'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='o ERB'
        extraContent=''
      />
    </div>
  );
}
