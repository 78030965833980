import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, IconButton, TableFooter, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
      maxHeight: 520, // Adjust this value based on the total height of 5 rows
    },
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
    },
    idField: {
      marginBottom: 100,
      marginTop: -10
    }
  }));

const ItemTableForm = (props) => {
  const {items, onAddRow, onRemoveRow, onChange} = props;
  console.log(items)
  const classes = useStyles();
  // const [items, setItems] = useState([{ id: 1, field1: '', field2: '', field3: '' }]);

  const handleAddRow = () => {
    // const newItem = { id: items.length + 1, field1: '', field2: '', field3: '' };
    // setItems([...items, newItem]);
    onAddRow()
  };

  const handleRemoveRow = (id) => {
    // setItems(items.filter(item => item.id !== id));
    onRemoveRow(id)
  };

  const handleChange = (id, field, value) => {
    // const newItems = items.map(item => {
    //   if (item.id === id) {
    //     return { ...item, [field]: value };
    //   }
    //   return item;
    // });
    // setItems(newItems);
    onChange(id, field, value)
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>IMEI*</TableCell>
            <TableCell>RF*</TableCell>
            <TableCell>Nº LORA</TableCell>
            <TableCell align="right">
              <IconButton onClick={handleAddRow}>
                <AddCircleOutlineIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
            <TableCell>
                {/* <TextField
                  value={row.id}
                  onChange={(e) => handleChange(row.id, 'imei', e.target.value)}
                  size="small"
                /> */}
                <span className={classes.idField}>{row.id}</span>
              </TableCell>
              <TableCell>
                <TextField
                  value={row.imei}
                  onChange={(e) => handleChange(row.id, 'imei', e.target.value)}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={row.rf}
                  onChange={(e) => handleChange(row.id, 'rf', e.target.value)}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={row.lora}
                  onChange={(e) => handleChange(row.id, 'lora', e.target.value)}
                  size="small"
                />
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => handleRemoveRow(row.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter className={classes.stickyFooter}>
          <TableRow>
            <TableCell colSpan={3}>Quantidade: {items.length}</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ItemTableForm;
