import React from 'react';
import BaseList from 'components/lists/BaseList';
import ActionList from 'components/lists/ActionList';
import { datetimeFormatter, datetimeFormatterUTC } from 'utils/formatters.js';
import Edit from '@material-ui/icons/Edit';
import TableHeaderAction from 'components/table/TableHeaderAction';
import { CheckboxColumn } from 'components/table/columns';
import ModalEquipmentEdit from 'components/modals/ModalEquipmentEdit';
import { useAccessLog } from 'hooks/logs';
import TableMenu, { TableColumn } from 'components/table/TableMenu';
import { StatusColumn } from 'components/table/columns';
import BadgeColumn from 'components/table/columns/BadgeColumn';

function ListBip(props) {
  useAccessLog('Acesso ao cadastro de BIPS');
  const { match } = props;
  return (
    <BaseList
      modals={{
        'Edit': {
          isVisible: false,
          needReloadData: true,
          component: (props) => (
            <ModalEquipmentEdit key='EditModal' {...props} />
          ),
        },
      }}

      columns={[
        {
          value: row => {
            if(row.date) {
              return datetimeFormatterUTC(row.date).split(' ')[0]
            } else {
              return '-'
            }
          },
          label: 'Data',
          sort: 'date'
        },
        { value: row => (row.company ? row.company.name : '-'), label: 'Empresa', sort: 'company.name' },
        { value: row => row.equipmentCount, label: 'QTD Equipamentos', sort: 'equipmentCount' },
        { value: row => row.orderNumber, label: 'Nº Pedido', sort: 'orderNumber' },
        { value: row => (row.user ? row.user : '-'), label: 'Usuário', sort: 'user' },
        // { value: row => row.statusId === 1 ? 'Em produção' : 'Finalizado', label: 'Status de Produção', sort: 'status' },
        // #538cc1 verde
        // #ffc500 amarelo
        { value: row => (
          // <TableColumn>
            <BadgeColumn
              status={row.statusId}
            />
          //</TableColumn>
        )
        , label: 'Status de Produção', sort: 'status' },
      ]}
      genericSearchFields={[
        { label: 'LAC', name: 'lac' },
        { label: 'CellID', name: 'cellid' },
        { label: 'lat', name: 'lat' },
        { label: 'long', name: 'long' },
      ]}
      basePath="bip"
      title="BIPS"
      addButtonText="Novo BIP"
      addUrl={`${match.path}/novo`}
    />
  );
}

export default ListBip;
