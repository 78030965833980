import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import 'moment/locale/pt-br';

import { makeStyles } from '@material-ui/core';
import { saveAs } from 'file-saver';
import theme from 'styles/theme';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import Filter from 'components/filter/Filter';
import DatePicker from 'components/pickers/DatePicker';
import FilterAutoComplete from 'components/filter/FilterAutoComplete';
// import ReportFilterContext from 'pages/Reports/ReportFilterContext';

import { useQuery } from 'hooks/fetch';
import { useAccessLog } from 'hooks/logs';
import { usePaginatedData } from 'hooks/common';
import { fetchAuthenticated } from 'services/fetch';
import { updateQueryFilter } from 'utils/query';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';
import { getYesterdayAndToday, getYesterdayAndTodayToDatePicker } from 'utils/helpers';

import { REPORT_COLUMNS } from './columns';
import DateTimePicker from 'components/pickers/DateTimePicker';

moment.locale('pt-br');

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
  filter: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const PATH = 'logs';
function LogsReport(props) {
  useAccessLog('Acesso ao relatórios de logs');
  const classes = useStyles();
  const [data, setData] = usePaginatedData();

  const [dataInitalDateFilled, setDataInitalDateFilled] = useState(false);
  const [dataFinalDateFilled, setDataFinalDateFilled] = useState(false);
  const [userFilled, setUserFilled] = useState(false);
  const [initialDate, setInitialDate] = useState(moment().subtract(1, 'days').startOf('day'));
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const [firstLoad, setFirstLoad] = useState(true);

  const [query, setQuery, isLoading] = useQuery(
    PATH,
    setData,
    ['description', 'user', 'ip', 'date', 'equipment'],
    {
      filter: {
        'isSystemLog': false,
        // '$expr': {
        //   '$and': [
        //     {['$gte']: ['$date', {'$dateFromString': {'dateString': initialDate.toISOString()}}]},
        //     {['$lte']: ['$date', {'$dateFromString': {'dateString': endDate.toISOString()}}]}
        //   ]
        // }
      }, sort: { date: -1 },
    },
    q => {
      if((!dataInitalDateFilled && !dataFinalDateFilled) || !userFilled) {
        return false;
      }
      return true;
      // return false;
    }
  );
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const exportSpreadsheet = async (query) => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', { message: 'Exportação limitada a 5000 itens', type: 'info' });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/xls${query.queryString}`,
    );
    logAction('Exportou relatório de logs em XLS')
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Logs.xls');
    setIsGeneratingReport(false);
  };

  const exportPdf = async (query) => {
    setIsGeneratingReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', { message: 'Exportação limitada a 5000 itens', type: 'info' });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/pdf${query.queryString}`,
    );
    logAction('Exportou relatório de logs em PDF')
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Logs.pdf');
    setIsGeneratingReport(false);
  };

  const onDateChange = async ({ date, filterHandler }, comparator) => {
    let _andClause = [];

    if (query && query.queryObject) {
      const _obj = { ...query.queryObject.filter };
      

      // Check if filter already exists and removes it
      if (_obj && _obj.$expr && _obj.$expr.$and) {
        _andClause = _obj.$expr.$and.filter(exp => !exp.hasOwnProperty(`${comparator}`));
      }
    }

    if (date) {
      _andClause.push({
        [`${comparator}`]: ['$date', { $dateFromString: { dateString: date } }],
      });
    }

    // It removes add an empty _andClause if no value is supplied and the
    // correct value otherwise
    filterHandler.updateQueryFilter({
      $expr: { $and: _andClause },
    });
  };

  const onCompanyChange = ({ opt, filterHandler, fields, isLoading, setIsLoading }) => {
    fields.users.current.resetField();
    fields.equipments.current.resetField();
    setIsLoading({ ...isLoading, users: true });
    setTimeout(() => {
      setIsLoading({ ...isLoading, equipments: true })
    }, 300)

    if (!opt) {
      filterHandler.removeFilters(['user.company', 'user._id', 'equipment.currentCompany._id', 'equipment._id']);
    } else {
      filterHandler.updateQueryFilter({
        'currentCompany._id': {
          value: opt._id,
          toObjectId: false,
        },
      });
      filterHandler.updateQueryFilter({ 'user.company': { value: opt._id, toObjectId: false}});
      // filterHandler.updateQueryFilter({ 'equipment.currentCompany._id': { value: opt._id, toObjectId: false}});
    }
  };

  const onUserChange = ({ opt, filterHandler }) => {
    if (!opt) {
      setUserFilled(false);
      filterHandler.removeFilter('user._id');
    } else {
      setUserFilled(true);
      filterHandler.updateQueryFilter({ 'user._id': { value: opt._id, toObjectId: false} })
      // {"$expr":{"$and":[{"$gte":["$date",{"$dateFromString":{"dateString":"2023-03-04T03:00:00.000Z"}}]},{"$lte":["$date",{"$dateFromString":{"dateString":"2023-03-06T02:59:59.999Z"}}]}]}}}
    }
  }

  const onEquipmentChange = async ({ opt, filterHandler }) => {
    // filterContext.updateEquipment(opt);
    if (!opt) {
      filterHandler.removeFilter('equipment');
    } else {
      filterHandler.updateQueryFilter({ equipment: opt.imei });
    }
  };

  const whenUserIsLoading = ({ fetchUrl, path, setFetchUrl, fields }) => {
    const companyField = fields.companies.current.value;
    if (companyField) {
      setFetchUrl(
        updateQueryFilter(fetchUrl, {
          'company._id': companyField._id,
        }),
      );
    } else {
      setFetchUrl(path);
    }
  }

  const whenEquipmentIsLoading = ({ fetchUrl, path, setFetchUrl, fields }) => {
    const companyField = fields.companies.current.value;
    if (companyField) {
      setFetchUrl(
        updateQueryFilter(fetchUrl, {
          "currentCompany._id": {
            value: companyField._id,
            toObjectId: false,
          },
        }),
      );
    } else {
      setFetchUrl(path);
    }
  }

  const _filters = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Empresa"
          showPlaceholder
          path="company?notPaginated=true&fields=_id,name"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={onCompanyChange}
        />
      ),
    },
    {
      name: 'users',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Usuário"
          showPlaceholder
          path="user?notPaginated=true"
          name="users"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando usuários..."
          noOptionsMessage="Nenhum usuário encontrado."
          onChange={onUserChange}
          whenIsLoading={whenUserIsLoading}
        />
      ),
    },
    {
      name: "equipments",
      component: (args) => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{ marginTop: 5 }}
          placeholder="Equipamento"
          showPlaceholder
          path="equipment/list?fields=_id,imei,currentCompany"
          name="equipments"
          getOptionLabel={(opt) => opt.imei}
          loadingMessage="Carregando equipamentos..."
          noOptionsMessage="Nenhum equipamento encontrado."
          onChange={onEquipmentChange}
          whenIsLoading={whenEquipmentIsLoading}
          // showLoading={false}
        />
      ),
    },
    {
      name: 'initialDate',
      component: args => (
        <DateTimePicker
          {...args}
          placeholder="Data ínicio"
          onChange={({ date, filterHandler }) => {
              setDataInitalDateFilled(date ? true : false);
              setInitialDate(date)
              setTimeout(() => {
                onDateChange({ date, filterHandler }, '$gte')
              }, 300)
          }}
          value={initialDate}
        />
      ),
    },
    {
      name: 'finalDate',
      component: args => (
        <DateTimePicker
          {...args}
          placeholder="Data término"
          onChange={({ date, filterHandler }) =>  {
            setDataFinalDateFilled(date ? true : false);
            setEndDate(date)
            onDateChange({ date, filterHandler }, '$lte')
          }}
          value={endDate}
        />
      ),
    },
  ];

  const _tableHeaderActions = [
    <TableHeaderAction
      title="Exportar XLS"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>XLS</Typography>}
      onClick={() => exportSpreadsheet(query)}
    />,
    <TableHeaderAction
      title="Exportar PDF"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>PDF</Typography>}
      onClick={() => exportPdf(query)}
    />,
  ];

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <Filter
          noSearch
          hideButton
          path={PATH}
          query={query}
          setQuery={setQuery}
          asyncFields={_filters}
          containerStyle={{ paddingLeft: 0, paddingTop: 0 }}
        />
      </Grid>
      <Table
        columns={REPORT_COLUMNS}
        data={data}
        query={query}
        setQuery={setQuery}
        containerStyle={{ marginTop: 0 }}
        isLoading={isLoading || isGeneratingReport}
        HeaderComponent={<TableHeader headerActions={_tableHeaderActions} />}
      />
    </Grid>
  );
}

LogsReport.propTypes = {
};

LogsReport.defaultProps = {
};

export default LogsReport;
