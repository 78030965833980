import React, { useState, useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';


import {
  datetimeFormatter,
  durationFormatter,
  percentageFormatter,
  temperatureFormatter,
  speedFormatter,
  defaultFormatter,
  imeiFormatter,
  latlongFormatter,
  lastValidPositionDateFormatter,
} from 'utils/formatters';
import { minutesToTimeString, boolToString } from 'utils/helpers';


import theme from 'styles/theme';
import Table from 'components/table/Table';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import ReportFilters from 'components/filter/ReportFilters';

// import { REPORT_COLUMNS as BASE_REPORT_COLUMNS } from './columns';
import { useQuery } from 'hooks/fetch';
import { usePaginatedData } from 'hooks/common';
import { useAccessLog } from 'hooks/logs';
import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import ReportFilterContext from 'pages/Reports/ReportFilterContext';
import { getDateFiltersFromContext } from 'pages/Reports/helpers';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  root: { ...theme.custom.page.root },
}));

const PATH = 'equipment-event';

let REPORT_COLUMNS = [
  {
    label: 'Empresa',
    identifier: 'company',
    value: ({ equipment }) =>
      equipment
        ? defaultFormatter(
            equipment.currentCompany ? equipment.currentCompany.name : undefined
          )
        : '',
  },
  {
    label: 'Equipamento',
    identifier: 'equipment',
    value: ({ equipment, imei }) => (equipment ? equipment.shortImei : imei),
    sort: 'equipment.shortImei',
  },
  {
    label: 'Data/Hora',
    identifier: 'timestamp',
    value: ({ timestamp }) => datetimeFormatter(timestamp),
    sort: 'timestamp',
  },
  {
    label: 'Intervalo',
    identifier: '',
    value: ({ positionSendInterval }) =>
      minutesToTimeString(positionSendInterval),
    sort: 'positionSendInterval',
  },
  {
    label: 'Bat.',
    identifier: 'battery',
    value: ({ adjustedInternalBatteryPercentage, internalBatteryPercentage }) =>
      percentageFormatter(
        Number(
          (adjustedInternalBatteryPercentage || internalBatteryPercentage) / 100
        )
      ),
    sort: 'internalBatteryPercentage',
  },
  {
    label: 'Posição',
    identifier: 'position',
    value: ({ positionIndicator }) =>
      (positionIndicator && positionIndicator[0]) || '-',
    sort: '',
  },
  {
    label: 'Operadora',
    identifier: 'cellphone_operator',
    value: ({ cellIdMncName }) => cellIdMncName,
    sort: ''
  },
  {
    label: 'Comunicação',
    identifier: 'comunication',
    value: ({ communicationType }) => communicationType || '-',
    sort: '',
  },
  {
    label: 'RF',
    identifier: 'rf',
    value: ({ status }) =>
      status && (status.transmitter434Mhz || status.transmitter928Mhz)
        ? 'Ligado'
        : 'Desligado',
    sort: '',
  },
  {
    label: 'Sinal',
    identifier: 'signal',
    value: ({ gsmModelSignal }) =>
      percentageFormatter(Number(gsmModelSignal / 32)),
    sort: 'gsmModelSignal',
  },
  {
    label: 'Lat/Long',
    identifier: 'lat_long',
    value: ({ latitude, longitude }) => latlongFormatter(latitude, longitude),
  },
  {
    label: 'Local',
    identifier: 'local',
    value: ({ local }) => defaultFormatter(local),
    sort: 'local',
  },
  {
    label: 'Jammer',
    identifier: 'jammer',
    // status.jammingDetected = protocolV2
    // jamming = protocolV1
    value: ({ status, jamming }) =>
      boolToString((status && status.jammingDetected) || jamming || false),
  },
  {
    label: 'Temperatura',
    identifier: 'temperature',
    value: ({ temperature }) => temperatureFormatter(temperature),
    sort: 'temperature',
  },
  {
    label: 'Umidade/T.Baú 1',
    identifier: 'temperatureHumidityOne',
    value: ({ temperatureHumidity }) =>
      (temperatureHumidity &&
        `${temperatureHumidity.humidity} / ${temperatureHumidity.temperature}`) ||
      '-',
    sort: '',
    headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
  },
  {
    label: 'Umidade/T.Baú 2',
    identifier: 'temperatureHumidityTwo',
    value: ({ temperatureHumiditySecondSensor }) =>
      (temperatureHumiditySecondSensor &&
        `${temperatureHumiditySecondSensor.humidity} / ${temperatureHumiditySecondSensor.temperature}`) ||
      '-',
    sort: '',
    headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
  },
  {
    label: 'Km Rodado',
    identifier: 'kmTraveled',
    value: ({ kmDistance }) => defaultFormatter(kmDistance),
    sort: 'kmDistance',
  },
  {
    label: 'Satélites',
    identifier: 'satelities',
    value: ({ gpsSatelliteNumber }) => defaultFormatter(gpsSatelliteNumber),
    sort: 'gpsSatelliteNumber',
  },
  {
    label: 'Velocidade',
    identifier: 'speed',
    value: ({ gpsSpeed }) => speedFormatter(gpsSpeed),
    sort: 'gpsSPeed',
  },
  {
    label: 'Última posição válida',
    identifier: 'lastValidPosition',
    value: ({ lastValidPositionDate, lastValidPositionHour }) =>
      lastValidPositionDateFormatter(
        lastValidPositionDate,
        lastValidPositionHour
      ),
  },
  {
    label: 'ID Módulo LORA',
    value: ({ loraModuleId }) => loraModuleId ? loraModuleId : '-',
    sort: 'loraModuleId',
  },
];

function HistoryReport(props) {
  useAccessLog('Acesso ao relatório de histórico');
  const classes = useStyles();
  const filterContext = useContext(ReportFilterContext);
  const [data, setData] = usePaginatedData();

  const [dataInitalDateFilled, setDataInitalDateFilled] = useState(false);
  const [dataFinalDateFilled, setDataFinalDateFilled] = useState(false);

  const defaultFilter = {
    imei: filterContext.equipment ? filterContext.equipment.imei : undefined,
    $expr: { $and: getDateFiltersFromContext(filterContext, '$timestamp') },
  };
  if (filterContext.company) {
    defaultFilter['equipment.currentCompany._id'] = {
      value: filterContext.company._id,
      toObjectId: false,
    };
  }

  const [query, setQuery, isLoading] = useQuery(
    PATH,
    setData,
    [
      'equipment.shortImei',
      'equipment.currentCompany',
      'timestamp',
      'positionSendInterval',
      'internalBatteryPercentage',
      'adjustedInternalBatteryPercentage',
      'gpsSpeed',
      'gpsSatelliteNumber',
      'positionIndicator',
      'rfTriangulation',
      'temperatureHumidity',
      'temperatureHumiditySecondSensor',
      'temperature',
      'gsmModelSignal',
      'kmDistance',
      'local',
      'isInsideReferencePoint',
      'referencePointLocal',
      'imei',
      'latitude',
      'longitude',
      'status',
      'jamming',
      'protocolVersion',
      'lastValidPositionDate',
      'lastValidPositionHour',
      'communicationType',
      'simCardOperator',
      'cellIdMncName',
      'loraModuleId'
    ],
    {
      filter: defaultFilter,
      sort: {
        timestamp: -1,
      },
    },
    q => {
      if (!dataInitalDateFilled || !dataFinalDateFilled) {
        return false;
      }
      const obj = q.queryObject;
      return Boolean(obj && obj.filter && obj.filter.imei);
    },
    false,
    {
      removeFilters: ['equipment.currentCompany._id'],
    }
  );

  const [isGeneretingReport, setIsGeneratinReport] = useState(false);

  useEffect(() => {
    async function fetchData() {
      REPORT_COLUMNS = await getAvailableColumns();
    }

    fetchData();
  }, [])

  const getAvailableColumns = async () => {

    return new Promise(async (resolve, reject) => {

      let REPORT_COLUMNS = [
        {
          label: 'Empresa',
          identifier: 'company',
          value: ({ equipment }) =>
            equipment
              ? defaultFormatter(
                  equipment.currentCompany ? equipment.currentCompany.name : undefined
                )
              : '',
        },
        {
          label: 'Equipamento',
          identifier: 'equipment',
          value: ({ equipment, imei }) => (equipment ? equipment.shortImei : imei),
          sort: 'equipment.shortImei',
        },
        {
          label: 'Data/Hora',
          identifier: 'timestamp',
          value: ({ timestamp }) => datetimeFormatter(timestamp),
          sort: 'timestamp',
        },
        {
          label: 'Intervalo',
          identifier: '',
          value: ({ positionSendInterval }) =>
            minutesToTimeString(positionSendInterval),
          sort: 'positionSendInterval',
        },
        {
          label: 'Bat.',
          identifier: 'battery',
          value: ({ adjustedInternalBatteryPercentage, internalBatteryPercentage }) =>
            percentageFormatter(
              Number(
                (adjustedInternalBatteryPercentage || internalBatteryPercentage) / 100
              )
            ),
          sort: 'internalBatteryPercentage',
        },
        {
          label: 'Posição',
          identifier: 'position',
          value: ({ positionIndicator }) =>
            (positionIndicator && positionIndicator[0]) || '-',
          sort: '',
        },
        {
          label: 'Operadora',
          identifier: 'cellphone_operator',
          value: ({ cellIdMncName }) => cellIdMncName,
          sort: ''
        },
        {
          label: 'Comunicação',
          identifier: 'comunication',
          value: ({ communicationType }) => communicationType || '-',
          sort: '',
        },
        {
          label: 'RF',
          identifier: 'rf',
          value: ({ status }) =>
            status && (status.transmitter434Mhz || status.transmitter928Mhz)
              ? 'Ligado'
              : 'Desligado',
          sort: '',
        },
        {
          label: 'Sinal',
          identifier: 'signal',
          value: ({ gsmModelSignal }) =>
            percentageFormatter(Number(gsmModelSignal / 32)),
          sort: 'gsmModelSignal',
        },
        {
          label: 'Lat/Long',
          identifier: 'lat_long',
          value: ({ latitude, longitude }) => latlongFormatter(latitude, longitude),
        },
        {
          label: 'Local',
          identifier: 'local',
          value: ({ local, isInsideReferencePoint, referencePointLocal }) => {
            let eventLocal = '';
            if(isInsideReferencePoint === undefined || isInsideReferencePoint === false) {
              eventLocal = local;
            } else if(isInsideReferencePoint === true) {
              eventLocal = referencePointLocal;
            } else {
              eventLocal = local;
            }
            // console.log(isInsideReferencePoint, referencePointLocal)
            return defaultFormatter(eventLocal)
          },
          sort: 'local',
        },
        {
          label: 'Sensor de Movimento',
          identifier: 'decouplingSensorMode',
          value: ({ status }) =>
            status && (status.decouplingSensorMode)
              ? 'Ligado'
              : 'Desligado',
          sort: '',
        },
        {
          label: 'Jammer',
          identifier: 'jammer',
          // status.jammingDetected = protocolV2
          // jamming = protocolV1
          value: ({ status, jamming }) =>
            boolToString((status && status.jammingDetected) || jamming || false),
        },
        {
          label: 'Temperatura',
          identifier: 'temperature',
          value: ({ temperature }) => temperatureFormatter(temperature),
          sort: 'temperature',
        },
        {
          label: 'Umidade/T.Baú 1',
          identifier: 'temperatureHumidityOne',
          value: ({ temperatureHumidity }) =>
            (temperatureHumidity &&
              `${temperatureHumidity.humidity} / ${temperatureHumidity.temperature}`) ||
            '-',
          sort: '',
          headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
        },
        {
          label: 'Umidade/T.Baú 2',
          identifier: 'temperatureHumidityTwo',
          value: ({ temperatureHumiditySecondSensor }) =>
            (temperatureHumiditySecondSensor &&
              `${temperatureHumiditySecondSensor.humidity} / ${temperatureHumiditySecondSensor.temperature}`) ||
            '-',
          sort: '',
          headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
        },
        {
          label: 'Km Rodado',
          identifier: 'kmTraveled',
          value: ({ kmDistance }) => defaultFormatter(kmDistance),
          sort: 'kmDistance',
        },
        {
          label: 'Satélites',
          identifier: 'satelities',
          value: ({ gpsSatelliteNumber }) => defaultFormatter(gpsSatelliteNumber),
          sort: 'gpsSatelliteNumber',
        },
        {
          label: 'Velocidade',
          identifier: 'speed',
          value: ({ gpsSpeed }) => speedFormatter(gpsSpeed),
          sort: 'gpsSPeed',
        },
        {
          label: 'Última posição válida',
          identifier: 'lastValidPosition',
          value: ({ lastValidPositionDate, lastValidPositionHour }) =>
            lastValidPositionDateFormatter(
              lastValidPositionDate,
              lastValidPositionHour
            ),
        },
        {
          label: 'ID Módulo LORA',
          identifier: 'loraModuleId',
          value: ({ loraModuleId }) => loraModuleId ? loraModuleId : '-',
          sort: 'lastEvent.loraModuleId',
        },
      ];
    
      const userData = await fetchAuthenticated('get', `user/${JSON.parse(localStorage.getItem('user'))._id}`);

      if(userData.ok) {
        userData.json().then(_userData => {

          if(_userData.hasOwnProperty('profileFieldsAvailable') && _userData.profileFieldsAvailable.length > 0) {
            if(_userData.profileFieldsAvailable.every(x => x.temperature === false)) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'temperature'), 1)
            } 
            
            if(_userData.profileFieldsAvailable.every(x => x.temperatureHumidityOne === false)) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'temperatureHumidityOne'), 1)
            } 
            
            if(_userData.profileFieldsAvailable.every(x => x.temperatureHumidityTwo === false)) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'temperatureHumidityTwo'), 1)
            } 
            
            if(_userData.profileFieldsAvailable.every(x => x.kmTraveled === false)) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'kmTraveled'), 1)
            } 
            
            if(_userData.profileFieldsAvailable.every(x => x.satelities === false)) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'satelities'), 1)
            } 
            
            if(_userData.profileFieldsAvailable.every(x => x.speed === false)) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'speed'), 1)
            } 
            
            if(_userData.profileFieldsAvailable.every(x => x.lastValidPosition === false)) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'lastValidPosition'), 1)
            }

            if(!(_userData.profileFieldsAvailable.some(x => x.profile === "5d8e6845957607437679e11d"))) {
              REPORT_COLUMNS.splice(REPORT_COLUMNS.findIndex(x => x.identifier === 'loraModuleId'), 1)
            }
          }

          resolve(REPORT_COLUMNS);
        })
      }

    })
  }

  const exportSpreadsheet = async query => {
    setIsGeneratinReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', {
        message: 'Exportação limitada a 5000 itens',
        type: 'info',
      });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/history/xls${query.queryString}`
    );
    logAction('Exportou relatório de histório em XLS');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Historico.xls');
    setIsGeneratinReport(false);
  };

  const exportPdf = async query => {
    setIsGeneratinReport(true);
    if (data && data.totalItems && data.totalItems > 5000) {
      emitEvent('showSnack', {
        message: 'Exportação limitada a 5000 itens',
        type: 'info',
      });
    }
    const response = await fetchAuthenticated(
      'GET',
      `${PATH}/report/history/pdf${query.queryString}`
    );

    logAction('Exportou relatório de histório em PDF');
    const blob = await response.blob();
    saveAs(blob, 'Relatorio_Historico.pdf');
    setIsGeneratinReport(false);
  };

  const onCompanyChange = async ({ opt, filterHandler }) => {
    filterContext.updateCompany(opt);
    if (!opt) {
      filterHandler.removeFilter('equipment.currentCompany._id');
    } else {
      filterHandler.updateQueryFilter({
        'equipment.currentCompany._id': {
          value: opt._id,
          toObjectId: false,
        },
      });
    }
  };

  const onEquipmentChange = async ({ opt, filterHandler }) => {
    filterContext.updateEquipment(opt);
    if (!opt) {
      filterHandler.removeFilter('imei');
    } else {
      filterHandler.updateQueryFilter({ imei: opt.imei });
    }
  };

  const onDateChange = async ({ date, filterHandler }, comparator) => {
    let _andClause = [];

    if (query && query.queryObject) {
      const _obj = { ...query.queryObject.filter };

      // Check if filter already exists and removes it
      if (_obj && _obj.$expr && _obj.$expr.$and) {
        _andClause = _obj.$expr.$and.filter(
          exp => !exp.hasOwnProperty(`${comparator}`)
        );
      }
    }

    if (date) {
      _andClause.push({
        [`${comparator}`]: [
          '$timestamp',
          { $dateFromString: { dateString: date } },
        ],
      });
    }

    // It removes add an empty _andClause if no value is supplied and the
    // correct value otherwise
    filterHandler.updateQueryFilter({
      $expr: { $and: _andClause },
    });
  };

  const _tableHeaderActions = [
    <TableHeaderAction
      title="Exportar XLS"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>XLS</Typography>}
      onClick={() => exportSpreadsheet(query)}
    />,
    <TableHeaderAction
      title="Exportar PDF"
      IconProps={{ style: theme.custom.icon }}
      Icon={props => <Typography {...props}>PDF</Typography>}
      onClick={() => exportPdf(query)}
    />,
  ];

  return (
    <Grid container direction="column" classes={{ root: classes.root }}>
      <Grid container item>
        <ReportFilters
          path={PATH}
          query={query}
          setQuery={setQuery}
          values={filterContext}
          onCompanyChange={onCompanyChange}
          onEquipmentChange={onEquipmentChange}
          onInitialDateChange={({ date, filterHandler }) => {
            setDataInitalDateFilled(date ? true : false);
            filterContext.updateInitialDate(date);
            onDateChange({ date, filterHandler }, '$gte');
          }}
          onFinalDateChange={({ date, filterHandler }) => {
            setDataFinalDateFilled(date ? true : false);
            filterContext.updateFinalDate(date);
            onDateChange({ date, filterHandler }, '$lte');
          }}
        />
      </Grid>
      <Table
        columns={REPORT_COLUMNS}
        data={data}
        query={query}
        setQuery={setQuery}
        containerStyle={{
          marginTop: 0,
        }}
        HeaderComponent={<TableHeader headerActions={_tableHeaderActions} />}
        isLoading={isLoading || isGeneretingReport}
      />
    </Grid>
  );
}

HistoryReport.defaultProps = {};

export default HistoryReport;
