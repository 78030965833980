import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditBip from "pages/Records/Bips/Edit";
import ListBip from "pages/Records/Bips/List";
import NewBip from "pages/Records/Bips/New";

export default function Bips (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Bip",
      path: '/novo',
      component: NewBip,
    },
    {
      name: "Bip",
      path: '/:id',
      component: EditBip,
    },
    {
      name: "Bip",
      path: '',
      component: ListBip
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
