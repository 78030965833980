import React, { useEffect } from "react";
import ActionList from 'components/lists/ActionList';
import Edit from '@material-ui/icons/Edit';
import TableHeaderAction from 'components/table/TableHeaderAction';
import ModalTemperatureRangeEdit from 'components/modals/ModalTemperatureRangeEdit';
import { useAccessLog } from 'hooks/logs';

function ListTemperatureRange(props) {
  useAccessLog('Acesso ao cadastro de Alarme de Temperatura');
  const { match } = props;

  return (
    <ActionList
      modals={{
        'Edit': {
          isVisible: false,
          needReloadData: true,
          component: (props) => (
            <ModalTemperatureRangeEdit key='EditModal' {...props} />
          ),
        },
      }}
      headerActions={[
        (setVisibleModal, checkedList) => (
          <TableHeaderAction
            title="Editar em massa"
            Icon={Edit}
            disabled={checkedList.length === 0}
            onClick={() => { setVisibleModal('Edit', true) }}
          />
        )
      ]}

      columns={[
        { value: row => row.shortImei, label: 'IMEI', sort: 'shortImei' },
        { value: row => {
            if(row.temperatureRangeEnabled === 'Sim') {
              return 'LIGADO'
            } else {
              return 'DESLIGADO'
            }
          }, 
          label: 'Status', sort: 'temperatureRangeEnabled' 
        },
        {
          value: row => {
            if(row.temperatureRangeEnabled === 'Sim' && row.hasOwnProperty('temperatureRangeType')) {
              if(row.temperatureRangeType === 1) {
                return 'Temperatura do Baú'
              } else if(row.temperatureRangeType === 2) {
                return 'Temperatura'
              }
            } else {
              return '-'
            }
          },
          label: 'Tipo de Temperatura'
        },
        {
          value: row => {
            if(row.hasOwnProperty('temperatureRange') && row.temperatureRange !== null) {
              return `${row.temperatureRange.min}ºC - ${row.temperatureRange.max}ºC`
            } else {
              return '-'
            }
          },
          label: 'Range'
        }
      ]}
      genericSearchFields={[
        { label: 'IMEI', name: 'imei' },
        { label: 'Código', name: 'code' },
        { label: 'Descrição', name: 'description' },
        { label: 'Tipo', name: 'equipmentType.name' },
        { label: 'Nome da Empresa', name: 'currentCompany.name' },
        { label: 'Cidade', name: 'currentCompany.addressCity.nome' },
        { label: 'Estado', name: 'currentCompany.addressState.nome' },
        { label: 'ICCID', name: 'iccid' },
      ]}
      basePath="equipment"
      title="Lista de Equipamentos"
    />
  );
}

export default ListTemperatureRange;
