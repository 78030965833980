import React from 'react';
import BaseList from 'components/lists/BaseList';
import { useAccessLog } from 'hooks/logs';

function ListFence(props) {
  useAccessLog('Acesso aos pontos de referência');
  const { match } = props;
  return (
    <BaseList
      columns={[
        { value: row => row.name, label: 'Nome', sort: 'name' },
        { value: row => {

          let companiesStr = ''

          let companiesLength = 1

          for(let company of row.companies) {
            if(companiesLength === 3) {
              companiesStr += ', ' + company.name + '...'
              companiesLength += 1
            }

            if(companiesLength <= 3) {
              if(companiesStr.length === 0) {
                companiesStr += company.name
                companiesLength += 1
              } else {
                companiesStr += ', ' + company.name
                companiesLength += 1
              }
            }
          }

          return companiesStr
        }, label: 'Empresas' },
        // { value: row => row.company && row.company.name, label: 'Empresa'},
      ]}
      genericSearchFields={[
        { label: 'Nome', name: 'name' },
        // { label: 'Empresas', name: 'name' },
      ]}
      basePath="reference-point"
      title="Lista de Cercas"
      addButtonText="Cadastrar Ponto de Referência"
      addUrl={`/cercas/pontosdereferencia/novo`}
    />
  );
}

export default ListFence;
