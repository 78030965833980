import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
  TextField,
  Grid
} from '@material-ui/core';
import { get, fetchAuthenticated, putWithErrorHandler, postWithErrorHandler } from 'services/fetch';
import { emitEvent } from 'utils/events';
import Switch from '@material-ui/core/Switch';
import Modal from 'components/modals/Modal';
import CheckboxList from "components/lists/CheckboxList";
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  followContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 51,
    borderBottom: 'solid 1px #80849d4d',
  },
  followHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  followTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  contact: {
    borderBottom: 'solid 1px #80849d4d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
  },
  splitContact: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newContactDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcon: {
    width: 47,
  },

}));

const ModalRequirePermission = (props) => {
  const { isVisible, setIsVisible, userData, checkAll, profileName, profileId, currentProfileFieldsAvailableData, setCurrentProfileFieldsAvailableData } = props;
  const classes = useStyles();

  const [showModal, setShowModal] = useState(isVisible);

  const [fieldsAvailable, setFieldsAvailable] = useState([]);

  useEffect(() => {

    if(JSON.stringify(currentProfileFieldsAvailableData) !== '{}') {
      const _fieldsAvailable = []

      for(const item of currentProfileFieldsAvailableData[profileId]) {
        _fieldsAvailable.push(JSON.parse(JSON.stringify(item)))
      }
      
      setFieldsAvailable(_fieldsAvailable);
    }
  }, [profileId])

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleSubmit = async (data) => {

    const _currentProfileFieldsAvailableData = JSON.parse(JSON.stringify(currentProfileFieldsAvailableData))

    _currentProfileFieldsAvailableData[profileId] = JSON.parse(JSON.stringify(fieldsAvailable))

    const _userData = userData;

    const _userDataProfileFieldsAvailable = [];
    
    for(const item of Object.entries(_currentProfileFieldsAvailableData)) {
      const key = item[0];
      const value = item[1];

      _userDataProfileFieldsAvailable.push({
        profile: key,
        temperature: value.find(x => x._id === 'temperature').checked,
        temperatureHumidityOne: value.find(x => x._id === 'temperatureHumidityOne').checked,
        temperatureHumidityTwo: value.find(x => x._id === 'temperatureHumidityTwo').checked,
        kmTraveled: value.find(x => x._id === 'kmTraveled').checked,
        satelities: value.find(x => x._id === 'satelities').checked,
        speed: value.find(x => x._id === 'speed').checked,
        lastValidPosition: value.find(x => x._id === 'lastValidPosition').checked,
        loraModuleId: value.find(x => x._id === 'loraModuleId').checked
      })
    }

    const requestData = {
      userId: _userData._id,
      profileFieldsAvailable: _userDataProfileFieldsAvailable
    }

    const successMessage = 'Informações atualizadas com sucesso';
    const errorMessage = 'Erro ao tentar atualizar as informações';
    const logMessage = 'Atualizar informações visíveis ao perfil';
    const response = await postWithErrorHandler('user/update-profile-fields-available', requestData, {
      successMessage, errorMessage, logMessage
    });

    setCurrentProfileFieldsAvailableData(_currentProfileFieldsAvailableData);

    setIsVisible(false);
    
  }

  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  }

  return (
    <Modal
      handleSubmit={() => handleSubmit(props.userData)}
      onSuccessMessage="Informações atualizadas com sucesso"
      isVisible={showModal} setIsVisible={setShowModal} title={"Visualizar relatório de histórico"} buttonLabel="Salvar"
      onClose={onClose}
      preventDefaultSubmit
    >
      <Typography className={classes.subsubheader} style={{ marginTop: '-20px', marginBottom: '30px' }}>
        Selecionar campos visíveis do perfil: <b>{props.profileName}</b>
      </Typography>
      <Grid item xs={12} className={classes.checkboxContainer}>
        {
          profileId !== '' && fieldsAvailable.length > 0 ?  
          <CheckboxList
            data={fieldsAvailable}
            setData={setFieldsAvailable}
            getName={(item) => item.label}
            labelStyle={{
              fontSize: 16,
              color: "#41475a",
              fontWeight: "normal",
            }}
            checkAll={checkAll}
            containerStyle={{
              flexDirection: "column",
              display: "flex",
              flexWrap: "wrap",
            }}
            showCheckAll={fieldsAvailable.length > 0}
          />
          : null
        }
      </Grid>
    </Modal>
  )
}

export default ModalRequirePermission;
