/* eslint-disable import/prefer-default-export */
/* eslint-disable quote-props */
const MAPS = {
  'dashboard': { path: '/dashboard', label: 'Dashboard' },
  'mapa': { path: '/mapa', label: 'Mapa' },
  'estaveis': { path: '/dashboard/estaveis', label: 'Estáveis' },
  'total-ativos': { path: '/dashboard/total-ativos', label: 'Total de equipamentos' },
  'bateria-baixa': { path: '/dashboard/bateria-baixa', label: 'Bateria baixa' },
  'sem-posicionar': { path: '/dashboard/sem-posicionar', label: 'Sem posicionar' },
  'posicoes-atrasadas': { path: '/dashboard/posicoes-atrasadas', label: 'Posições atrasadas' },
  'aguardando-ativacao': { path: '/dashboard/aguardando-ativacao', label: 'Aguardando ativação' },
  'expirados': { path: '/dashboard/expirados', label: 'Expirados' },
  'sem-bateria': { path: '/dashboard/sem-bateria', label: 'Sem bateria' },
  'jammer-detectado': { path: '/dashboard/jammer-detectado', label: 'Em emergência' },
  'rf-ativo': { path: '/dashboard/rf-ativo', label: 'RF Ativo' },
};


export const getBreadcrumbsFor = (path) => {
  const breadcrumbs = [];
  if (!path) {
    return breadcrumbs;
  }
  const exploded = path.split('/').filter(p => p);
  exploded.forEach((p) => {
    const crumb = MAPS[p];
    if (crumb) {
      breadcrumbs.push(crumb);
    }
  });
  return breadcrumbs;
};
