import React, { useEffect } from 'react';

import { fetchAuthenticated, get } from 'services/fetch';
import { emitEvent } from 'utils/events';

// import RfImportForm from 'components/forms/NewRfImportForm';
import NewErbForm from 'components/forms/NewErbForm';
import { logAction } from 'utils/logs';

export default function NewErb(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: '/cadastros/erb', label: 'Cadastro ERB' },
      { path: '', label: 'Novo Cadastro' }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs])

  // Handle form submit
  const handleSubmit = async (data) => {

    if(data.lac === '') {
      return
    }

    if(data.cellid === '') {
      return
    }
    
    if(data.lat === '') {
      return
    }

    if(data.long === '') {
      return
    }

    const response = await fetchAuthenticated('post', 'erb', data);

    if (!response.ok) {
      throw new Error('');
    } else {
      const data = await response.json();
      emitEvent('showSnack', { message: 'Cadastro realizado com sucesso!', type: 'success' });
      // logAction(`Criação do equipamento ${data.shortImei}`, data.imei)
      history.push(`/cadastros/erb/${data._id}`);
    }
  }

  // Handle cancel click
  const handleCancel = async () => {
    history.push('/cadastros/erb');
  }



  return (
    <div>
      <NewErbForm
        title='Novo cadastro'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
