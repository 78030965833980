import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import GlobalLinearProgress from 'components/Progress';

import theme from './styles/theme';
import Login from './pages/Login';
import Home from './pages/Home';
import PasswordRecover from './pages/Auth/PasswordRecover';
import SignaturePasswordRecover from './pages/Auth/SignaturePasswordRecover';
import ErrorPage from './pages/Error';
import { setItem } from 'services/local-storage';
import { isLogged, logoutUser, getUser } from './services/user';
import { fetchAuthenticated } from 'services/fetch';

import Snack from './components/Snack';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { emitEvent } from 'utils/events';
import { useIdleTimer } from 'react-idle-timer';

function App() {
  const checkPasswordValidity = async () => {
    if (isLogged()) {
      const result = await (
        await fetchAuthenticated('get', 'user/check-validity')
      ).json();

      if (result.isPasswordExpired) {
        emitEvent('showForcedPasswordChangeModal');
      }
    }
  };

  const handleOnIdle = async () => {
    if (!isLogged) return;

    let logout = false;

    try {
      logout = (await getUser()).inactivityLogout || false;
    } catch (error) {
      console.log(`An error ocurred while trying to return logout status`);
    }

    if (logout) {
      await logoutUser();
      setItem('inactivity-logout', true);
      window.location.replace('/');
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 15 * 60 * 1000, // 15 minutes
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    checkPasswordValidity();
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalLinearProgress></GlobalLinearProgress>
      <Snack />
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/error" component={ErrorPage} />
          <Route path="/recover/:hash?" component={PasswordRecover} />
          <Route path="/recover-signature-password/:hash?" component={SignaturePasswordRecover} />
          <PrivateRoute path="/" component={Home} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isLogged() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default App;
