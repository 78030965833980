import React from "react";
import { withStyles } from "@material-ui/core";
import GeneralParametersForm from "components/forms/GeneralParametersForm";

function EditGeneralParameters(props) {
  const { classes, match, history, setBreadcrumbs } = props;

  const handleCancel = () => {
    history.push('/');
  }

  return (
    <div>
      <GeneralParametersForm
        title='Configurações gerais da plataforma'
        handleCancel={handleCancel}
      />
    </div>
  );
}

const styles = theme => ({
  text: {
    fontSize: 16,
    color: theme.palette.gray.gray_4,
    maxWidth: 800,
  }
  /** */
});

export default withStyles(styles)(EditGeneralParameters);
