import {
  datetimeFormatter,
  percentageFormatter,
  lastValidPositionDateFormatter,
  temperatureFormatter,
  defaultFormatter,
} from 'utils/formatters';
import { minutesToTimeString, boolToString } from 'utils/helpers';

export const REPORT_COLUMNS = [
  {
    label: 'Empresa',
    value: ({ equipment }) =>
      equipment
        ? defaultFormatter(
            equipment.currentCompany ? equipment.currentCompany.name : undefined
          )
        : '',
  },
  {
    label: 'Equipamento',
    value: ({ equipment, imei }) => (equipment ? equipment.shortImei : imei),
    sort: 'equipment.shortImei',
  },
  // {
  //   label: 'Data/Hora',
  //   value: ({ timestamp }) => datetimeFormatter(timestamp),
  //   sort: 'timestamp',
  // },
  {
    label: 'Data/Hora',
    value: ({ lastValidPositionDate, lastValidPositionHour }) => lastValidPositionDateFormatter(lastValidPositionDate, lastValidPositionHour)
      // console.log(lastValidPositionDate, lastValidPositionHour)
    ,
      // lastValidPositionDateFormatter(
      //   equipment && equipment.lastEvent.lastValidPositionDate,
      //   equipment && equipment.lastEvent.lastValidPositionHour
      // ),
    sort: 'lastValidPositionDate',
  },
  {
    label: 'Intervalo',
    value: ({ positionSendInterval }) =>
      minutesToTimeString(positionSendInterval),
    sort: 'positionSendInterval',
  },
  {
    label: 'Bat.',
    value: ({ adjustedInternalBatteryPercentage, internalBatteryPercentage }) =>
      percentageFormatter(
        Number(
          (adjustedInternalBatteryPercentage || internalBatteryPercentage) / 100
        )
      ),
    sort: 'internalBatteryPercentage',
  },
  {
    label: 'Umidade/T.Baú',
    value: ({ temperatureHumidity }) =>
      (temperatureHumidity &&
        `${temperatureHumidity.humidity} / ${temperatureHumidity.temperature}`) ||
      '-',
    sort: ''
  },
  {
    label: 'Temperatura',
    value: ({ temperature }) => temperatureFormatter(temperature),
    sort: 'temperature',
  }
];
