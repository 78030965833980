import React, { useEffect } from 'react';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

import UserForm from 'components/forms/UserForm';
import { logAction } from 'utils/logs';

export default function NewUser(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: `/cadastros/usuarios`, label: `Usuários` },
      { path: ``, label: `Novo usuário` }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs]);

  // Handle form submit
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('post', 'user', data);

    if (!response.ok) {
      const error = await response.json();
      if (error.message) {
        throw new Error(error.message);
      } else {
        throw new Error();
      }
    } else {
      const data = await response.json();
      emitEvent('showSnack', { message: 'Usuário criado com sucesso', type: 'success' });
      logAction(`Cadastro / Usuários / Cadastrar Usuários / ${data.name}`)
      history.push(`/cadastros/usuarios/${data._id}`);
    }
  }

  // Handle cancel
  const handleCancel = () => {
    history.push('/cadastros/usuarios');
  }

  return (
    <div>
      <UserForm
        title='Novo Usuário'
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}
