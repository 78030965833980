
import { get } from 'services/fetch'

export const getThreshold = async () => {

  const result = await get('settings');
  const { hideFarthestPosition, farthestPositionRay } = result;

  return { hideFarthestPosition, farthestPositionRay }

}

export const haversineDistance = (coord1, coord2) => {
  const toRad = (x) => (x * Math.PI) / 180;

  const R = 6371; // Raio da Terra em quilômetros
  const dLat = toRad(coord2.lat - coord1.lat);
  const dLon = toRad(coord2.long - coord1.long);
  const lat1 = toRad(coord1.lat);
  const lat2 = toRad(coord2.lat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c * 1000; // Distância em quilômetros
}

export const analyzeCoordinates = (coordA, coordB, coordC, threshold = 2000) => {



  const distanceAB = haversineDistance(coordA, coordB);
  const distanceAC = haversineDistance(coordA, coordC);
  const distanceBC = haversineDistance(coordB, coordC);

  console.log(`Parâmetro considerado: ${threshold}`)

  console.log(`Coord A: ${coordA.lat}, ${coordA.long}`)
  console.log(`Coord B: ${coordB.lat}, ${coordB.long}`)
  console.log(`Coord C: ${coordC.lat}, ${coordC.long}`)

  console.log(`Distância A/B: ${Math.round(distanceAB, 2)}m`)
  console.log(`Distância A/C: ${Math.round(distanceAC, 2)}m`)
  console.log(`Distância B/C: ${Math.round(distanceBC, 2)}m`)

  // Nova lógica
  if (distanceAB > threshold && distanceAC > threshold) {
    console.log('Coordenada A está muito distante de B e C');
    return coordA;
  } else if (distanceAB > threshold && distanceBC > threshold) {
    console.log('Coordenada B está muito distante de A e C');
    return coordB;
  } else if (distanceAC > threshold && distanceBC > threshold) {
    console.log('Coordenada C está muito distante de A e B');
    return coordC;
  } else {
    console.log('Todas as coordenadas estão dentro do limite aceitável');
    return null;
  }
}
