import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#548cc1',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#eef0f5',
      primary: '#548cc1',
      white: '#ffffff',
    },
    text: {
      white: '#ffffff',
      black: '#000000',
      blue: {
        activeNavBar: '#548cc1',
        blue_1: '#548cc1',
      },
      red: '#f12121',
    },
    gray: {
      gray_1: '#80859b',
      gray_2: '#dee2f2',
      gray_3: '#e6e8f2',
      gray_4: '#41475a',
      gray_5: '#8e96b0',
      gray_6: '#808492',
      gray_7: '#c2c7db',
      gray_8: '#b2b7c7',
      navBar: '#b8bdd1',
    },
  },
  typography: {
    fontWeight: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    },
    useNextVariants: true,
    fontWeightBold: 'bold',
    fontFamily: ['Raleway', 'sans-serif'].join(','),
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        borderRadius: 16,
        textTransform: 'none',
        padding: '15px 50px',
        fontSize: '1em',
        fontWeight: 'bold',
      },
      containedSecondary: {
        borderRadius: 16,
        textTransform: 'none',
        padding: '15px 50px',
        fontSize: '1em',
      },
      text: {
        borderRadius: 16,
        textTransform: 'none',
        padding: '15px 50px',
        fontSize: '1em',
      },
    },
    MuiFormControl: {
      root: {
        minHeight: 68,
      },
      marginDense: {
        minHeight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        marginRight: 0,
        marginLeft: 0,
      },
    },
  },
  main: {
    padding: {
      top: 16,
      left: 34,
      right: 25,
      bottom: 16,
    },
    filterPadding: {
      top: 20,
    },
    paperPadding: {
      vertical: 25,
      horizontal: 32,
      top: 25,
      bottom: 25,
      left: 32,
      right: 32,
    },
  },
  border: {
    radius: 22,
  },
  text: {
    header: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    subheader: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    content: {
      fontSize: 14,
      fontWeight: 'normal',
    },
  },
  input: {
    borderStyle: {
      borderBottom: 'solid 2px #e3e4e8',
    },
  },
  status: {
    green: '#5bf293',
    waterBlue: '#07A39F'
  },
  custom: {
    containers: {
      center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: ' center',
        flex: 1,
      },
    },
    page: {
      root: {
        flex: 1,
        paddingTop: 16,
        paddingBottom: 25,
        paddingLeft: 34,
        paddingRight: 25,
      },
    },
    paper: {
      root: {
        borderRadius: 22,
        paddingTop: 25,
        paddingBottom: 25,
        paddingRight: 32,
        paddingLeft: 32,
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
      },
    },
    icon: {
      fontWeight: 'bold',
      fontSize: 14,
      height: 24,
      display: 'flex',
      alignItems: 'center',
    },
  },
});

export default theme;
