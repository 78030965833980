import React, { useEffect } from 'react';

import { fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';

import ReferencePointForm from 'components/forms/ReferencePointForm';
import { logAction } from 'utils/logs';

export default function NewUser(props) {
  const { history, setBreadcrumbs } = props;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: `/cercas/pontosdereferencia`, label: `Pontos de Referência` },
      { path: ``, label: `Novo ponto de referência` }
    ]);
    return () => setBreadcrumbs([]);
  }, [setBreadcrumbs]);


  // Handle cancel
  const handleCancel = () => {
    history.push('/cercas/pontosdereferencia');
  }

  return (
    <div>
      <ReferencePointForm
        title='Novo Ponto de Referência'
        handleCancel={handleCancel}
      />
    </div>
  );
}
