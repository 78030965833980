import React from 'react';

import SubRoutes from "components/SubRoutes";
import ListTemperatureRange from "pages/Records/TemperatureRange/List";

export default function TemperatureRange (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "TemperatureRange",
      path: '',
      component: ListTemperatureRange
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
