import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { fetchAuthenticated } from 'services/fetch';
import { Prompt } from 'react-router';

import { emitEvent } from "utils/events";
import UserForm from "components/forms/UserForm";
import ModalRemove from 'components/modals/ModalRemove';
import { logAction } from 'utils/logs';
import { buildLogString } from 'utils/helpers'

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

function EditUser(props) {
  const classes = useStyles();
  const { history, match, setBreadcrumbs } = props;
  const [user, setUser] = useState();
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const translateKeys = {
    signaturePasswordIsEnabled: 'Assinatura Eletrônica Ativada',
    isActive: 'Usuário Ativo',
    inactivityLogout: 'Desconectar após inatividade',
    integrationUser: 'Usuário de Integração',
    profiles: 'Perfis',
    company: 'Empresa',
    name: 'Nome',
    cpf: 'Cpf',
    nationality: 'Nacionalidade',
    address: 'Endereço',
    addressHouseNumber: 'Número',
    addressAdditionalInfo: 'Complemento',
    addressZipcode: 'CEP',
    addressNeighborhood: 'Bairro',
    addressState: 'UF',
    addressCity: 'Cidade',
    jobRole: 'Cargo',
    phone: 'Telefone',
    phoneExtension: 'Ramal',
    radio: 'Rádio',
    email: 'Email',
    username: 'Usuário',
    code: 'Código'
    // profileFieldsAvailable: '',
    // passwordRecoverHash: '',
  }

  // User base user url
  const userURL = `user/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    setBreadcrumbs([
      { path: `/cadastros/usuarios`, label: `Usuários` },
      { path: ``, label: `${user && user.name || ''}` }
    ]);
    return () => {
      setBreadcrumbs([]);
    }
  }, [setBreadcrumbs, match.params.id, user]);

  // Load user info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');

      const response = await fetchAuthenticated('get', userURL);

      // Any erro will redirect to companies list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/usuarios');
      } else {
        const _user = await response.json();
        setUser(_user);
        logAction(`Acesso ao cadastro do usuário ${_user.username}`)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, userURL])

  useEffect(() => {

  })

  // Handle form submit
  const handleSubmit = async (data, profiles) => {
    try {
      const response = await fetchAuthenticated('put', userURL, data);
      if (!response.ok) {
        throw new Error('')
      } else {
        const _user = await response.json();

        let updatedItemsStr = buildLogString(user, data, translateKeys, ['profiles', 'company'], {'true': 'Ativo', 'false': 'Inativo'})

        let allProfiles = user.profiles.map(item => item._id)
        allProfiles = [...allProfiles, ...data.profiles]
        allProfiles = allProfiles.filter((value, index, self) => {
          return self.indexOf(value) === index;
        })

        let updatedProfiles = []

        for(const profile of allProfiles) {
          
          let isInOldData = user.profiles.find(x => x._id === profile) === undefined ? false : true
          let isInNewData = data.profiles.includes(profile)

          let id = profile
          let name = profiles.find(x => x._id === profile)['name']

          if(isInOldData && !isInNewData) {
            updatedProfiles.push({
              id: id,
              name: name,
              action: 'removed'
            })
          } else if(!isInOldData && isInNewData) {
            updatedProfiles.push({
              id: id,
              name: name,
              action: 'added'
            })
          }
        }

        if(updatedProfiles.length > 0) {
          updatedItemsStr += ' / Edição dos Perfis de Acesso /'

          for(const updatedProfile of updatedProfiles) {

            let lastStatus = updatedProfile['action'] === 'added' ? 'Desabilitado' : 'Habilitado'
            let currentStatus = updatedProfile['action'] === 'added' ? 'Habilitado' : 'Desabilitado'

            updatedItemsStr += ` ${updatedProfile['name']} | De: ${lastStatus} Para: ${currentStatus} /`
          }
        }

        if (updatedItemsStr.charAt(updatedItemsStr.length - 1) === "/") {
          updatedItemsStr = updatedItemsStr.slice(0, -1);
        }

        logAction(`Edição do usuário ${_user.username}${updatedItemsStr}`);
        emitEvent('showSnack', { message: 'Usuário atualizado com sucesso', type: 'success' });
        setHasUnsavedChanges(false);

        const _response = await fetchAuthenticated('get', userURL);
        if (!_response.ok) {
          throw new Error('')
        } else {
          const _user = await _response.json();
          setUser(_user)
        }

      }
    } catch (err) {
      throw err;
    }
  }

  const handleCancel = async (event) => {
    history.push('/cadastros/usuarios');
  }

  const handleRemove = async (event) => {
    setConfirmRemove(true);
  }

  const handleRemoveConfirm = async (event) => {
    setModalLoading(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', userURL)

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover o usuário', type: 'error' });
      } else {
        logAction(`Remoção do usuário ${user.username}`);
        emitEvent('showSnack', { message: 'Usuário removido com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setModalLoading(false);
      setConfirmRemove(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/usuarios');
      }
    }
  }

  const handleRemoveCancel = async (event) => {
    setModalLoading(false);
  }

  return (
    <div>
      <Prompt
        when={hasUnsavedChanges}
        message='Você tem certeza que deseja sair ? Você possui alterações não salvas'
      />
      
      <UserForm
        title='Detalhes do Usuário'
        data={user}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
        edit={true}
        hasUnsavedChanges={hasUnsavedChanges}
        setHasUnsavedChanges={setHasUnsavedChanges}
      />

      <ModalRemove
        isVisible={confirmRemove}
        setIsVisible={setConfirmRemove}
        titleLabel='do usuário'
        onSubmit={handleRemoveConfirm}
        onCancel={handleRemoveCancel}
        isLoading={modalLoading}
        removeLabel='o usuário'
        extraContent='Ao realizar está ação o usuário não terá mais acesso ao sistema'
      />

    </div>
  );
}

export default EditUser;
