import React, { useReducer, useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import FilterAutoComplete from 'components/filter/FilterAutoComplete';
import Map from '../components/map/Map';
import Permission from 'components/permissions/Permission';
import Filter from 'components/filter/Filter';
import Search from '@material-ui/icons/Search';
import SimpleTable from '../components/table/Table';
import TableMenu, { TableColumn } from 'components/table/TableMenu';
import ModalObs from 'components/modals/ModalObs';
import { CommandGprs, CommandAdmin } from '../components/commands/Commands';
import CommandSafeloggyModal from '../components/commands/CommandSafeloggyModal';
import CommandSafeloggySOSAndBlockModal from '../components/commands/CommandSafeloggySOSAndBlockModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { StatusColumn } from 'components/table/columns';
import theme from 'styles/theme';
import TableHeader from 'components/table/TableHeader';
import TableHeaderAction from 'components/table/TableHeaderAction';
import { List } from '@material-ui/icons';
import ModalSetUserMapFieldsAvailable from 'components/modals/ModalSetUserMapFieldsAvailable';

import BatteryIcon from '../assets/icon/battery.svg';

import { fetchAuthenticated } from 'services/fetch';
import { useAccessLog } from 'hooks/logs';
import { useQuery } from 'hooks/fetch';
import { emitEvent } from 'utils/events';

import { saveAs } from 'file-saver';
import { logAction } from 'utils/logs';

import {
  datetimeFormatter,
  percentageFormatter,
  latlongFormatter,
  poffFormatter,
} from 'utils/formatters';
import {
  isRfOn,
  getPositionIndicator,
  minutesToTimeString,
  getBatteryStatus,
} from 'utils/helpers';

import HomeFilterContext from 'pages/HomeFilterContext';

async function fetchData(path) {
  let data = null;
  emitEvent('showGlobalLinearProgress');

  const response = await fetchAuthenticated('GET', path);
  if (response.ok) data = await response.json();

  emitEvent('hideGlobalLinearProgress');
  return data;
}

function reduce(state, action) {
  switch (action.type) {
    case 'SET':
      return action.payload;
    default:
      return state;
  }
}

function Mapa(props) {
  const filterContext = useContext(HomeFilterContext);

  const genericSearchFields = [
    { label: 'Imei', name: 'imei' },
    { label: 'Código', name: 'code' },
    { label: 'Descrição', name: 'description' },
    { label: 'Tipo', name: 'equipmentType.name' },
    { label: 'Nome da Empresa', name: 'currentCompany.name' },
    // { label: 'Cidade', name: 'currentCompany.addressCity.nome', },
    // { label: 'Estado', name: 'currentCompany.addressState.nome', },
    {
      label: 'Placa / LP',
      name: 'vehicle',
    },
    { label: 'Local', name: 'lastEvent.local' },
    { label: 'Ponto de Referência', name: 'lastEvent.referencePointLocal' },
    { label: 'Observação', name: 'lastObservation.text' },
  ];

  useAccessLog('Acesso ao mapa');
  const { classes, location } = props;
  const path = 'equipment';

  const [modalMapFieldsAvailable, setModalMapFieldsAvailable] = useState(false);
  // const [fetchPath, setFetchPath] = useState(buildUrl(path, location, {sort: {'lastEvent.timestamp': -1}}));

  // const [query, setQuery] = useState(buildQuery(location, {filter: {currentCompany: {'$exists': true}}}))

  const [reload, setReload] = useState(false);
  // Fetch and re-fetch data every time `fetchPath` changes
  const [data, dispatch] = useReducer(reduce, false);

  const [userData, setUserData] = useState({});

  const [userMapFieldsAvailable, setUserMapFieldsAvailable] = useState({});

  const [chips, setChips] = useState(
    (filterContext && filterContext.searchTerm) || []
  );

  const [modalProfileFieldsAvailable, setModalProfileFieldsAvailable] =
    useState(false);

  const [query, setQuery, isLoading, refreshQuery] = useQuery(
    path,
    data => dispatch({ type: 'SET', payload: data }),
    undefined,
    {
      filter: {
        'currentCompany._id': {
          ...(filterContext.company
            ? { value: filterContext.company._id, toObjectId: false }
            : { $exists: true }),
        },
        lastEvent: { $exists: true },
        isActive: true,
        ...(filterContext.equipment && { _id: filterContext.equipment._id }),
        ...(filterContext.searchTerm && {
          searchText: filterContext.searchTerm,
          genericSearchFields: genericSearchFields.map(field => field.name),
        }),
      },
      sort: {
        'lastEvent.timestamp': -1,
      },
    },
    undefined,
    reload
  );
  const [centerMapOn, setCenterMapOn] = useState(null);

  const onModalObsSubmit = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const INTERVAL = 1000 * 60;
    let RELOAD_INTERVAL_REFERENCE = null;

    if (filterContext) {
      filterContext.updateSearchTerm(query.queryObject.filter.searchText);
    }

    const fn = async () => {
      RELOAD_INTERVAL_REFERENCE = setInterval(async () => {
        refreshQuery(query);
      }, INTERVAL);
    };
    fn();

    return () => clearInterval(RELOAD_INTERVAL_REFERENCE);
  }, [query]);

  useEffect(() => {
    const fn = async () => {
      const userData = await fetchAuthenticated(
        'get',
        `user/${JSON.parse(localStorage.getItem('user'))._id}`
      );
      if (userData.ok) {
        userData.json().then(__userData => {
          setUserData(__userData);

          setUserFieldsList(__userData);
        });
      }
    };

    fn();
  }, []);

  const rowActions = [
    {
      label: 'Comandos GPRS',
      permission: 'SendGPRSCommand',
      component: props => (
        <CommandGprs showStatus={true} isVisible={true} {...props} />
      ),
    },
    {
      label: 'Comandos Administrativos',
      permission: 'SendAdminCommand',
      component: props => (
        <CommandAdmin showStatus={true} isVisible={true} {...props} />
      ),
    },
    {
      label: 'Observações',
      permission: 'EditEquipmentObservations',
      component: props => (
        <ModalObs
          onSubmit={onModalObsSubmit}
          equipment={props}
          isVisible={true}
          {...props}
        />
      ),
    },
  ];

  const safeloggyRowActions = [
    {
      label: 'Comandos Bloqueador',
      permission: 'SendSafeloggyCommand',
      component: (props, setCurrentAction) => (
        <CommandSafeloggyModal
          isOpen
          setCurrentAction={setCurrentAction}
          {...props}
        />
      ),
    },
    {
      label: 'Bloqueador SOS e Bloqueio',
      permission: 'SendSafeloggyCommand',
      component: (props, setCurrentAction) => (
        <CommandSafeloggySOSAndBlockModal
          isOpen
          setCurrentAction={setCurrentAction}
          {...props}
        />
      ),
    },
    {
      label: 'Observações',
      permission: 'EditEquipmentObservations',
      component: props => (
        <ModalObs
          onSubmit={onModalObsSubmit}
          equipment={props}
          isVisible={true}
          {...props}
        />
      ),
    },
  ];

  const columns = [
    {
      label: 'Equip.',
      value: row => row.shortImei,
      sort: 'shortImei',
      identifier: 'equipment',
    },
    {
      label: 'Placa / LP',
      headerStyle: { 'whiteSpace': 'nowrap' },
      value: row => row.vehicle,
      sort: 'vehicle',
      identifier: 'plate',
    },
    {
      label: 'Tipo',
      value: row => row.equipmentType && row.equipmentType.name,
      sort: 'equipmentType.name',
      identifier: 'type',
    },
    {
      label: 'Comunicação',
      value: row => (row.lastEvent && row.lastEvent.communicationType) || '-',
      sort: 'lastEvent.communicationType',
      identifier: 'communicationType',
    },
    {
      label: 'Empresa',
      value: row => row.currentCompany && row.currentCompany.name,
      sort: 'currentCompany.name',
      identifier: 'company',
    },
    {
      label: 'V. FW',
      value: row => (row.lastEvent && row.lastEvent.firmwareVersion) || '-',
      sort: 'lastEvent.firmwareVersion',
      identifier: 'firmwareVersion',
    },
    {
      label: 'Bat.',
      value: row => (
        <div className={classes.chargingBattery}>
          <span>
            {(row.lastEvent &&
              percentageFormatter(
                Number(
                  (row.lastEvent.adjustedInternalBatteryPercentage ||
                    row.lastEvent.internalBatteryPercentage) / 100
                )
              )) ||
              '-'}
          </span>
          {getBatteryStatus(row.lastEvent) && (
            <img
              src={BatteryIcon}
              style={{ marginLeft: '10px' }}
              alt="Carregando"
            />
          )}
        </div>
      ),
      sort: 'lastEvent.internalBatteryPercentage',
      identifier: 'battery',
    },
    {
      label: 'Exp.',
      value: row => poffFormatter(row),
      identifier: 'expiration',
    },
    {
      label: 'Posição',
      value: row => row.lastEvent && getPositionIndicator(row.lastEvent, true),
      identifier: 'position',
    },
    {
      label: 'Sinal',
      value: row =>
        row.lastEvent &&
        row.lastEvent.gsmModelSignal &&
        `${Math.round(
          (parseInt(row.lastEvent.gsmModelSignal, 10) / 31) * 100
        )}%`,
      sort: 'lastEvent.gsmModelSignal',
      identifier: 'signal',
    },
    {
      label: 'Data/hora',
      value: row =>
        (row.lastEvent && `${datetimeFormatter(row.lastEvent.timestamp)}`) ||
        '-',
      sort: 'lastEvent.timestamp',
      identifier: 'datetime',
    },
    { label: 'RF', value: row => isRfOn(row.lastEvent), identifier: 'rf' },
    {
      label: 'FPP.',
      value: row =>
        row.lastEvent &&
        minutesToTimeString(row.lastEvent.positionSendInterval),
      sort: 'lastEvent.positionSendInterval',
      identifier: 'fpp',
    },
    {
      label: 'Local',
      // value: row => row.lastEvent && row.lastEvent.local,
      value: row =>
        'isInsideReferencePoint' in row.lastEvent &&
        row.lastEvent.isInsideReferencePoint
          ? row.lastEvent.referencePointLocal
          : row.lastEvent.local,
      sort: 'lastEvent.local',
      identifier: 'local',
    },
    {
      label: 'Lat/Long',
      value: row =>
        (row.lastEvent &&
          `${latlongFormatter(
            row.lastEvent.latitude,
            row.lastEvent.longitude
          )}`) ||
        '-/-',
      sort: '',
      identifier: 'latlong',
    },
    {
      label: 'ID Módulo LORA',
      value: row =>
        ((row.lastLoraModuleId && row.lastLoraModuleId) &&
          `${row.lastLoraModuleId}`) ||
        '-',
      sort: '',
      identifier: 'lastLoraModuleId',
    },
    {
      label: 'Status',
      component: row => (
        <TableColumn>
          <StatusColumn
            edit={false}
            // status={row.handlingStatus}
            status={row.currentHandlingStatus}
            dashboardCard={row.currentDashboardCard}
          />
        </TableColumn>
      ),
      identifier: 'status',
    },
    {
      label: '',
      component: (row, col) => (
        <Permission
          key={row._id}
          names={[
            'SendGPRSCommand',
            'SendAdminCommand',
            'EditEquipmentObservations',
          ]}
        >
          <TableMenu
            row={row}
            rowActions={
              row.equipmentType.name === 'Bloqueador'
                ? safeloggyRowActions
                : rowActions
            }
            icon={<MoreVertIcon />}
          />
        </Permission>
      ),
    },
  ];

  const setColumns = () => {
    if (JSON.stringify(userData) !== '{}') {
      if (
        userData.hasOwnProperty('userMapFieldsAvailable') &&
        userData.userMapFieldsAvailable.length > 0
      ) {
        if (userData.userMapFieldsAvailable.every(x => x.equipment === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'equipment'),
            1
          );
        }

        if (
          userData.userMapFieldsAvailable.every(
            x => x.communicationType === false
          )
        ) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'communicationType'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.plate === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'plate'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.type === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'type'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.company === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'company'),
            1
          );
        }

        if (
          userData.userMapFieldsAvailable.every(
            x => x.firmwareVersion === false
          )
        ) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'firmwareVersion'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.battery === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'battery'),
            1
          );
        }

        if (
          userData.userMapFieldsAvailable.every(x => x.expiration === false)
        ) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'expiration'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.position === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'position'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.signal === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'signal'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.datetime === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'datetime'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.rf === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'rf'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.fpp === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'fpp'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.local === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'local'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.latlong === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'latlong'),
            1
          );
        }

        if (userData.userMapFieldsAvailable.every(x => x.status === false)) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'status'),
            1
          );
        }

        if (!(userData.profiles.some(x => x._id === "5d8e6845957607437679e11d"))) {
          columns.splice(
            columns.findIndex(x => x.identifier === 'loraModuleId'),
            1
          );
        }
      }
    }
  };

  setColumns();

  const filterAsyncFields = [
    {
      name: 'companies',
      component: args => (
        <FilterAutoComplete
          {...args}
          menuContainerStyle={{
            marginTop: 5,
          }}
          placeholder="Empresa"
          showPlaceholder={true}
          hideLabel={true}
          path="company?notPaginated=true&fields=_id,name"
          name="companies"
          getOptionLabel={opt => opt.name}
          loadingMessage="Carregando empresas..."
          noOptionsMessage="Nenhuma empresa encontrada."
          onChange={args => {
            const { opt, fields, setIsLoading, isLoading, filterHandler } =
              args;
            // reset equipments filter
            // fields.equipments.current.resetField();
            setIsLoading({ ...isLoading, equipments: true });
            filterContext.updateCompany(opt);
            if (!opt) {
              filterHandler.removeFilter('currentCompany._id');
            } else {
              // update equipments available for filtering
              filterHandler.updateQueryFilter({
                currentCompany: { $exists: true },
                'currentCompany._id': { value: opt._id, toObjectId: false },
                isActive: true,
              });
            }
          }}
          value={filterContext.company}
        />
      ),
    },
    // {
    //   name: 'equipments',
    //   component: args => (
    //     <FilterAutoComplete
    //       {...args}
    //       menuContainerStyle={{
    //         marginTop: 5,
    //       }}
    //       placeholder="Equipamentos"
    //       showPlaceholder={true}
    //       hideLabel={true}
    //       path="equipment?notPaginated=true&fields=_id,imei&"
    //       additionalStartPath='filter={"isActive":true, "currentCompany._id": { "$exists": true }, "lastEvent": { "$exists": true }}'
    //       name="equipments"
    //       getOptionLabel={opt => opt.imei}
    //       loadingMessage="Carregando equipamentos..."
    //       noOptionsMessage="Nenhum equipamento encontrado."
    //       onChange={args => {
    //         const { opt, filterHandler } = args;
    //         filterContext.updateEquipment(opt);
    //         if (!opt) {
    //           filterHandler.removeFilter('_id');
    //         } else {
    //           filterHandler.updateQueryFilter({
    //             currentCompany: { $exists: true },
    //             _id: opt._id,
    //             isActive: true,
    //           });
    //         }
    //       }}
    //       whenIsLoading={({ path: defaultPath, setFetchUrl, fields }) => {
    //         const companyField = fields.companies.current.value;
    //         if (companyField) {
    //           const _filter = {
    //             'currentCompany._id': {
    //               value: companyField._id,
    //               toObjectId: false,
    //             },
    //             isActive: true,
    //           };
    //           setFetchUrl(`${defaultPath}filter=${JSON.stringify(_filter)}`);
    //         } else {
    //           setFetchUrl(
    //             `${defaultPath}filter=${JSON.stringify({ isActive: true })}`
    //           );
    //         }
    //       }}
    //       value={filterContext.equipment}
    //     />
    //   ),
    // },
  ];

  const Popover = props => (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography style={{ fontSize: 15 }}>
          <b>Última observação:</b>{' '}
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            marginLeft: 34,
            color: '#8e96b0',
            fontWeight: 'bold',
          }}
        >
          {datetimeFormatter(props.lastObservation.date)}
        </Typography>
      </div>
      <Typography style={{ fontSize: 14, marginTop: 17, color: '#41475a' }}>
        {props.lastObservation && props.lastObservation.text}
      </Typography>
    </>
  );

  const handleExportXLS = async query => {
    try {
      const response = await fetchAuthenticated(
        'GET',
        `equipment/report/xls${query.queryString}&reportIdentifier=map-report`
      );

      const blob = await response.blob();
      saveAs(blob, 'Relatorio_Equipamentos.xls');
      logAction('Exportou relatório de expirados em XLS');
    } catch (err) {
      console.log(err);
    }
  };

  const setUserFieldsList = async userData => {
    let __fields = [];

    if (userData.hasOwnProperty('userMapFieldsAvailable')) {
      try {
        __fields = [
          {
            _id: 'equipment',
            label: 'Equipamento',
            checked: userData.userMapFieldsAvailable[0]['equipment'],
          },
          {
            _id: 'plate',
            label: 'Placa / LP',
            checked: userData.userMapFieldsAvailable[0]['plate'],
          },
          {
            _id: 'type',
            label: 'Tipo',
            checked: userData.userMapFieldsAvailable[0]['type'],
          },
          {
            _id: 'communicationType',
            label: 'Comunicação',
            checked: userData.userMapFieldsAvailable[0].communicationType,
          },
          {
            _id: 'company',
            label: 'Empresa',
            checked: userData.userMapFieldsAvailable[0]['company'],
          },
          {
            _id: 'firmwareVersion',
            label: 'Versão do Firmware',
            checked: userData.userMapFieldsAvailable[0]['firmwareVersion'],
          },
          {
            _id: 'battery',
            label: 'Bateria',
            checked: userData.userMapFieldsAvailable[0]['battery'],
          },
          {
            _id: 'expiration',
            label: 'Expiração',
            checked: userData.userMapFieldsAvailable[0]['expiration'],
          },
          {
            _id: 'position',
            label: 'Posição',
            checked: userData.userMapFieldsAvailable[0]['position'],
          },
          {
            _id: 'signal',
            label: 'Sinal',
            checked: userData.userMapFieldsAvailable[0]['signal'],
          },
          {
            _id: 'datetime',
            label: 'Data e Hora',
            checked: userData.userMapFieldsAvailable[0]['datetime'],
          },
          {
            _id: 'rf',
            label: 'RF',
            checked: userData.userMapFieldsAvailable[0]['rf'],
          },
          {
            _id: 'fpp',
            label: 'FPP',
            checked: userData.userMapFieldsAvailable[0]['fpp'],
          },
          {
            _id: 'local',
            label: 'Local',
            checked: userData.userMapFieldsAvailable[0]['local'],
          },
          {
            _id: 'latlong',
            label: 'Lat/Long',
            checked: userData.userMapFieldsAvailable[0]['latlong'],
          },
          {
            _id: 'status',
            label: 'Status',
            checked: userData.userMapFieldsAvailable[0]['status'],
          },
          // {
          //   _id: 'loraModuleId',
          //   label: 'ID Módulo LORA',
          //   checked: userData.userMapFieldsAvailable[0]['loraModuleId'],
          // },
        ];
      } catch (e) {
        __fields = [
          {
            _id: 'equipment',
            label: 'Equipamento',
            checked: true,
          },
          {
            _id: 'plate',
            label: 'Placa / LP',
            checked: true,
          },
          {
            _id: 'type',
            label: 'Tipo',
            checked: true,
          },
          {
            _id: 'communicationType',
            label: 'Comunicação',
            checked: true,
          },
          {
            _id: 'company',
            label: 'Empresa',
            checked: true,
          },
          {
            _id: 'firmwareVersion',
            label: 'Versão do Firmware',
            checked: true,
          },
          {
            _id: 'battery',
            label: 'Bateria',
            checked: true,
          },
          {
            _id: 'expiration',
            label: 'Expiração',
            checked: true,
          },
          {
            _id: 'position',
            label: 'Posição',
            checked: true,
          },
          {
            _id: 'signal',
            label: 'Sinal',
            checked: true,
          },
          {
            _id: 'datetime',
            label: 'Data e Hora',
            checked: true,
          },
          {
            _id: 'rf',
            label: 'RF',
            checked: true,
          },
          {
            _id: 'fpp',
            label: 'FPP',
            checked: true,
          },
          {
            _id: 'local',
            label: 'Local',
            checked: true,
          },
          {
            _id: 'latlong',
            label: 'Lat/Long',
            checked: true,
          },
          {
            _id: 'status',
            label: 'Status',
            checked: true,
          },
        ];
      }
    } else {
      __fields = [
        {
          _id: 'equipment',
          label: 'Equipamento',
          checked: true,
        },
        {
          _id: 'plate',
          label: 'Placa / LP',
          checked: true,
        },
        {
          _id: 'type',
          label: 'Tipo',
          checked: true,
        },
        {
          _id: 'communicationType',
          label: 'Comunicação',
          checked: true,
        },
        {
          _id: 'company',
          label: 'Empresa',
          checked: true,
        },
        {
          _id: 'firmwareVersion',
          label: 'Versão do Firmware',
          checked: true,
        },
        {
          _id: 'battery',
          label: 'Bateria',
          checked: true,
        },
        {
          _id: 'expiration',
          label: 'Expiração',
          checked: true,
        },
        {
          _id: 'position',
          label: 'Posição',
          checked: true,
        },
        {
          _id: 'signal',
          label: 'Sinal',
          checked: true,
        },
        {
          _id: 'datetime',
          label: 'Data e Hora',
          checked: true,
        },
        {
          _id: 'rf',
          label: 'RF',
          checked: true,
        },
        {
          _id: 'fpp',
          label: 'FPP',
          checked: true,
        },
        {
          _id: 'local',
          label: 'Local',
          checked: true,
        },
        {
          _id: 'latlong',
          label: 'Lat/Long',
          checked: true,
        },
        {
          _id: 'status',
          label: 'Status',
          checked: true,
        },
      ];
    }

    setUserMapFieldsAvailable(__fields);
  };

  const onClickEditList = async () => {
    setModalMapFieldsAvailable(true);
  };

  const handleChangeColumns = async () => {
    const userData = await fetchAuthenticated(
      'get',
      `user/${JSON.parse(localStorage.getItem('user'))._id}`
    );
    if (userData.ok) {
      userData.json().then(__userData => {
        setUserData(__userData);

        setUserFieldsList(__userData);
      });
    }

    setColumns();
  };

  return (
    <div className={classes.root}>
      <ModalSetUserMapFieldsAvailable
        isVisible={modalMapFieldsAvailable}
        setIsVisible={setModalMapFieldsAvailable}
        userData={userData}
        currentProfileFieldsAvailableData={userMapFieldsAvailable}
        setCurrentProfileFieldsAvailableData={setUserMapFieldsAvailable}
        handleSave={handleChangeColumns}
      />
      <Filter
        searchPlaceHolder="Buscar (cidade, estado, equipamento, ponto de referência...)"
        path={path}
        query={query}
        setQuery={setQuery}
        containerStyle={{ paddingLeft: 0 }}
        asyncFields={filterAsyncFields}
        inputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.searchIcon}>
              <Search />
            </InputAdornment>
          ),
        }}
        showPlaceholder={true}
        hideLabel={true}
        genericSearchFields={genericSearchFields}
        defaultChips={chips}
      />
      <Map
        height={320}
        containerStyle={{}}
        equipments={data}
        centerMapOn={centerMapOn}
        // showAllFences={true}
        showCurrentEquipmentFences={true}
      />
      <SimpleTable
        columns={columns}
        path={path}
        data={data}
        setQuery={setQuery}
        query={query}
        containerStyle={{ paddingLeft: 0, paddingRight: 0 }}
        onRowClick={(row, col) => {
          setCenterMapOn(row);
        }}
        HeaderComponent={
          <TableHeader
            headerActions={[
              <TableHeaderAction
                title="Exportar XLS"
                IconProps={{ style: theme.custom.icon }}
                Icon={props => <Typography {...props}>XLS</Typography>}
                disabled={data && data.totalItems === 0}
                onClick={() => handleExportXLS(query)}
              />,
              <TableHeaderAction
                title="Editar Visualização"
                Icon={List}
                // disabled={checkedList.length === 0 || filter !== 'received'}
                onClick={() => onClickEditList()}
              />,
            ]}
          />
        }
        popover={Popover}
        isPopoverVisible={row =>
          row.lastObservation && !row.lastObservation.archived
        }
      />
    </div>
  );
}

const styles = theme => ({
  root: {
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  mapClass: {
    marginTop: 50,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  },
  chargingBattery: {
    display: 'flex',
    alignItems: 'center',
  },
});
export default withRouter(withStyles(styles)(Mapa));
