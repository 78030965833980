/* eslint-disable no-mixed-operators */
import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { conformToMask } from 'react-text-mask';
import {
  PLATE_MODEL_1,
  PLATE_MODEL_2,
} from 'components/fields/VehiclePlate.field';

import Map from '../components/map/Map';

import { emitEvent } from 'utils/events';
import {
  getField,
  getPositionIndicator,
  getStatus,
  getBatteryStatus,
  boolToString,
  isRfOn,
  minutesToTimeString,
  formatTemperature,
} from 'utils/helpers';
import {
  percentageFormatter,
  datetimeFormatter,
  poffFormatter,
  imeiFormatter
} from 'utils/formatters';
import { EMERGENCY_LABELS, getEmergencyLabel } from 'utils/labels';
import { useFetch, useAuthenticated } from '../hooks/fetch';
import { fetchAuthenticated, get } from 'services/fetch';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import AnchorIcon from '../assets/icon/anchor1.png';
import AnchorWhiteIcon from '../assets/icon/anchor2.png';
import FenceIcon from '../assets/icon/fence.png';
import FenceWhiteIcon from '../assets/icon/fence_white.png';
import FollowIcon from '../assets/icon/follow.png';
import FollowWhiteIcon from '../assets/icon/follow_white.png';
import CommandIcon from '../assets/icon/admin_command.png';
import LocationIcon from '../assets/icon/location.png';
import LocationWhiteIcon from '../assets/icon/location_white.png';
import HistoryIcon from '../assets/icon/history.png';
import ObsIcon from '../assets/icon/obs.png';
import ReportIcon from '../assets/icon/report.svg';
import ModalObs from 'components/modals/ModalObs';
import ModalVehicle from 'components/modals/ModalVehicle';
import ModalFollow from 'components/modals/ModalFollow';
import ModalFence from 'components/modals/ModalFence';
import ModalEquipmentHistory from 'components/modals/ModalEquipmentHistory';
import ModalBeacon from 'components/modals/ModalBeacon';
import Permission from 'components/permissions/Permission';
import { CommandGprs } from '../components/commands/Commands';
import CircularLoading from 'components/loading/CircularLoading';
import SubBar from 'components/SubBar';
import { getBreadcrumbsFor } from 'utils/breadcrumbs';
import { useAccessLog } from 'hooks/logs';
import HomeFilterContext from 'pages/HomeFilterContext';

import BatteryIcon from '../assets/icon/battery.svg';


import { Hidden } from '@material-ui/core';

const styles = theme => ({
  root: {
    position: 'relative',
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    paddingTop: theme.main.padding.top,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    fontSize: theme.text.header.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  headerIcon: {
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.background.white,
    width: 40,
    height: 40,
    padding: 0,
    marginRight: 10,
    boxShadow: '0 2px 12px 3px rgba(0, 0, 0, 0.08)',
  },
  activeHeaderIcon: {
    borderRadius: 14,
    color: theme.palette.gray_1,
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
    padding: 0,
    marginRight: 10,
    boxShadow: '0 2px 12px 3px rgba(0, 0, 0, 0.08)',
  },
  mapGrid: {
    marginTop: theme.main.padding.top,
  },
  cardHeader: {
    paddingLeft: 27,
    paddingRight: 27,
    paddingBottom: 0,
  },
  cardContent: {
    paddingLeft: 27,
    paddingTop: 0,
    display: 'inline-grid',
  },
  cardHeaderTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  cardLineContainer: {
    display: 'inline-flex',
    marginTop: 10,
  },
  cardFieldLabel: {
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_5,
  },
  cardField: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.gray.gray_4,
  },
  cardInfo: {
    borderRadius: theme.border.radius,
    boxShadow: '0 4px 10px 7px rgba(0, 0, 0, 0.03)',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  cardHighlighted: {
    borderTopLeftRadius: theme.border.radius,
    borderTopRightRadius: theme.border.radius,
    width: '100%',
    height: 42,
    display: 'flex',
    alignItems: 'center',
  },
  chargingBattery: {
    display: 'flex',
    alignItems: 'center',
  },
});

const requestEseyeAndCellIDLocation = async (
  equipment,
  setIsLoading,
  setEquipment
) => {
  let response = {};

  const communicationType = equipment.lastEvent.communicationType ? equipment.lastEvent.communicationType : '';
  const positionIndicator = equipment.lastEvent.positionIndicator[0] ? equipment.lastEvent.positionIndicator[0] : '';

  if (communicationType === '4G' && positionIndicator === 'GPS') {
    response = await (
      await fetchAuthenticated('post', 'api/last-xglobal-location', {
        equipment: {
          lastEvent: equipment.lastEvent,
          _id: equipment._id,
        }
      })
    ).json();
    if (!response.statusCode) {
      if (!('lastXglobalEvent' in response)) {
        emitEvent('showSnack', {
          message: 'Posição XGlobal indisponível',
          type: 'error',
        });
      } else {
        emitEvent('showSnack', {
          message: 'Posição XGlobal solicitada.',
          type: 'success',
        });

        setEquipment(response);
      }
    } else {
      emitEvent('showSnack', {
        message: 'Posição XGlobal indisponível',
        type: 'error',
      });
    }
  } else {
    response = await (
      await fetchAuthenticated('post', 'api/xglobal-locations', {
        equipment: {
          lastEvent: equipment.lastEvent,
          _id: equipment._id,
        },
        iccid: equipment.iccid,
      })
    ).json();
    if (!response.statusCode) {
      emitEvent('showSnack', {
        message: 'Posição XGlobal 2 solicitada.',
        type: 'success',
      });

      setEquipment(response);
    } else {
      emitEvent('showSnack', {
        message: 'Posição XGlobal 2 indisponível',
        type: 'error',
      });
    }
  }
  setIsLoading(false);
};

const requestLORALocation = async (equipment, setIsLoading, setEquipment) => {
  const response = await (
    await fetchAuthenticated('post', 'api/lora-locations', {
      equipment: {
        lastEvent: equipment.lastEvent,
        _id: equipment._id,
      },
      iccid: equipment.iccid,
    })
  ).json();
  if (!response.statusCode) {
    emitEvent('showSnack', {
      message: 'Posição Lora solicitada.',
      type: 'success',
    });
    setEquipment(response);
  } else {
    emitEvent('showSnack', {
      message: 'Posição Lora indisponível',
      type: 'error',
    });
  }
  setIsLoading(false);
};

const requestLBSPlusLocation = async (equipment, setIsLoading, setEquipment) => {
  const response = await (
    await fetchAuthenticated('post', 'api/lbsplus-locations', {
      equipment: {
        lastEvent: equipment.lastEvent,
        _id: equipment._id,
      },
      iccid: equipment.iccid,
    })
  ).json();
  if (!response.statusCode) {
    emitEvent('showSnack', {
      message: 'Posição LBS+ solicitada.',
      type: 'success',
    });
    setEquipment(response);
  } else {
    emitEvent('showSnack', {
      message: 'Posição LBS+ indisponível',
      type: 'error',
    });
  }
  setIsLoading(false);
};

function Header(props) {
  const {
    classes,
    equipment,
    setEquipment,
    showXglobal,
    setShowXglobal,
    setCurrentEvent,
    OpenHistory,
    renderHeader
  } = props;

  const [isObsVisible, setIsObsVisible] = useState(false);
  const [isCommandGprsVisible, setIsCommandGprsVisible] = useState(false);
  const [isFollowVisible, setIsFollowVisible] = useState(false);
  const [isFenceVisible, setIsFenceVisible] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isEquipmentHistoryVisible, setIsEquipmentHistoryVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFence, setHasFence] = useState(false);

  const [isAnchorVisible, setIsAnchorVisible] = useState(false);
  const [isAnchored, setIsAnchored] = useState(false);

  const icons = [
    {
      permissions: ['ViewFence', 'ViewAllFences'],
      onClick: () => {
        setIsFenceVisible(!isFenceVisible);
      },
      icon: hasFence ? FenceWhiteIcon : FenceIcon,
      tooltip: 'Cercas',
      active: hasFence,
      imgStyle: { width: 20 },
    },
    {
      permissions: ['ViewEquipmentBeacon'],
      onClick: () => {
        setIsAnchorVisible(!isAnchorVisible);
      },
      icon: isAnchored ? AnchorIcon : AnchorWhiteIcon,
      tooltip: 'Ancoragem',
      active: isAnchored,
      imgStyle: { width: 20 },
    },
    {
      onClick: () => {
        setIsFollowVisible(!isFollowVisible);
      },
      icon: (isFollowed && FollowWhiteIcon) || FollowIcon,
      tooltip: 'Follow',
      active: isFollowed,
      imgStyle: { width: 20 },
    },
    {
      permissions: ['SendGPRSCommand'],
      onClick: () => {
        setIsCommandGprsVisible(!isCommandGprsVisible);
      },
      icon: CommandIcon,
      tooltip: 'Comandos GPRS',
      imgStyle: { width: 15 },
    },
    {
      onClick: async () => {

        if (!showXglobal) {
          setIsLoading(true);
          await requestEseyeAndCellIDLocation(equipment, setIsLoading, setEquipment);

          setIsLoading(true);
          await requestLORALocation(equipment, setIsLoading, setEquipment);

          setIsLoading(true);
          await requestLBSPlusLocation(equipment, setIsLoading, setEquipment);

        } else {
          setCurrentEvent(equipment.lastEvent);
        }
        setShowXglobal(!showXglobal);
      },
      icon: (showXglobal && LocationWhiteIcon) || LocationIcon,
      active: showXglobal,
      tooltip: `Posição ${window.location.href.includes(process.env.REACT_APP_SECONDARY_URL) ? 'CIM' : 'XGlobal'}`,
      imgStyle: { width: 15 },
    },
    {
      permissions: ['EditEquipmentObservations'],
      onClick: () => {
        setIsObsVisible(true);
      },
      icon: ObsIcon,
      tooltip: 'Observações',
      imgStyle: { width: 20 },
    },
    {
      permissions: ['ViewEquipmentHistory', 'ViewAllEquipmentHistory'],
      onClick: () => {
        setIsEquipmentHistoryVisible(true);
      },
      icon: HistoryIcon,
      noMargin: true,
      tooltip: 'Histórico',
      imgStyle: { width: 20 },
    },
  ];

  useEffect(() => {
    const fn = async () => {

      if (equipment) {

        setIsLoading(true);
        await requestEseyeAndCellIDLocation(equipment, setIsLoading, setEquipment);

        setIsLoading(true);
        await requestLORALocation(equipment, setIsLoading, setEquipment);

        setIsLoading(true);
        await requestLBSPlusLocation(equipment, setIsLoading, setEquipment);

        setShowXglobal(true);
      }
    };

    fn();
  }, [renderHeader])

  return (
    <div>
      <IconButton
        title="Relatório"
        className={classes.activeHeaderIcon}
        style={{ marginRight: 50, width: 135, backgroundColor: '#ffffff' }}
        onClick={() => {
          OpenHistory(equipment);
        }}
      >
        <img src={ReportIcon} style={{ width: 20 }} alt="Relatório" />
        <Typography
          style={{
            fontSize: 17,
            fontWeight: 'bold',
            color: '#548cc1',
            marginLeft: 10,
          }}
        >
          Relatório
        </Typography>
      </IconButton>
      {icons.map((icon, index) => (
        <Permission key={index} names={icon.permissions}>
          <IconButton
            title={icon.tooltip}
            className={
              (icon.active && classes.activeHeaderIcon) || classes.headerIcon
            }
            style={(icon.noMargin && { marginRight: 0 }) || {}}
            onClick={icon.onClick}
          >
            <img
              src={icon.icon}
              style={icon.imgStyle || {}}
              alt={icon.tooltip}
            />
          </IconButton>
        </Permission>
      ))}
      <ModalFence
        setEquipment={setEquipment}
        equipment={equipment}
        isVisible={isFenceVisible}
        setIsVisible={setIsFenceVisible}
        setHasFence={setHasFence}
      />
      <ModalBeacon
        equipment={equipment}
        setIsVisible={setIsAnchorVisible}
        isVisible={isAnchorVisible}
        setIsAnchored={setIsAnchored}
      />
      <ModalObs
        equipment={equipment}
        isVisible={isObsVisible}
        setIsVisible={setIsObsVisible}
      />
      <ModalFollow
        equipment={equipment}
        setIconActive={setIsFollowed}
        isVisible={isFollowVisible}
        setIsVisible={setIsFollowVisible}
      />
      <CommandGprs
        showStatus={true}
        isVisible={isCommandGprsVisible}
        setIsVisible={setIsCommandGprsVisible}
        lastEvent={equipment.lastEvent}
      />
      <ModalEquipmentHistory
        setEquipment={setEquipment}
        equipment={equipment}
        isVisible={isEquipmentHistoryVisible}
        setIsVisible={setIsEquipmentHistoryVisible}
      />
      <CircularLoading isLoading={isLoading} />
    </div>
  );
}

function CardField(props) {
  const {
    classes, label, value, icon
  } = props;
  const fieldStyle = props.fieldStyle || {};
  if (props.marginLeft) {
    fieldStyle.marginLeft = 45;
  }
  return (
    <div style={fieldStyle}>
      <Typography className={classes.cardFieldLabel}>{label}</Typography>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography className={classes.cardField}>{value}</Typography>
        {icon && (
          <IconButton
            style={{
              width: 20,
              height: 20,
              boxShadow: 'unset',
              marginLeft: 10,
            }}
            title={icon.title}
            className={classes.headerIcon}
            onClick={icon.onClick}
          >
            <img src={icon.icon} style={{ width: 16, height: 16 }} />
          </IconButton>
        )}
      </div>
    </div>
  );
}

function SummaryCard(props) {
  const {
    classes, equipment, currentEvent, setRefresh, isSafeloggy
  } = props;
  const cardStyle = props.cardStyle || {};

  const [openVehicleEdit, setOpenVehicleEdit] = useState(false);

  const highlightColors = {
    XGlobal: '#548cc1',
    Google: '#673E9A',
    CellID: '#8E96B0',
    GPS: '#41475a',
    'XGlobal 2': '#37B0C9',
    Safeloggy: '#548cc1',
    LORA: '#07A39F',
    'LBS+': '#A330CC',
  };

  return (
    <Card className={classes.cardInfo} style={cardStyle}>

      <div
        className={classes.cardHighlighted}
        style={{
          backgroundColor: highlightColors[getPositionIndicator(currentEvent)],
        }}
      >
        <Typography
          style={{
            fontSize: 16,
            color: 'white',
            fontWeight: '600',
            paddingLeft: 27,
          }}
        >
          {/* {getPositionIndicator(currentEvent)} */}
          {getPositionIndicator(currentEvent) === 'XGlobal' ? window.location.href.includes(process.env.REACT_APP_SECONDARY_URL) ? 'CIM' : 'XGlobal' : getPositionIndicator(currentEvent)}
          {/* `Posição ${window.location.href.includes(process.env.REACT_APP_SECONDARY_URL) ? 'CIM' : 'XGlobal'}` */}
        </Typography>
      </div>
      <ModalVehicle
        equipment={equipment}
        isVisible={openVehicleEdit}
        setIsVisible={setOpenVehicleEdit}
        onSubmit={() => {
          setRefresh(true);
        }}
      />
      <CardHeader
        title={`Equipamento: ${(equipment && equipment.shortImei) || ''}`}
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.cardLineContainer}>
          <CardField
            classes={classes}
            label="EMPRESA"
            value={getField(equipment, 'currentCompany.name')}
          />
          <CardField
            classes={classes}
            label="PLACA / LP"
            // value={
            //   equipment.vehicle && equipment.vehicle[4] !== undefined
            //     ? equipment.vehicle[4].match(/[a-z]/i)
            //       ? conformToMask(equipment.vehicle, PLATE_MODEL_2)
            //           .conformedValue
            //       : conformToMask(equipment.vehicle, PLATE_MODEL_1)
            //           .conformedValue
            //     : ''
            // }
            value={equipment.vehicle}
            fieldStyle={{ marginLeft: 45 }}
            icon={{
              icon: ObsIcon,
              title: 'Editar veículo',
              onClick: () => {
                setOpenVehicleEdit(true);
              },
            }}
          />
        </div>
        <div className={classes.cardLineContainer}>
          <CardField
            classes={classes}
            label="DATA"
            value={datetimeFormatter(currentEvent.timestamp)}
          />
          {!isSafeloggy && (
            <CardField
              classes={classes}
              label="NÍVEL BATERIA"
              value={percentageFormatter(
                getField(
                  currentEvent,
                  currentEvent.adjustedInternalBatteryPercentage
                    ? 'adjustedInternalBatteryPercentage'
                    : 'internalBatteryPercentage',
                  '-'
                ) / 100,
                'Indisponível'
              )}
              fieldStyle={{ marginLeft: 45 }}
            />
          )}
          {!isSafeloggy && (
            <CardField
              classes={classes}
              label="RF"
              value={isRfOn(currentEvent)}
              fieldStyle={{ marginLeft: 45 }}
            />
          )}
        </div>
        <CardField
          classes={classes}
          label="LOCAL"
          value={
            'isInsideReferencePoint' in currentEvent &&
              currentEvent.isInsideReferencePoint
              ? currentEvent.referencePointLocal
              : currentEvent.local
          }
          fieldStyle={{ marginTop: 10 }}
        />
        <div className={classes.cardLineContainer}>
          {!isSafeloggy && (
            <CardField
              classes={classes}
              label="TEMPO DE POSIÇÃO FPP"
              value={minutesToTimeString(
                currentEvent.positionSendInterval,
                'Indisponível'
              )}
            />
          )}
          <CardField
            classes={classes}
            label="TIPO COORDENADA"
            value={getPositionIndicator(currentEvent) === 'XGlobal' ? window.location.href.includes(process.env.REACT_APP_SECONDARY_URL) ? 'CIM' : 'XGlobal' : getPositionIndicator(currentEvent)}
            fieldStyle={{ marginLeft: 45 }}
          />
        </div>
        <div className={classes.cardLineContainer}>
          <CardField
            classes={classes}
            label="STATUS"
            value={getStatus(equipment, true)}
          />
          { }
          {/* {equipment.currentHandlingStatus === 'emergency' && ( */}
          {(equipment.handlingStatus === 'emergency' ||
            equipment.handlingStatus === 'BEACON' ||
            equipment.handlingStatus === 'RF_ACTIVE' ||
            equipment.handlingStatus === 'suspect') &&
            (equipment.situation !== 'STABLE') &&
            (equipment.currentHandlingStatus !== 'active') &&
            (
              <CardField
                classes={classes}
                label="EMERGÊNCIA"
                value={getEmergencyLabel(equipment) || ''}
                fieldStyle={{ marginLeft: 30 }}
              />
            )
          }
        </div>
        <div className={classes.cardLineContainer}>
          <CardField
            classes={classes}
            label="TIPO DE COMUNICAÇÃO"
            value={
              (equipment.lastEvent && equipment.lastEvent.communicationType) ||
              '-'
            }
          />
        </div>
      </CardContent>
    </Card>
  );
}

function InfoCard(props) {
  const {
    classes, equipment, currentEvent, isSafeloggy
  } = props;
  const cardStyle = props.cardStyle || {};

  const [userData, setUserData] = useState(null);

  const renderBatteryStatus = event => {
    const batteryStatus = getBatteryStatus(event);

    const chargingBatteryStatus = (
      <div className={classes.chargingBattery}>
        <span>Sim</span>
        <img
          src={BatteryIcon}
          style={{ marginLeft: '10px' }}
          alt="Carregando"
        />
      </div>
    );

    return batteryStatus === undefined
      ? 'Indisponível'
      : batteryStatus
        ? chargingBatteryStatus
        : 'Não';
  };

  useEffect(() => {
    const fn = async () => {
      const userData = await fetchAuthenticated('get', `user/${JSON.parse(localStorage.getItem('user'))._id}`);
      if (userData.ok) {
        userData.json().then(_userData => {
          setUserData(_userData);
        });
      }
    };
    setUserData(userData);
    fn();
  }, []);

  return (
    <Card className={classes.cardInfo} style={cardStyle}>
      <CardHeader
        title="Localização"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent style={{ paddingLeft: 27 }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="DATA"
              value={datetimeFormatter(currentEvent.timestamp)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="LOCAL"
              value={
                'isInsideReferencePoint' in currentEvent &&
                  currentEvent.isInsideReferencePoint
                  ? currentEvent.referencePointLocal
                  : currentEvent.local
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="LATITUDE"
              value={currentEvent.latitude}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="LONGITUDE"
              value={currentEvent.longitude}
            />
          </Grid>

          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="RF"
                value={isRfOn(currentEvent)}
              />
            </Grid>
          )}

          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="FREQ. 434Mhz"
                value={getField(
                  currentEvent,
                  'status.transmitter434Mhz',
                  value => boolToString(value, 'Ligado', 'Desligado', 'Indisponível')
                )}
              />
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="FREQ. 928Mhz"
                value={getField(
                  currentEvent,
                  'status.transmitter928Mhz',
                  value => boolToString(value, 'Ligado', 'Desligado', 'Indisponível')
                )}
              />
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={2}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="CURSO GPS"
                value={getField(currentEvent, 'gpsDirection', 'Indisponível')}
              />{' '}
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={2}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="QUANTIDADE SATÉLITE"
                value={getField(
                  currentEvent,
                  'gpsSatelliteNumber',
                  'Indisponível'
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={2} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="VELOCIDADE"
              value={getField(currentEvent, 'gpsSpeed', 'Indisponível')}
            />{' '}
          </Grid>
          <Grid item xs={12} sm={2} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="KM RODADO"
              value={getField(currentEvent, 'kmDistance', 'Indisponível')}
            />{' '}
          </Grid>
        </Grid>
      </CardContent>
      <CardHeader
        title="Status"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent style={{ paddingLeft: 27 }}>
        <Grid container>
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="NÍVEL BATERIA"
                value={percentageFormatter(
                  getField(
                    currentEvent,
                    currentEvent.adjustedInternalBatteryPercentage
                      ? 'adjustedInternalBatteryPercentage'
                      : 'internalBatteryPercentage',
                    '-'
                  ) / 100,
                  'Indisponível'
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="TENSÃO BATERIA"
              value={currentEvent.internalBatteryVoltage || 'Indisponível'}
            />
          </Grid>

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="T. BATERIA EXT."
                value={currentEvent.externalBatteryVoltage || 'Indisponível'}
              />
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="CARREGANDO"
                value={renderBatteryStatus(currentEvent)}
              />
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="TEMPERATURA"
                value={formatTemperature(currentEvent, 'temperature')}
              />
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="TEMPERATURA BAÚ"
                value={formatTemperature(
                  currentEvent,
                  'temperatureHumidity.temperature'
                )}
              />
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="UMIDADE BAÚ"
                value={getField(
                  currentEvent,
                  'temperatureHumidity.humidity',
                  'Indisponível'
                )}
              />
            </Grid>
          )}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="NÍVEL DE SINAL GSM"
                value={
                  `${(!isNaN(Math.round((parseInt(currentEvent.gsmModelSignal, 10) / 31) * 100))) ? `${Math.round((parseInt(currentEvent.gsmModelSignal, 10) / 31) * 100)}%` : 'Indisponível'}` || 'Indisponível'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="SOS JAMMER"
                value={currentEvent.status.isSOSJammer ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="SOS TAMPER"
                value={currentEvent.status.isSOSTamper ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="SOS MANUAL"
                value={currentEvent.status.isSOSManual ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="SOS VIOLAÇÃO"
                value={currentEvent.status.isSOSViolated ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="RASTREADOR"
                value={
                  currentEvent.status.isTrackerOn ? 'Ativado' : 'Desativado'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="IGNIÇÃO"
                value={
                  currentEvent.status.isIgnitionOn ? 'Ligada' : 'Desligada'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="VEÍCULO BLOQUEADO"
                value={currentEvent.status.isVehicleBlocked ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="SIM CARD"
                value={
                  currentEvent.status.isSimCardRemovedOrHacked
                    ? 'Removido ou Adulterado'
                    : 'Normal'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="BLOQUEIO TEMPORIZADO"
                value={currentEvent.status.isTimedBlocked ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="PRÉ-JAMMING DETECTADO"
                value={currentEvent.status.isPreJamming ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="PÂNICO PRESSIONADO"
                value={currentEvent.status.isPanicButtonPressed ? 'Sim' : 'Não'}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="FIRMWARE"
              value={currentEvent.firmwareVersion || 'Indisponível'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="STATUS"
              value={getStatus(equipment, true)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="EXPIRAÇÃO"
              value={poffFormatter(equipment)}
            />
          </Grid>
          {/* <CardField classes={classes} label="HORA ATIVAÇÃO" value={"-x-"} marginLeft={true}/> */}
        </Grid>
      </CardContent>
      <CardHeader
        title="Transmissão"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent style={{ paddingLeft: 27 }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="TIPO DE TRANSMISSÃO"
              value={getPositionIndicator(currentEvent) === 'XGlobal' ? window.location.href.includes(process.env.REACT_APP_SECONDARY_URL) ? 'CIM' : 'XGlobal' : getPositionIndicator(currentEvent)}
            />
          </Grid>

          {currentEvent.communicationType && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="TIPO DE COMUNICAÇÃO"
                value={currentEvent.communicationType}
              />
            </Grid>
          )}
          {/* <CardField classes={classes} label="TIPO LBS" value={currentEvent.positionIndicator[1]} marginLeft={true}/> */}
          {/* <CardField classes={classes} label="TIPO DE COORDENADA" value="-x-" marginLeft={true}/> */}
          {!isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="TEMPO DE POSIÇÃO"
                value={minutesToTimeString(
                  currentEvent.positionSendInterval,
                  'Indisponível'
                )}
              />
            </Grid>
          )}

          {/* (userData.profiles.some(x => x.profile === "5d8e6845957607437679e11ddd")) */}

          {(equipment.lastLoraModuleId && userData && (userData.profiles.some(x => x._id === "5d8e6845957607437679e11d"))) && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                classes={classes}
                label="ID MÓDULO LORA"
                value={equipment.lastLoraModuleId === "" ? "Indisponível" : equipment.lastLoraModuleId}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                label="FPP SOS"
                classes={classes}
                value={
                  equipment &&
                    equipment.repository &&
                    equipment.repository.positionSendIntervalSOS
                    ? `${equipment.repository.positionSendIntervalSOS} segundos`
                    : 'Indisponível'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                label="FPP NORMAL"
                classes={classes}
                value={
                  equipment &&
                    equipment.repository &&
                    equipment.repository.positionSendInterval
                    ? `${equipment.repository.positionSendInterval} segundos`
                    : 'Indisponível'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                label="FPP SLEEP"
                classes={classes}
                value={
                  equipment &&
                    equipment.repository &&
                    equipment.repository.positionSendIntervalSleep
                    ? `${equipment.repository.positionSendIntervalSleep} minutos`
                    : 'Indisponível'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                label="MODO SLEEP APÓS"
                classes={classes}
                value={
                  equipment &&
                    equipment.repository &&
                    equipment.repository.timeToStartSleepMode
                    ? `${equipment.repository.timeToStartSleepMode} minutos`
                    : 'Indisponível'
                }
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={2}
              style={{ marginBottom: 15 }}
            >
              <CardField
                label="DESBL. AUTOM. JAMMER"
                classes={classes}
                value={
                  equipment &&
                    equipment.repository &&
                    equipment.repository.timeToAutomaticUnblockAfterJammer
                    ? `${equipment.repository.timeToAutomaticUnblockAfterJammer} minutos`
                    : 'Indisponível'
                }
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardHeader
        title="Informações técnicas"
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent style={{ paddingLeft: 27 }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="CÓDIGO"
              value={equipment.code}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="OPERADORA"
              value={currentEvent.cellIdMncName || ''}
            />
          </Grid>

          {/* <CardField classes={classes} label="DESCRIÇÃO" value={""} marginLeft={true}/> */}

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="TIPO"
              value={getField(equipment, 'baitType.name')}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="MODELO"
              value={getField(equipment, 'model.name')}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="LINHA"
              value={equipment.lineNumber}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField classes={classes} label="IMEI" value={equipment.imei} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="ICCID"
              value={equipment.iccid}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
            <CardField
              classes={classes}
              label="CHIP"
              value={getField(equipment, 'mobileOperator.name')}
            />
          </Grid>

          {isSafeloggy && (
            <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
              <CardField
                classes={classes}
                label="NÚMERO DE SÉRIE"
                value={equipment.seriesNumber}
              />
            </Grid>
          )}

          {isSafeloggy && (
            <Grid item xs={12} sm={6} md={3} lg={2} style={{ marginBottom: 15 }}>
              <CardField
                classes={classes}
                label="FIRMWARE"
                value={
                  equipment &&
                    equipment.repository &&
                    equipment.repository.firmwareVersion
                    ? `${equipment.repository.firmwareVersion}`
                    : 'Indisponível'
                }
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

const BREADCRUMBS_MAP = {
  '/mapa': [
    {
      path: '/mapa',
      label: 'Mapa',
    },
  ],
  '/dashboard/estaveis': [
    {
      path: '/dashboard',
      label: 'Dashboard',
    },
    {
      path: '/dashboard/estaveis',
      label: 'Equipamentos estáveis',
    },
  ],
  '/dashboard/bateria-baixa': [
    {
      path: '/dashboard',
      label: 'Dashboard',
    },
    {
      path: '/dashboard/bateria-baixa',
      label: 'Equipamentos com bateria baixa',
    },
  ],
  '/dashboard/sem-posicionar': [
    {
      path: '/dashboard',
      label: 'Dashboard',
    },
    {
      path: '/dashboard/sem-posicionar',
      label: 'Equipamentos sem posicionar',
    },
  ],
  '/dashboard/posicoes-atrasadas': [
    {
      path: '/dashboard',
      label: 'Dashboard',
    },
    {
      path: '/dashboard/posicoes-atrasadas',
      label: 'Equipamentos posições atrasadas ',
    },
  ],
};

function EquipmentDetail(props) {
  useAccessLog('Acesso ao detalhe do equipamento');
  const {
    classes, location, match, history
  } = props;
  const [showXglobal, setShowXglobal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({});
  const [data, setData] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const fetchPath = `equipment/${match.params.id}`;
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [isSafeloggy, setIsSafeloggy] = useState(false);
  const [renderHeader, setRenderHeader] = useState(false);

  const homeFilterContext = useContext(HomeFilterContext);

  useEffect(() => {
    if (refresh) {
      const _fetch = async () => {
        const response = await fetchAuthenticated(get, fetchPath);
        setData(response);
        setRenderHeader(true);
      };
      _fetch();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const INTERVAL = 2 * 1000 * 60; // 2 minutes
    let RELOAD_INTERVAL_REFERENCE = null;

    const fn = async () => {
      RELOAD_INTERVAL_REFERENCE = setInterval(async () => {
        setRefresh(true);
      }, INTERVAL);
    };
    fn();

    return () => clearInterval(RELOAD_INTERVAL_REFERENCE);
  }, []);

  useEffect(() => {
    const from = (location.state && location.state.from) || {
      pathname: '/dashboard',
    };
    setBreadcrumbs([
      ...getBreadcrumbsFor(from.pathname),
      { path: '', label: data.shortImei || '' },
    ]);
  }, [location, data]);

  useEffect(() => {
    if (data) {
      setCurrentEvent(data.lastEvent);
      setIsSafeloggy(data.equipmentType.name === 'Bloqueador');
    }
  }, [data]);

  const onEventClick = equipmentEvent => {
    setCurrentEvent(equipmentEvent);
  };

  const OpenHistory = equipment => {
    homeFilterContext.updateEquipment(equipment);
    homeFilterContext.updateCompany(equipment.currentCompany);
    history.push('/relatorios/historico');
  };

  const mapHeight = 360;

  return (
    <div>
      <SubBar breadcrumbs={breadcrumbs} items={[]} match={props.match} />
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className={classes.headerText}>Monitoramento</Typography>
          <Header
            setEquipment={setData}
            showXglobal={showXglobal}
            setShowXglobal={setShowXglobal}
            equipment={data}
            classes={classes}
            setCurrentEvent={setCurrentEvent}
            OpenHistory={OpenHistory}
            renderHeader={renderHeader}
          />
        </div>
        <Grid container className={classes.mapGrid}>
          <Grid item xs={3}>
            <SummaryCard
              equipment={data}
              currentEvent={currentEvent}
              classes={classes}
              cardStyle={{ height: mapHeight }}
              setRefresh={setRefresh}
              isSafeloggy={isSafeloggy}
            />
          </Grid>
          <Grid item xs={9}>
            <Map
              showXglobal={showXglobal}
              onEventClick={equipmentEvent => onEventClick(equipmentEvent)}
              currentEquipment={data}
              equipments={[data]}
              // disableExpand={true}
              disablePopup={true}
              height={mapHeight}
              containerStyle={{
                marginLeft: 15,
              }}
              showCurrentEquipmentFences={true}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.mapGrid}>
          <Grid item xs={12}>
            <InfoCard
              equipment={data}
              classes={classes}
              currentEvent={currentEvent}
              cardStyle={{ marginBottom: 16 }}
              isSafeloggy={isSafeloggy}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default withRouter(withStyles(styles)(EquipmentDetail));
