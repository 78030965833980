import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export function StatusBall(props) {
  const { status, containerStyle } = props;
  // const colorStatus = {
  //   active: '#5bf293',
  //   suspect: '#ffc500',
  //   emergency: '#e44a4a',
  //   RF_ACTIVE: '#e44a4a',
  // };

  let color = null;

  if(status === 1) {
    color = '#ffc500'
  } else {
    color = '#5bf293'
  }


  return (
    <div
      style={{
        height: 20,
        width: 20,
        marginRight: 5,
        marginTop: -0.5,
        backgroundColor: color,
        borderRadius: '50%',
        ...containerStyle,
      }}
    />
  )
}

StatusBall.defaultProps = {
  containerStyle: {},
}

export default function BadgeColumn(props) {
  const { status, edit } = props;
  // console.log(status);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'left' }}>
      {status === 1 ? 'Em produção' : 'Finalizado'} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<StatusBall status={status}/>
    </div>
  );
}

BadgeColumn.defaultProps = {
  status: 'emergency',
  edit: true,
};
