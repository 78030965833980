import React, { useState, useEffect, useRef, useReducer } from "react";

import L from "leaflet";
import "leaflet.motion/dist/leaflet.motion";

import InfoCardField from "components/map/InfoCardField";
import InfoCard from "components/map/InfoCard";

import { datetimeFormatter } from "utils/formatters";
import "components/map/Map.css";

import { addPointsToMap } from "utils/helpers";

const MAP_INFO = {
  tileLayer: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  attribution:
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
};

// fn: function called when button is clicked
// color: object with enabled and disabled background colors
// marginBottom: string with distance between previous control and this one
// iconSrc: object with enabled and disabled icons
// title:  string with control title
// isEnabled: boolean representing current control status
const _buildControl = (
  fn,
  enabledClass,
  marginBottom,
  iconSrc,
  title,
  isEnabled
) => {
  const Control = L.Control.extend({
    options: {
      position: "bottomleft"
    },

    onAdd(map) {
      const container = L.DomUtil.create(
        "div",
        "leaflet-bar leaflet-control leaflet-path-report-control"
      );
      container.style.marginBottom = marginBottom;

      const link = L.DomUtil.create(
        "a",
        isEnabled ? enabledClass : "",
        container
      );
      link.href = "#";
      link.title = title;
      link.style.padding = 0;
      link.style.paddingTop = 0;

      const icon = L.DomUtil.create("img", "", link);
      icon.setAttribute("src", isEnabled ? iconSrc.enabled : iconSrc.disabled);

      const stop = L.DomEvent.stopPropagation;

      L.DomEvent.on(link, "click", stop)
        .on(link, "mousedown", stop)
        .on(link, "dblclick", stop)
        .on(link, "click", L.DomEvent.preventDefault)
        .on(
          link,
          "click",
          function(event) {
            if (enabledClass) {
              if (L.DomUtil.hasClass(link, enabledClass)) {
                icon.setAttribute("src", iconSrc.disabled);
                L.DomUtil.removeClass(link, enabledClass);
              } else {
                icon.setAttribute("src", iconSrc.enabled);
                L.DomUtil.addClass(link, enabledClass);
              }
            }
            fn();
          },
          this
        )
        .on(link, "click", this._refocusOnMap, this);

      return container;
    }
  });

  return new Control();
};

const pathLinesReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE":
      return !state;
    case "HIDE":
      return false;
    case "SHOW":
      return true;
    default:
      return state;
  }
};

function PathReportMap({ points }) {
  const map = useRef(null);
  const mapTiles = useRef(null);

  const markers = useRef([]);
  const motion = useRef(null);
  const linesControlRef = useRef(null);

  const [selectedCard, setSelectedCard] = useState(null);
  const [showPathLines, dispatchPathLines] = useReducer(pathLinesReducer, true);

  useEffect(() => {
    const _map = L.map("map", {
      zoom: 1,
      zoomControl: true
    }).setView([-14.3908906, -53.6396429], 4);

    _map.zoomControl.setPosition("bottomright");

    mapTiles.current = L.tileLayer(MAP_INFO.tileLayer, {
      attribution: MAP_INFO.attribution
    });
    mapTiles.current.addTo(_map);

    // Add replay button
    linesControlRef.current = _map.addControl(
      _buildControl(
        () => {
          dispatchPathLines({ type: "TOGGLE" });
        },
        "lines-enabled",
        "49px",
        {
          enabled: require("assets/icon/line_white.svg"),
          disabled: require("assets/icon/line.svg")
        },
        "Esconder linhas",
        showPathLines
      )
    );

    _map.addControl(
      _buildControl(
        () => {
          if (motion.current) {
            map.current.removeLayer(motion.current);
            motion.current.addTo(map.current);
          }
        },
        "",
        "10px",
        {
          enabled: require("assets/icon/replay.svg"),
          disabled: require("assets/icon/replay.svg")
        },
        "Replay",
        true
      )
    );

    map.current = _map;
  }, []);

  // Update map boundaries
  useEffect(() => {
    if (!points.length) {
      setSelectedCard(null);
      map.current.setView([-14.3908906, -53.6396429], 4);
      return;
    }

    // let filteredPoints = points.filter(p => String(p.latitude || '-').substring(0, 8) !== '-' && String(p.longitude || '-').substring(0, 8) !== '-')

    const filteredPoints = []

    for(const point of points) {
      if( (String(point.latitude || '-').substring(0, 2) !== '13' && String(point.longitude || '-').substring(0, 2) !== '13') && (String(point.latitude || '-').substring(0, 8) !== '-' && String(point.longitude || '-').substring(0, 8) !== '-') ) {
        filteredPoints.push(point)
      }
    }

    const coords = filteredPoints.map(p => [p.latitude, p.longitude]);
    const bounds = new L.LatLngBounds(coords);
    map.current.fitBounds(bounds.pad(0.5), { maxZoom: 15 });
  }, [points, map]);

  useEffect(() => {
    markers.current.forEach(m => {
      map.current.removeLayer(m);
    });

    if (!points.length) {
      return;
    }

    const filteredPoints = []

    for(const point of points) {
      if( (String(point.latitude || '-').substring(0, 2) !== '13' && String(point.longitude || '-').substring(0, 2) !== '13') && (String(point.latitude || '-').substring(0, 8) !== '-' && String(point.longitude || '-').substring(0, 8) !== '-') ) {
        filteredPoints.push(point)
      }
    }

    const _markers = addPointsToMap(map.current, filteredPoints, setSelectedCard);
    markers.current = _markers;
  }, [points, map, showPathLines]);

  // Remove old animation
  // Add cool animation
  useEffect(() => {

    if (motion.current) {
      map.current.removeLayer(motion.current);
    }

    if (!points.length) {
      return;
    }

    const truckIcon = L.icon({
      iconUrl: require("assets/icon/Truck_blue.png"),
      iconSize: [49, 41],
      iconAnchor: [24, 41]
    });

    // let filteredPoints = points.filter(p => String(p.latitude || '-').substring(0, 8) !== '-' && String(p.longitude || '-').substring(0, 8) !== '-')

    const filteredPoints = []

    for(const point of points) {
      if( (String(point.latitude || '-').substring(0, 2) !== '13' && String(point.longitude || '-').substring(0, 2) !== '13') && (String(point.latitude || '-').substring(0, 8) !== '-' && String(point.longitude || '-').substring(0, 8) !== '-') ) {
        filteredPoints.push(point)
      }
    }

    const coords = filteredPoints.map(p => [p.latitude, p.longitude]);
    motion.current = L.motion.polyline(
      coords,
      {
        color: "#41475A"
      },
      {
        auto: true,
        duration: 5000,
        easing: L.Motion.Ease.linear
      },
      {
        removeOnEnd: true,
        icon: L.divIcon({ html: "<span />", iconSize: { x: 0, y: 0 } })
      }
    );

    if (showPathLines) {
      motion.current.addTo(map.current);
    }
    // dispatchPathLines({ type: 'SHOW' });
  }, [points, map, showPathLines]);

  return (
    <>
      <div id="map" style={{ height: "100%", borderRadius: 22 }} />
      {selectedCard && (
        <InfoCard
          title="Detalhes"
          onCloseClick={() => setSelectedCard(null)}
          scrollable
        >
          {selectedCard.data.map(c => (
            <InfoCardField
              label=""
              value={datetimeFormatter(c.timestamp)}
              key={c.timestamp}
            />
          ))}
        </InfoCard>
      )}
    </>
  );
}

PathReportMap.propTypes = {};

PathReportMap.defaultProps = {
  points: []
};

export default PathReportMap;
