import { datetimeFormatter, defaultFormatter } from 'utils/formatters';

export const REPORT_COLUMNS = [
  {
    label: 'Data/Hora',
    value: ({ date }) => datetimeFormatter(date),
    sort: 'date',
  },
  {
    label: 'Equipamento',
    value: ({ equipment }) => defaultFormatter(equipment)
  },
  {
    label: 'Usuário',
    value: ({ user }) => defaultFormatter(user && user.username),
    sort: 'user.username'
  },
  {
    label: 'Ação',
    value: ({ description }) => defaultFormatter(description),
  },
  {
    label: 'IP',
    value: ({ ip }) => defaultFormatter(ip),
    sort: 'ip'
  }
]