import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import { TextField } from '@material-ui/core';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { Rule, validate } from '../../utils/formValidator';

export const PLATE_MODEL_1 = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const PLATE_MODEL_2 = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /\d/,
  /[a-zA-Z]/,
  /\d/,
  /\d/,
];

const PlateInput = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => inputRef(ref ? ref.inputElement : null)}
      mask={value => {
        // const newValue = value.replace(/\W/g, '').toUpperCase();
        const newValue = value;
        return newValue.length >= 4 && newValue[3] === '-'
          ? PLATE_MODEL_1
          : PLATE_MODEL_2;
      }}
      guide={false}
    />
  );
};

const VehiclePlateField = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  const [value, setValue] = useState('');

  const [validations, setValidations] = useState({
    rules: [
      Rule('isEmpty', [], false, 'Campo de preenchimento obrigatório'),
      Rule(
        'matches',
        [/(^$)|[a-zA-Z]{3}-\d{4}|[a-zA-Z]{3}\d[a-zA-Z]\d\d/],
        true,
        'Formato inválido para o campo'
      ),
    ],
    alreadyValidated: false,
    isInvalid: false,
    message: '',
  });

  const setFieldValue = value => {
    let mask;
    if (value[4].match(/[a-z]/i)) {
      mask = PLATE_MODEL_2;
    } else {
      mask = PLATE_MODEL_1;
    }
    setValue(conformToMask(value, mask).conformedValue);
  };

  const validateField = () => {
    const currentRules = props.required || value ? validations.rules : [];
    setValidations({
      rules: validations.rules,
      alreadyValidated: true,
      ...validate(value, currentRules),
    });
  };

  useEffect(() => {
    if (props.defaultValue) {
      setFieldValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const onChange = e => setValue(e.target.value);
  const onBlur = () => validateField();

  useImperativeHandle(ref, () => ({
    inputRef,
    value,
    raw: value.replace(/\W/g, '').toUpperCase(),
    isInvalid: validations.isInvalid,
    alreadyValidated: validations.alreadyValidated,
    validateField,
    setFieldValue,
  }));

  return (
    <TextField
      {...props}
      ref={inputRef}
      InputProps={{ inputComponent: PlateInput }}
      inputProps={{ style: { textTransform: 'uppercase' } }}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={validations.isInvalid}
      helperText={(validations.isInvalid && validations.message) || ''}
    />
  );
});

export default VehiclePlateField;
