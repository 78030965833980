import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, TextField, Grid } from '@material-ui/core';
import {
  get,
  fetchAuthenticated,
  putWithErrorHandler,
  postWithErrorHandler,
} from 'services/fetch';
import { emitEvent } from 'utils/events';
import Switch from '@material-ui/core/Switch';
import Modal from 'components/modals/Modal';
import CheckboxList from 'components/lists/CheckboxList';
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { logAction } from 'utils/logs';

const useStyles = makeStyles(theme => ({
  followContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 51,
    borderBottom: 'solid 1px #80849d4d',
  },
  followHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  followTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  contact: {
    borderBottom: 'solid 1px #80849d4d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
  },
  splitContact: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newContactDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcon: {
    width: 47,
  },
}));

const ModalRequirePermission = props => {
  const {
    isVisible,
    setIsVisible,
    userData,
    checkAll,
    currentProfileFieldsAvailableData,
    setCurrentProfileFieldsAvailableData,
  } = props;
  const classes = useStyles();

  const [showModal, setShowModal] = useState(isVisible);

  const [fieldsAvailable, setFieldsAvailable] = useState([{}]);

  useEffect(() => {
    console.log(currentProfileFieldsAvailableData);
    if (JSON.stringify(currentProfileFieldsAvailableData) !== '{}') {
      // const _fieldsAvailable = [];

      setFieldsAvailable(currentProfileFieldsAvailableData);
    }
  }, [currentProfileFieldsAvailableData]);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  const handleSubmit = async userData => {

    const _currentUserFieldsAvailableData = JSON.parse(
      JSON.stringify(fieldsAvailable)
    );

    const _userData = userData;

    const _userDataProfileMapFieldsAvailable = [];

    _userDataProfileMapFieldsAvailable.push({
      equipment: _currentUserFieldsAvailableData.find(
        x => x._id === 'equipment'
      ).checked,
      plate: _currentUserFieldsAvailableData.find(x => x._id === 'plate')
        .checked,
      type: _currentUserFieldsAvailableData.find(x => x._id === 'type').checked,
      communicationType: _currentUserFieldsAvailableData.find(
        x => x._id === 'communicationType'
      ).checked,
      company: _currentUserFieldsAvailableData.find(x => x._id === 'company')
        .checked,
      firmwareVersion: _currentUserFieldsAvailableData.find(
        x => x._id === 'firmwareVersion'
      ).checked,
      battery: _currentUserFieldsAvailableData.find(x => x._id === 'battery')
        .checked,
      expiration: _currentUserFieldsAvailableData.find(
        x => x._id === 'expiration'
      ).checked,
      position: _currentUserFieldsAvailableData.find(x => x._id === 'position')
        .checked,
      signal: _currentUserFieldsAvailableData.find(x => x._id === 'signal')
        .checked,
      datetime: _currentUserFieldsAvailableData.find(x => x._id === 'datetime')
        .checked,
      rf: _currentUserFieldsAvailableData.find(x => x._id === 'rf').checked,
      fpp: _currentUserFieldsAvailableData.find(x => x._id === 'fpp').checked,
      local: _currentUserFieldsAvailableData.find(x => x._id === 'local')
        .checked,
      latlong: _currentUserFieldsAvailableData.find(x => x._id === 'latlong')
        .checked,
      status: _currentUserFieldsAvailableData.find(x => x._id === 'status')
        .checked
      /* loraModuleId: _currentUserFieldsAvailableData.find(x => x._id === 'loraModuleId')
      .checked, */
    });

    const requestData = {
      userId: _userData._id,
      userMapFieldsAvailable: _userDataProfileMapFieldsAvailable,
    };

    const successMessage = 'Informações atualizadas com sucesso';
    const errorMessage = 'Erro ao tentar atualizar as informações';
    const logMessage = 'Atualizar informações visíveis ao perfil';
    const response = await postWithErrorHandler(
      'user/update-user-map-fields-available',
      requestData,
      {
        successMessage,
        errorMessage,
        logMessage,
      }
    );

    props.handleSave();

    setCurrentProfileFieldsAvailableData(_currentUserFieldsAvailableData);

    setIsVisible(false);
  };

  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  };

  return (
    <Modal
      handleSubmit={() => handleSubmit(props.userData)}
      onSuccessMessage="Informações atualizadas com sucesso"
      isVisible={showModal}
      setIsVisible={setShowModal}
      title={'Visualição de campos do mapa'}
      buttonLabel="Salvar"
      onClose={onClose}
      preventDefaultSubmit
    >
      <Typography
        className={classes.subsubheader}
        style={{ marginTop: '-20px', marginBottom: '30px' }}
      >
        Selecionar campos visíveis na lista:
      </Typography>
      <Grid item xs={12} className={classes.checkboxContainer}>
        {fieldsAvailable.length > 0 ? (
          <CheckboxList
            data={fieldsAvailable}
            setData={setFieldsAvailable}
            getName={item => item.label}
            labelStyle={{
              fontSize: 16,
              color: '#41475a',
              fontWeight: 'normal',
            }}
            checkAll={checkAll}
            containerStyle={{
              flexDirection: 'column',
              display: 'flex',
              flexWrap: 'wrap',
            }}
            showCheckAll={fieldsAvailable.length > 0}
          />
        ) : null}
      </Grid>
    </Modal>
  );
};

export default ModalRequirePermission;
