/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */
/* eslint-disable consistent-return */
import { getItem, removeItem } from "./local-storage";
import { emitEvent } from "utils/events";
import { logAction } from "utils/logs";

const URL = process.env.REACT_APP_SERVER_URL;
// const URL = 'http://localhost:3000/';
// const URL = "https://api.xglobal.com.br/";

function getAuthorization() {
  const user = getItem("user");
  if (!user) {
    return {};
  }

  return {
    Authorization: `Bearer ${user.accessToken}`,
  };
}

export async function get(path, filter, queryString = '?') {
  let getUrl = URL + path;

  if (filter) {
    getUrl += `${queryString}filter=${JSON.stringify(filter)}`;
  }


  const response = await fetch(getUrl, {
    method: "get",
    headers: {
      ...getAuthorization(),
    },
    mode: "cors",
  });

  /* when user is not logged in */

  if (response.status === 401) {
    removeItem("user");
    window.location.replace("/");
    return;
    /* when user doesnt have permission */
  }
  if (response.status === 403) {
    emitEvent("showSnack", {
      message: "Você não tem permissão",
      type: "error",
    });
    setTimeout(() => {
      window.location.replace("/");
    }, 2500);
    return;
  }

  if (!response.ok) {
    const error = await response.json();
    throw error;
  }

  return await response.json();
}

export async function post(path, data) {
  const url = URL + path;
  return await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...getAuthorization(),
    },
    body: JSON.stringify(data),
  });
}

async function handleResponse(response, messages) {
  const { successMessage, errorMessage, logMessage } = messages;
  if (!response.ok) {
    const error = await response.json();
    if (errorMessage) {
      emitEvent("showSnack", { message: errorMessage, type: "error" });
    }
    throw error;
  }
  if (logMessage) {
    logAction(logMessage);
  }
  if (successMessage) {
    emitEvent("showSnack", { message: successMessage, type: "success" });
  }

  return await response.json();
}

export async function postWithErrorHandler(path, data, messages = {}) {
  const url = URL + path;
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...getAuthorization(),
    },
    body: JSON.stringify(data),
  });

  return await handleResponse(response, messages);
}

export async function putWithErrorHandler(path, data, messages = {}) {
  const url = URL + path;
  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...getAuthorization(),
    },
    body: JSON.stringify(data),
  });

  return await handleResponse(response, messages);
}

export async function put(path, data) {
  const url = URL + path;
  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...getAuthorization(),
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    const error = await response.json();
    throw error;
  }
  return await response.json();
}

export async function remove(path) {
  const url = URL + path;
  return await fetch(url, {
    method: "DELETE",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...getAuthorization(),
    },
  });
}

export async function fetchAuthenticated(method, path, data, returnJson = false, headers = { Accept: "application/json", "Content-Type": "application/json" }) {
  if (headers["Content-Type"] === "application/json") {
    data = JSON.stringify(data);
  }

  let response;
  if (typeof method === "string") {
    const url = URL + path;
    response = await fetch(url, {
      method,
      mode: "cors",
      headers: { ...headers, ...getAuthorization() },
      body: data,
    });
  } else {
    response = await method(path, data);
  }

  const statusCode = response.status;
  if (statusCode === 401) {
    removeItem("user");
    window.location.replace("/");
    return;
  }

  if (statusCode === 403) {
    emitEvent("showSnack", {
      message: "Você não tem permissão",
      type: "error",
    });
    setTimeout(() => {
      window.location.replace("/");
    }, 2500);
    return;
  }

  if (returnJson) {
    return await response.json();
  }
  return response;
}
