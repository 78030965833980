// General imports
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

// Material-ui imports
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Grid,
  Paper,
  InputAdornment,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';

// Project imports
import { fetchAuthenticated } from 'services/fetch';
import { buildUrl, defaultQuery } from 'utils/query';
import { emitEvent } from 'utils/events';
import { useSimpleFilterContext } from 'components/filter/SimpleFilterProvider';

// Project components
import Filter from "components/filter/Filter";
import SimpleTable from 'components/table/Table';

import FilterHandler from 'utils/FilterHandler';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: theme.main.padding.top,
    paddingBottom: 25,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.gray.gray_4,
    fontSize: 22,
    lineHeight: 'normal',
  },
  paper: {
    borderRadius: theme.border.radius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  btnRoot: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    padding: '8px 18px',
    boxShadow: 'none',
    position: 'absolute',
    right: '25px'
  },
  btnRoot2: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    padding: '8px 18px',
    boxShadow: 'none',
    position: 'absolute',
    right: '175px'
    // float: 'right'
  },
  header: {
    marginBottom: theme.main.padding.bottom,
  },
  searchIcon: {
    color: theme.palette.gray.gray_5,
  }
}));

function BaseList (props) {
  const filterContext = useSimpleFilterContext();

  const classes = useStyles();
  const {
    location,
    match,
    history,
    containerStyle,
    hideFilters,
    genericSearchFields,
  } = props;

  const [ fetchPath, setFetchPath ] = useState(buildUrl(props.basePath, location));
  const [ query, setQuery ] = useState(defaultQuery);
  const [ data, setData ] = useState(null);

  const filterHandler = new FilterHandler(query, setQuery, location);

  const [chips, setChips] = useState((filterContext && filterContext.filterTerms) || []);

  // Load data
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress')
      const response = await fetchAuthenticated('get', fetchPath);
      if (response) {
        let data = await response.json();
        setData(data);
        emitEvent('hideGlobalLinearProgress')
      }
    }; fn();
  }, [fetchPath])

  // Update query string
  useEffect(() => {
    if (filterContext) {
      filterContext.updateFilter(query);
    }
    if (query.queryString) {
      setFetchPath(`${props.basePath}${query.queryString}`);
    }
  }, [query]);

  useEffect(() => {
    filterHandler.updateQueryFilter({
      searchText: chips,
      genericSearchFields: genericSearchFields.map(field => field.name),
    });
  }, [chips]);

  return (
    <div className={classes.root} style={{...containerStyle}}>
      <Grid container justify="space-between" alignItems="flex-start" className={classes.header}>
        {!hideFilters &&
        <Filter
          searchPlaceHolder="Buscar"
          path={props.basePath}
          query={query}
          setQuery={setQuery}
          containerStyle={{
            padding: 0,
            width: 254,
            display: 'flex',
            flexDirection: 'column',
          }}
          genericSearchFields={genericSearchFields}
          inputContainerStyle={{
            width: '100%',
            margin: 0,
          }}
          inputProps={{
            startAdornment: <InputAdornment position="start" className={classes.searchIcon}><Search /></InputAdornment>,
          }}
          hideButton={true}
          showPlaceholder={true}
          hideLabel={true}
          defaultChips={chips}
        />
        }
        {/* <div style={{float: 'right'}}> */}
          {props.addSecondButtonText && props.addSecondUrl &&
            <Button
                classes={{root: classes.btnRoot2}}
                size="small"
                color="primary"
                variant="contained"
                onClick={() => history.push(props.addSecondUrl)}
              >{props.addSecondButtonText}
            </Button>
          }
          {props.addButtonText && props.addUrl &&
            <Button
              classes={{root: classes.btnRoot}}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => history.push(props.addUrl)}
            >{props.addButtonText}</Button>
          }
        {/* </div> */}
      </Grid>
      <Paper elevation={0} className={classes.paper}>
        <SimpleTable
          columns={props.columns}
          data={data}
          query={query}
          setQuery={setQuery}
          onRowClick={(row, col) => {history.push(`${match.path}/${row._id}`)}}
          HeaderComponent={props.HeaderComponent}
        >
          <Grid container></Grid>
          <Typography>Test</Typography>
        </SimpleTable>

      </Paper>
    </div>
  );
}

BaseList.defaultProps = {
  containerStyle: {},
  genericSearchFields: [],
  hideFilters: false,
  filterContext: null,
}

export default withRouter(BaseList);
