import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { fetchAuthenticated, postWithErrorHandler, post } from 'services/fetch';
import { emitEvent } from 'utils/events';
import { logAction } from 'utils/logs';
import { buildLogString } from "utils/helpers";

import NewRfImportForm from 'components/forms/NewRfImportForm';
import ModalRemove from 'components/modals/ModalRemove';
import ModalConfirm from "components/modals/ModalConfirm";

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    height: "100%",
    mouseEvents: "none"
  }
});

export default function EditRf(props) {
  const classes = useStyles();
  const { match, history, setBreadcrumbs } = props;
  const [rfImport, setRfImport] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const [showEnableDisableDialog, setShowEnableDisableDialog,] = useState(false);

  const translateKeys = {
    status: 'Status',
    imei: 'IMEI',
    code: 'Código',
    originalImei: 'Original IMEI',
    iccid: 'ICCID',
    equipmentType: 'Tipo de Equipamento',
    protocolVersion: 'Protocolo',
    shortImei: 'IMEI Curto',
    currentCompany: 'Empresa',
    antiJamming: 'Anti-Jammer',
    baitType: 'Tipo de Isca',
    battery: 'Bateria',
    color: 'Cor',
    exemplary: 'Exemplar',
    expirationDate: 'Tempo para Expiração',
    lineNumber: 'Número da Linha',
    mobileOperator: 'Operadora',
    model: 'Modelo',
    packing: 'Embalagem',
    rfNumber: 'Número RF',
    vehicle: 'Veículo'
  }

  // Use base equipment url
  const URL = `rf/${match.params.id}`;

  // Set breadcrumbs
  useEffect(() => {
    console.log(rfImport)
    setBreadcrumbs([
      { path: '/cadastros/rf', label: 'Importação RF' },
      { path: '', label: `${rfImport.description}` }
    ]);
    return () => { setBreadcrumbs([]); }
  }, [setBreadcrumbs, match.params.id, rfImport]);

  // Load equipment info
  useEffect(() => {
    const fn = async () => {
      emitEvent('showGlobalLinearProgress');
      const response = await fetchAuthenticated('get', URL);

      // Any error will redirect to equipments list
      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível realizar esta ação', type: 'error' });
        history.push('/cadastros/rf');
      } else {
        const data = await response.json();

        setRfImport(data);
        // logAction(`Acesso ao cadastro do equipamento ${data.shortImei}`, data.imei)
        emitEvent('hideGlobalLinearProgress');
      }
    }; fn();
  }, [history, URL]);

  // Handle form submission
  const handleSubmit = async (data) => {
    const response = await fetchAuthenticated('put', URL, data);

    if (!response.ok) {
      throw new Error('')
    } else {
      const data = await response.json();
      setRfImport(data);
      // logAction(`Edição da importação de RF ${data.shortImei}${updatedItemsStr}`, __equipment.imei);
      emitEvent('showSnack', { message: 'Equipamento atualizado com sucesso', type: 'success' });
    }
  }

  // Handle cancel button click
  const handleCancel = () => {
    history.push('/cadastros/rf');
  }

  // Handle remove button click
  const handleRemove = () => {
    console.log('Handle remove');
    setShowDialog(true);
  }

  const handleDownload = async () => {
    console.log('Handle download!!');


    const successMessage = `Comando enviado com sucesso.`;
    const errorMessage = `Erro ao tentar enviar comando.`;
    const logMessage = `Conteúdo do arquivo baixado`;
    // const response = await postWithErrorHandler('equipment-event/command', {
    //   imei,
    //   pv1Fields: {
    //     DI,
    //     RC,
    //     RA,
    //     BeaconImei,
    //   },
    //   action: 'Admin',
    // }, {
    //   successMessage, errorMessage, logMessage
    // });


    const response = await post(`rf/download/${match.params.id}`, {})

    const rfImportFileName = rfImport.file.filename

    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    let randomString = Math.random().toString(5).slice(-5)
    link.setAttribute('download', rfImportFileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link); // Limpa o DOM
  }

  // Update show dialog variable to close modal
  const handleDialogClose = () => {
    setShowDialog(false);
  }

  // Handle remove confirmation
  const handleRemoveConfirm = async () => {
    setLoadingDialog(true);
    let redirect = false;
    try {
      const response = await fetchAuthenticated('delete', URL);

      if (response.status >= 400) {
        emitEvent('showSnack', { message: 'Não foi possível remover a importação', type: 'error' });
      } else {
        // logAction(`Remoção do equipamento ${equipment.shortImei}`, equipment.imei);
        emitEvent('showSnack', { message: 'Importação removida com sucesso', type: 'success' });
        redirect = true;
      }
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoadingDialog(false);
      setShowDialog(false);

      // Redirects to user list if everything was ok
      if (redirect) {
        history.push('/cadastros/rf');
      }
    }
  }

  const handleEnableDisable = () => {
    setShowEnableDisableDialog(true);
  }
  const confirmEnableDisable = async () => {
    // const method = equipment.isActive ? 'deactivate'  : 'activate';
    // const path = `${URL}/${method}`;
    // try {
    //   const res = await fetchAuthenticated('put', path);
    //   const eqp = await res.json();
    //   setRfImport(eqp);
    //   setShowEnableDisableDialog(false);
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <div>
      <NewRfImportForm
        title='Detalhes do Equipamento'
        data={rfImport}
        handleDownload={handleDownload}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
        handleEnableDisable={handleEnableDisable}
      />

      <ModalRemove
        isVisible={showDialog}
        setIsVisible={setShowDialog}
        titleLabel='da importação'
        onSubmit={handleRemoveConfirm}
        isLoading={loadingDialog}
        removeLabel='a importação'
        extraContent='Caso a importação já tenha sido iniciada, esta ação não impedirá o seu processamento.'
      />
    </div>
  );
}
