import React, { useReducer, useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/styles";
import { ButtonSubmit } from "components/buttons/ButtonSubmit";
import { ButtonRemove, ButtonCancel } from "components/buttons/ButtonsForm";
import CheckboxList from "components/lists/CheckboxList";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import CommandPv2GprsFields, {
  getDefaultEnabled,
} from "components/commands/CommandPv2GprsFields";
import ModalFenceAddEquipment from "components/modals/ModalFenceAddEquipment";
import TableMenu, { TableColumn } from "components/table/TableMenu";
import CheckboxColumn from "components/table/columns/CheckboxColumn";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "assets/icon/lixeira.svg";
import ReactSVG from "react-svg";
import { useEffectSkipFirst } from "hooks/common";

import SimpleTable from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import TableHeaderAction from "components/table/TableHeaderAction";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import Fade from "@material-ui/core/Fade";
import CircularLoading from "components/loading/CircularLoading";
import MapFence from "components/map/MapFence";
import AutoCompleteGray from "components/fields/AutoCompleteGray";
import { MyErrorMessage } from "components/fields/ErrorMessage";
import { emitEvent } from "utils/events";
import { logAction } from "utils/logs";

import { useFetch, useAuthenticated } from 'hooks/fetch';

import {
  fetchAuthenticated,
  postWithErrorHandler,
  putWithErrorHandler,
} from "services/fetch";
import { useAccessLog } from "hooks/logs";

import Permission from "components/permissions/Permission";
import { validatePermission } from "services/permissions";

import { Formik } from "formik";
import * as Yup from "yup";

import TabPanel from "components/tabs/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {
  datetimeFormatter,
  percentageFormatter,
  latlongFormatter,
} from "utils/formatters";
import { buildUrl, defaultQuery } from "utils/query";
import { isRfOn, getPositionIndicator } from "utils/helpers";

function LeftCard(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.custom.paper.root,
      height: (props) => props.height,
      boxShadow: "none",
      marginRight: 25,
      paddingLeft: 29,
      paddingRight: 29,
      paddingTop: 32,
      paddingBottom: 32,
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.gray.gray_4,
    },
    container: {
      ...theme.custom.containers.center,
      flexDirection: "column",
    },
  }));

  const classes = useStyles({
    height: 380,
  });

  const { showError, errorMsg, onNameBlur, referencePoint, onDescriptionBlur } = props;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName((referencePoint && referencePoint.name) || "");
  }, [referencePoint]);

  return (
    <Paper className={classes.root}>
      <Typography className={classes.header}>Informações</Typography>
      <div className={classes.container}>
        <TextField
          required
          label="Nome"
          name="name"
          className={classes.textField}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={(e) => {
            onNameBlur(e.target.value);
          }}
          fullWidth
          error={showError}
          helperText={showError && errorMsg}
        />
      </div>
    </Paper>
  );
}

export function SelectCompanies(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      ...theme.custom.paper.root,
      minHeight: (props) => props.minHeight,
      boxShadow: "none",
      marginTop: 23,
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: 29,
      marginBottom: 27,
    },
    container: {
      marginTop: 17,
      borderBottom: "solid 1px #c7cad94d",
      paddingBottom: 26,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    header: {
      fontSize: 20,
      fontWeight: "bold",
      color: theme.palette.gray.gray_4,
    },
    chip: {
      marginRight: 10,
      // marginTop: 10,
    },
    placeholder: {
      fontSize: 15,
      color: theme.palette.gray.gray_4,
      width: 400,
    },
  }));

  const classes = useStyles({
    minHeight: 240,
  });

  const {
    companies,
    setCompanies,
    referencePoint,
    containerStyle,
    hideTitle,
    checkAll,
  } = props;
  
  const [chips, setChips] = useState([]);

  const [allowedCompanies, setAllowedCompanies] = useState([]);

  useEffect(() => {

    const fn = async () => {
      await fetchAuthenticated('get', 'company?notPaginated=true&fields=_id,name').then(json => {
        json.json().then(data => {
          setAllowedCompanies(data.data)
        })
      });
    }
    fn();
  }, []);

  useEffect(() => {

    if(referencePoint && Object.keys(referencePoint).length > 0) {
      setChips(referencePoint.companies)
    }

  }, [referencePoint])

  const handleDelete = (chip) => {
    const _chips = chips.filter((item) => item._id !== chip._id);
    setChips(_chips);
    const _companies = companies.filter(
      (company) => JSON.stringify(company) !== JSON.stringify(chip)
    );
    setCompanies(_companies);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Paper className={classes.root} style={containerStyle}>
          {!hideTitle && (
            <Typography className={classes.header}>Empresas</Typography>
          )}
          <Grid container>
            <Grid item xs={12} className={classes.container}>
              <AutoCompleteGray
                menuContainerStyle={{
                  marginTop: 5,
                }}
                containerStyle={{
                  width: 254,
                  marginRight: 10,
                }}
                fetchUrl="company?notPaginated=true&fields=_id,name"
                placeholder="Buscar empresa"
                showPlaceholder={true}
                hideLabel={true}
                name="company"
                getOptionLabel={(opt) => opt.name}
                loadingMessage="Carregando empresas..."
                noOptionsMessage="Nenhuma empresa encontrada."
                onChange={({ opt }) => {
                  if (opt && !chips.some((chip) => chip._id === opt._id)) {
                    // let _companies = JSON.parse(JSON.stringify(companies))
                    setCompanies([...companies, {name: opt.name, _id: opt._id} ])
                    setChips([...chips, { name: opt.name, _id: opt._id }]);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.checkboxContainer}>
              { chips.length === 0 ? <Typography className={classes.placeholder}>
                  Nenhuma empresa selecionada
                </Typography> :
              chips.map((item, index) => (
                <Fade key={item._id + index} in={true} timout={1000}>
                  <Chip
                    label={item.name}
                    clickable
                    color="primary"
                    onDelete={allowedCompanies.findIndex(x => x._id === item._id) > -1 ? () => {
                      handleDelete(item);
                    } : undefined}
                    deleteIcon={allowedCompanies.findIndex(x => x._id === item._id) > -1 ? <CloseIcon style={{ width: 15 }} /> : undefined}
                    variant="outlined"
                    className={classes.chip}
                  />
                </Fade>
              ))}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
SelectCompanies.defaultProps = {
  containerStyle: {},
  hideTitle: false,
  checkAll: false,
};

function ReferencePointForm(props) {
  useAccessLog("Acesso ao ponto de referência");

  console.log(props)

  const { classes, location, referencePoint, setReferencePoint, title, history } = props;
  const [layer, setLayer] = useState({});
  const [companies, setCompanies] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [showNameRequired, setShowNameRequired] = useState(false);
  const [showLayerRequired, setShowLayerRequired] = useState(false);

  /* data to be send */
  const [data, setData] = useState({});

  useEffect(() => {

    if (referencePoint && Object.keys(referencePoint).length > 0) {
      setLayer(referencePoint.layer);
      setName(referencePoint.name);
      setCompanies(referencePoint.companies);
      // setDescription(fence.description);
      // setEquipments(fence.equipments);
    }
    
  }, [referencePoint]);

  const _onSubmit = (values) => {
    setData({ ...values });
    setSubmit(true);
  };

  const buildDataAndSubmit = async (
    data,
    layer
  ) => {
    const _data = {
      ...data,
      layer: {
        layerType: layer.layerType,
        bounds: layer.layer._bounds,
        latlngs: layer.layer._latlngs,
        latlng: layer.layer._latlng,
        radius: layer.layer._radius,
      },
      geoJSON: layer.layer.toGeoJSON(),
    };

    if(_data.companies.length === 0) {
      emitEvent('showSnack', {message: 'É necessário selecionar ao menos uma empresa!', type: 'warning'});
      return
    }

    const successMessage = "Ponto de Referência criado.";
    const errorMessage = "Erro ao tentar criar o ponto de referência.";
    const logMessage = "Criação de ponto de referência.";
    try {
      const response = await postWithErrorHandler("reference-point", _data, {
        successMessage,
        errorMessage,
        logMessage,
      });
      // history.push(`/cercas/${response._id}`);
      history.push(`/cercas/pontosdereferencia`);
    } catch (e) {
      console.log(e);
    }
  };

  const editSubmit = async (
    data,
    layer,
    referencePoint
  ) => {
    const _data = {
      ...data
    };

    if(_data.companies.length === 0) {
      emitEvent('showSnack', {message: 'É necessário selecionar ao menos uma empresa!', type: 'warning'});
      return
    }

    if (layer.needParse) {
      _data.layer = {
        layerType: layer.layerType,
        bounds: layer.layer._bounds,
        latlngs: layer.layer._latlngs,
        latlng: layer.layer._latlng,
        radius: layer.layer._radius,
      };
      _data.geoJSON = layer.layer.toGeoJSON();
    }

    const successMessage = "Informações atualizadas.";
    const errorMessage = "Erro ao tentar atualizar a cerca.";
    const logMessage = "Edição de cercas.";
    const response = await putWithErrorHandler(`reference-point/${referencePoint._id}`, _data, {
      successMessage,
      errorMessage,
      logMessage,
    });

    history.push(`/cercas/pontosdereferencia`);
  };

  /* Listen for user submit, and then ask for command data by
   * setting setCommand(true). After that, when command is set,
   * we are ready to submit.
   */
  useEffect(() => {
    if (submit) {

      if (!referencePoint) {
        buildDataAndSubmit(data, layer);
        setSubmit(false);
        return;
      }

      if (referencePoint) {
        console.log('editing')
        editSubmit(data, layer, referencePoint);
        setSubmit(false);
        return;
      }
      
    }
    // equipments and layer are here so their values are
    // updated when buildDataAndSubmit is fired
  }, [submit, data, layer]);

  const validateFields = () => {
    setShowNameRequired(!name);
    setShowLayerRequired(!Object.keys(layer).length > 0);

    if (name && Object.keys(layer).length > 0) {
      _onSubmit({ name, companies, layer });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerText}>{title}</Typography>
      </div>
      <Grid container className={classes.mapGrid}>
        <Grid item xs={3}>
          <LeftCard
            onNameBlur={setName}
            showError={showNameRequired}
            errorMsg="Campo obrigatório"
            referencePoint={referencePoint}
          />
        </Grid>
        <Grid item xs={9}>
          <MyErrorMessage isVisible={showLayerRequired}>
            Forma geométrica obrigatória
          </MyErrorMessage>
          <MapFence
            fence={referencePoint}
            height={380}
            containerStyle={{}}
            companies={[]}
            onLayerChange={(layer) => {
              setLayer(layer);
            }}
          />
        </Grid>
      </Grid>
      {
        <SelectCompanies
          referencePoint={referencePoint}
          companies={companies}
          setCompanies={setCompanies}
        />
      }
      <Grid
        container
        justify="flex-end"
        spacing={1}
        style={{ marginTop: 20, marginBottom: 28 }}
      >
        {props.handleCancel && (
          <Grid item xs={12} md="auto">
            <ButtonCancel onClick={props.handleCancel} />
          </Grid>
        )}
        {props.handleRemove && (
          <Grid item xs={12} md="auto">
            <ButtonRemove onClick={props.handleRemove} />
          </Grid>
        )}
        <Grid item xs={12} md="auto">
          <ButtonSubmit
            // disabledCondition={isSubmitting}
            onSubmit={() => {
              validateFields(true);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    position: "relative",
    paddingTop: theme.main.padding.top,
    paddingLeft: theme.main.padding.left,
    paddingRight: theme.main.padding.right,
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    fontSize: theme.text.header.fontSize,
    fontWeight: theme.text.header.fontWeight,
    color: theme.palette.gray.gray_4,
  },
  leftCardPaper: {
    borderRadius: 16,
  },
  mapGrid: {
    paddingTop: theme.main.padding.top,
  },
});
export default withRouter(withStyles(styles)(ReferencePointForm));
