import React from 'react';
import BaseList from 'components/lists/BaseList';
import ActionList from 'components/lists/ActionList';
import { datetimeFormatter } from 'utils/formatters.js';
import Edit from '@material-ui/icons/Edit';
import TableHeaderAction from 'components/table/TableHeaderAction';
import { CheckboxColumn } from 'components/table/columns';
import ModalEquipmentEdit from 'components/modals/ModalEquipmentEdit';
import { useAccessLog } from 'hooks/logs';

function ListErb(props) {
  useAccessLog('Acesso ao cadastro de ERB');
  const { match } = props;
  return (
    <BaseList
      modals={{
        'Edit': {
          isVisible: false,
          needReloadData: true,
          component: (props) => (
            <ModalEquipmentEdit key='EditModal' {...props} />
          ),
        },
      }}

      columns={[
        { value: row => row.lac, label: 'LAC', sort: 'lac' },
        { value: row => row.cellid, label: 'CellID', sort: 'cellid' },
        { value: row => row.lat, label: 'Lat', sort: 'lat' },
        { value: row => row.long, label: 'Long', sort: 'long' },
        { value: row => (row.user ? row.user : '-'), label: 'Usuário', sort: 'user' },
      ]}
      genericSearchFields={[
        { label: 'LAC', name: 'lac' },
        { label: 'CellID', name: 'cellid' },
        { label: 'lat', name: 'lat' },
        { label: 'long', name: 'long' },
      ]}
      basePath="erb"
      title="ERBs"
      addButtonText="Nova Cadastro"
      addUrl={`${match.path}/novo`}
    />
  );
}

export default ListErb;
