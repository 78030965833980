import React from 'react';

import SubRoutes from "components/SubRoutes";
import EditRf from "pages/Records/RF/Edit";
import ListRF from "pages/Records/RF/List";
import NewRf from "pages/Records/RF/New";

export default function Equipments (props) {
  const { match, setBreadcrumbs } = props;
  const pages = [
    {
      name: "Rf",
      path: '/novo',
      component: NewRf,
    },
    {
      name: "Rf",
      path: '/:id',
      component: EditRf,
    },
    {
      name: "Rf",
      path: '',
      component: ListRF
    },
  ]

  return (
    <SubRoutes
      setBreadcrumbs={setBreadcrumbs}
      defaultPage={0}
      pages={pages}
      match={match}
    />
  )
}
