import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
} from '@material-ui/core';
import { get, fetchAuthenticated } from 'services/fetch';
import { emitEvent } from 'utils/events';
import Switch from '@material-ui/core/Switch';
import Modal from 'components/modals/Modal';
import { getUser, updateUser } from 'services/user';
import { useEffectSkipFirst } from 'hooks/common';
import { logAction } from 'utils/logs';

import {
  AutocompleteField,
} from 'components/fields';
import { MyCheckbox as Checkbox } from 'components/lists/CheckboxList';

import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from 'assets/icon/lixeira.svg';
import ReactSVG from 'react-svg'

const useStyles = makeStyles(theme => ({
  followContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 21,
    paddingTop: 51,
    borderBottom: 'solid 1px #80849d4d',
  },
  followHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  followTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.gray.gray_4,
  },
  subheader: {
    fontSize: 15,
    color: theme.palette.gray.gray_4,
  },
  nameText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  normalText: {
    fontSize: 16,
    color: theme.palette.gray.gray_5,
  },
  contact: {
    borderBottom: 'solid 1px #80849d4d',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
  },
  splitContact: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  newContactDiv: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerIcon: {
    width: 47,
  },

}));

const ModalFollow = (props) => {
  const { isVisible, setIsVisible, equipment, setIconActive, onSubmit } = props;
  const classes = useStyles();

  const [contactList, setContactList] = useState([]);
  const [followedEquipments, setFollowedEquipments] = useState([]);
  const [followedEquipmentsWithSoundAlarm, setFollowedEquipmentsWithSoundAlarm] = useState([]);

  const [showModal, setShowModal] = useState(isVisible);
  const [isContactLoading, setIsContactLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [follow, setFollow] = useState(false);
  const [soundAlarm, setSoundAlarm] = useState(false);

  const contactRef = React.createRef(null);
  const [user, setUser] = useState(null);

  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    setShowModal(isVisible);
  }, [isVisible]);

  /* Load contact List */
  useEffect(() => {
    if (equipment) {
      setIsContactLoading(true);
      const fn = async () => {
        const _data = await fetchAuthenticated(get, 'company/contacts/' + equipment.currentCompany._id);
        setContactList(_data);
        setIsContactLoading(false);
      }
      fn();
    }
  }, [equipment]);

  /* Update followed contacts when load current equipment */
  useEffect(() => {
    if (equipment.followedContacts) {
      setContacts(equipment.followedContacts);
    }
  }, [equipment]);

  /* load current user */
  useEffectSkipFirst(() => {
    const fn = async () => {
      let currentUser = await getUser();
      currentUser = await fetchAuthenticated(get, 'user/' + currentUser._id);
      let isSoundAlarmActivate = false;
      if(currentUser.hasOwnProperty('followedEquipmentsWithSoundAlarm')) {
        isSoundAlarmActivate = currentUser.followedEquipmentsWithSoundAlarm.some(e => e._id === equipment._id);
      }
      const isFollowed = currentUser.followedEquipments.some(e => e._id === equipment._id);
      setFollow(isFollowed);
      setSoundAlarm(isSoundAlarmActivate);
      setIconActive(isFollowed);
      setFollowedEquipments(currentUser.followedEquipments || []);
      setFollowedEquipmentsWithSoundAlarm(currentUser.followedEquipmentsWithSoundAlarm || []);
      setUser(currentUser);
    }
    if (equipment) fn();
  }, [equipment]);

  /* update followed equipments list when follow changes */
  useEffectSkipFirst(() => {
    if (user && equipment) {
      if (follow) {
        setFollowedEquipments([...followedEquipments, { _id: equipment._id }]);

      } else {
        setFollowedEquipments(followedEquipments.filter(eq => eq._id !== equipment._id));
      }
    }
  }, [follow]);

  /* update soundAlarm equipments list when soundAlarm changes */
  useEffectSkipFirst(() => {
    if (user && equipment) {
      if (soundAlarm) {
        setFollowedEquipmentsWithSoundAlarm([...followedEquipmentsWithSoundAlarm, { _id: equipment._id }]);
      } else {
        setFollowedEquipmentsWithSoundAlarm(followedEquipmentsWithSoundAlarm.filter(eq => eq._id !== equipment._id));
      }
    }
  }, [soundAlarm]);

  const handleSubmit = async () => {
    if (setIsVisible) {
      setIsVisible(false);
    }

    const response = await fetchAuthenticated('put', 'equipment/update-follow/' + equipment._id, {
      followedContacts: contacts,
      follow,
      action: 'Follow',
    });

    console.log(followedEquipments, followedEquipmentsWithSoundAlarm)

    const updatedUser = await fetchAuthenticated('put', 'user/' + user._id, {
      followedEquipments: followedEquipments.map(e => e._id),
      followedEquipmentsWithSoundAlarm: followedEquipmentsWithSoundAlarm.map(e => e._id)
    });

    if (!response.ok) {
      emitEvent('showSnack', { message: 'Não foi possível realizar o follow.', type: 'error' });
    } else {
      logAction(`${follow ? 'Começou a' : 'Deixou de'} seguir o equipamento ${equipment.shortImei}`, equipment.imei)
      emitEvent('showSnack', { message: follow && 'Follow realizado.' || 'Unfollow realizado.', type: 'success' });
    }
  }

  const onClose = () => {
    if (setIsVisible) {
      setIsVisible(false);
    }
  }

  const onRemoveClick = (_id) => {
    setContacts(contacts.filter((e) => e._id !== _id));
  }

  const onAddClick = () => {
    if (contactRef.current.value && contactRef.current.value._id) {
      const { email: _email, mobile: _mobile, _id, name } = contactRef.current.value;
      const newContact = {
        _id,
        name: name,
        email: email && _email || '',
        mobile: mobile && _mobile || '',
      }
      setContacts([newContact, ...contacts]);
    }
  }

  return (
    <Modal
      handleSubmit={handleSubmit}
      onSuccessMessage="Follow realizado com sucesso."
      isVisible={showModal} setIsVisible={setShowModal} title={equipment.follow && "Seguindo equipamento" || "Seguir equipamento"} buttonLabel="Salvar"
      onClose={onClose}
    >

      <div className={classes.followContainer}>
        <div className={classes.followHeader}>
          <Typography className={classes.followTitle}>
            Seguir
          </Typography>
          <Typography className={classes.subsubheader}>
            Ativar ou desativar funcionalidade
          </Typography>
        </div>

        <Switch
          checked={follow}
          onChange={(e) => {
            setFollow(e.target.checked);
            setIconActive(e.target.checked);
            if(!e.target.checked) {
              setSoundAlarm(false)
            }
          }}
          value={follow}
          color='primary'
        />

      </div>

      <div className={classes.followContainer}>
        <div className={classes.followHeader}>
          <Typography className={classes.followTitle}>
            Alerta Sonoro
          </Typography>
          <Typography className={classes.subsubheader}>
            Ativar ou desativar funcionalidade
          </Typography>
        </div>

        <Switch
          checked={soundAlarm}
          disabled={!follow}
          onChange={(e) => {
            setSoundAlarm(e.target.checked);
          }}
          value={soundAlarm}
          color='primary'
        />

      </div>

      <Typography className={classes.followTitle} style={{ paddingTop: 21 }}>
        Contatos que serão notificados
      </Typography>
      <div className={classes.newContactDiv}>
        <div className={classes.splitContact}>
          <div style={{ width: '100%', display: 'block' }}>
            <AutocompleteField
              innerRef={contactRef}
              label='Novo contato'
              name='contact'
              options={contactList}
              isLoading={isContactLoading}
              getOptionLabel={opt => opt.name}
              getOptionValue={opt => opt._id}
              noOptionsMessage={() => 'Nenhuma contato encontrado.'}
              loadingMessage={() => 'Carregando contatos...'}
              isClearable={true}
            />
          </div>
        </div>
        <div className={classes.splitContact} style={{ justifyContent: 'space-around', marginLeft: 20, marginRight: 20 }}>
          <Checkbox
            value={email}
            setValue={setEmail}
            header='E-mail'
            checkboxStyle={{
              marginRight: 16,
            }}
            labelStyle={{
              color: '#41475a',
              fontWeight: 'normal',
            }}
          />
          <Checkbox
            value={mobile}
            setValue={setMobile}
            header='Celular'
            checkboxStyle={{
              marginRight: 16,
            }}
            labelStyle={{
              color: '#41475a',
              fontWeight: 'normal',
            }}
          />
        </div>
      </div>
      <div>
        <Link
          onClick={onAddClick}
          style={{ fontSize: 16, fontWeight: 'bold', cursor: 'pointer' }}
          color='primary'
          underline='none'
        >Adicionar contato
        </Link>

      </div>

      {contacts.map((contact) => (
        <div key={contact._id} className={classes.contact}>
          <div className={classes.splitContact}>
            <Typography className={classes.nameText}>{contact.name}</Typography>
            <Typography className={classes.normalText}>{contact.email}</Typography>
          </div>
          <div className={classes.splitContact}>
            <Typography className={classes.normalText} style={{ marginLeft: 80 }}>{contact.mobile}</Typography>
            <IconButton
              style={{ boxShadow: 'unset' }}
              title="Remover"
              className={classes.headerIcon}
              onClick={() => onRemoveClick(contact._id)}>
              <ReactSVG
                // className={classes.cardIcon}
                src={RemoveIcon}
              />
            </IconButton>
          </div>
        </div>
      ))}
    </Modal>
  )
}

export default ModalFollow;
