
/* eslint-disable import/prefer-default-export */
export const EMERGENCY_LABELS = {
  TEMPERATURE_ALARM: "Alarme Temperatura",
  JAMMING_DETECTED: "Jamming detectado",
  BEACON: "Ancoragem rompida",
  PANIC_BUTTON: "Pânico Motorista",
  MOVEMENT_SENSOR: "Sensor movimento",
  BOX_VIOLATION: "Pânico Carona",
  NO_BATTERY: "Sem bateria",
  PANIC_PASSENGER: "Pânico Passageiro",
  PANIC_BAG: "Pânico Porta-Malas"
};

export function getEmergencyLabel (equipment) {

  if (equipment.lastEvent.status.jammingDetected) return 'Jammer Detectado';

  if (equipment.lastEvent.status.movementSensorAlarm) return 'Alarme de Movimento';

  if (equipment.lastEvent.status.temperatureAlarm) return 'Alarme de Temperatura';

  return EMERGENCY_LABELS[equipment.emergencyType];
}
