import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';

import SubBar from "components/SubBar";
import SubRoutes from "components/SubRoutes";
import AccessProfiles from 'pages/Admin/AccessProfile';
import GeneralParameters from 'pages/Admin/GeneralParameters';

const pages = [
  {
    name: "Perfil de acesso",
    path: '/perfil-de-acesso',
    component: AccessProfiles,
    permission: 'AccessProfile',
  },
  {
    name: "Parâmetros gerais",
    path: '/general-parameters',
    component: GeneralParameters,
    permission: 'AccessProfile',
  },

];

function Admin(props) {
  const { match } = props;
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  return (
    <div>
      <SubBar breadcrumbs={breadcrumbs} items={pages} match={match}></SubBar>
      {/* Para definir as rotas precisamos importar o compoenente SubRoutes */}
      <SubRoutes setBreadcrumbs={setBreadcrumbs} defaultPage={0} pages={pages} match={match}></SubRoutes>
    </div>
  )
};

const styles = theme => {/** */ };
export default withStyles(styles)(Admin);
