import React, { useEffect, useState } from "react";

import CommandModal from "./CommandModal";
import CommandPv2GprsFields from "./CommandPv2GprsFields";
import { emitEvent } from "utils/events";
import { logAction } from "utils/logs";
import {
  postWithErrorHandler, fetchAuthenticated, post, put
} from "services/fetch";
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, Button, Divider, CircularProgress
} from '@material-ui/core';
import ModalRequirePermission from "components/modals/ModalRequirePermission";
import { useEffectSkipFirst } from 'hooks/common';
import { buildLogString } from "utils/helpers";
import { buildTemperatureRangeLogString } from "utils/helpers";

const CommandPv2Gprs = (props) => {
  const useStyles = makeStyles(theme => ({

  }));
  const classes = useStyles();

  const [imei, setImei] = useState(props.imei || "");
  const [showModal, setShowModal] = useState(true);

  const [commandsV2ToLog, setCommandsV2ToLog] = useState();

  const [modalRequirePermissionIsVisible, setModalRequirePermissionIsVisible] = useState(false);
  const [signaturePasswordValidated, setSignaturePasswordValidated] = useState(null);
  const [gprsCommandData, setGprsCommandData] = useState({});
  const [gprsCommandLogData, setGprsCommandLogData] = useState({});
  const [defaultEnabledCommands, setDefaultEnabledCommands] = useState(null);
  const [loading, setLoading] = useState(false);

  const createLogMessage = () => {
    const message = [];

    for (const c in commandsV2ToLog) {
      message.push(`${commandsV2ToLog[c].label}: ${commandsV2ToLog[c].value}`);
    }

    return message.join(" / ");
  };

  const handleModifyV2Commands = (newCommandsState) => {
    setCommandsV2ToLog(newCommandsState);
  };

  useEffectSkipFirst(() => {
    if (signaturePasswordValidated === true) {
      setShowModal(false);
      if (props.setIsVisible) {
        props.setIsVisible(false);
      }


      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(false);

      const logMessage = `Comando GPRS enviado ao equipamento ${imei.slice(-8)}`;
      const successMessage = `Comando enviado com sucesso.`;
      const errorMessage = `Erro ao tentar enviar comando.`;
      const response = postWithErrorHandler(
        "equipment-event/command",
        {
          imei,
          ...gprsCommandData,
          action: "GPRS",
          logMessage: createLogMessage()
        },
        {
          successMessage,
          errorMessage
        }
      );
      
      const _originalData = {
        ...gprsCommandLogData,
        ...gprsCommandLogData.status
      };
  
      delete _originalData.status;
  
      const _newData = {
        ...gprsCommandData,
        ...gprsCommandData.status
      };
  
      delete _newData.status;
      
      const trueOrFalseCast = { true: 'Ligado', false: 'Desligado' };

      const translateKeys = {
        imei: 'Imei',
        positionSendInterval: 'Período Envio de Dados',
        positionSendIntervalMotion: 'Período Envio modo motion',
        positionSendIntervalSleep: 'Período Envio modo sleep',
        movementSensorMode: 'Alarme Movimento',
        hibernationMode: 'LORA',
        transmitter434Mhz: 'RF',
        transmitter928Mhz: 'RF Bip Triplo',
        temperatureAlarmMode: 'Alarme Temperatura',
        gpsMode: 'Modo GPS'
      };

      let updatedItemsStr = buildLogString(_originalData, _newData, translateKeys, ['imei', 'temperatureAlarmMode'], trueOrFalseCast, false);

      setTimeout(() => {

        let oldTemperatureRange = {
          temperatureRange: 'temperatureRange' in _originalData ? _originalData.temperatureRange : null,
          temperatureRangeEnabled: 'temperatureAlarmMode' in _originalData ? _originalData.temperatureAlarmMode ? 'Sim' : 'Não' : null,
          temperatureRangeType: 'temperatureRangeType' in _originalData ? _originalData.temperatureRangeType : null
        }

        let newTemperatureRange = {
          temperatureRange: 'temperatureRange' in _newData ? _newData.temperatureRange : null,
          temperatureRangeEnabled: 'temperatureAlarmMode' in _newData ? _newData.temperatureAlarmMode ? 'Sim' : 'Não' : null,
          temperatureRangeType: 'temperatureRangeType' in _newData ? _newData.temperatureRangeType : null
        }

        let logString = buildTemperatureRangeLogString(oldTemperatureRange, newTemperatureRange, imei)

        if(logString !== '') {
          updatedItemsStr += ` / ${logString}`
        }

        logAction(`Comando GPRS${updatedItemsStr}`, _newData.imei);
      }, 300);

    } else if (signaturePasswordValidated === false) {
      emitEvent('showSnack', { message: 'Assinatura Eletrônica Inválida!', type: 'error' });
    }
  }, [signaturePasswordValidated]);

  const handleSubmit = async (data) => {

    const translateKeys = {
      imei: 'Imei',
      positionSendInterval: 'Período Envio de Dados',
      positionSendIntervalMotion: 'Período Envio modo motion',
      positionSendIntervalSleep: 'Período Envio modo sleep',
      movementSensorMode: 'Alarme Movimento',
      hibernationMode: 'LORA',
      transmitter434Mhz: 'RF',
      transmitter928Mhz: 'RF Bip Triplo',
      temperatureAlarmMode: 'Alarme Temperatura',
      gpsMode: 'Modo GPS'
    };

    const _originalData = {
      ...data.logData,
      ...data.logData.status
    };

    delete _originalData.status;

    const _newData = {
      ...data.data,
      ...data.data.status
    };

    delete _newData.status;

    setGprsCommandData(data.data);
    setGprsCommandLogData(data.logData);
    const userId = JSON.parse(localStorage.getItem('user'))._id;
    const userRequest = await fetchAuthenticated('get', `user/${userId}`);
    let user = {};

    await userRequest.json().then(userResponse => {
      user = userResponse;
    });

    if (user && user.signaturePasswordIsEnabled && user.profiles.some(p => p.enableSignaturePasswordOption)) {
      setModalRequirePermissionIsVisible(null);
      setModalRequirePermissionIsVisible(true);
    } else {
      setShowModal(false);
      if (props.setIsVisible) {
        props.setIsVisible(false);
      }

      setTimeout(async () => {
        setLoading(true);
        const responseLastGPRSCommand = await put(
          `equipment/update-lastgprscommand/${imei}`,
          {
            lastGprsCommand: data.data
          },
          // {
          //   successMessage,
          //   errorMessage,
          //   logMessage,
          // }
        );

        const logMessage = `Comando GPRS enviado ao equipamento ${imei.slice(-8)}`;
        const successMessage = `Comando enviado com sucesso.`;
        const errorMessage = `Erro ao tentar enviar comando.`;
        const response = await postWithErrorHandler(
          "equipment-event/command",
          {
            imei,
            ...data.data,
            action: "GPRS",
            logMessage: createLogMessage()
          },
          {
            successMessage,
            errorMessage
          }
        );
        setLoading(false);
      }, 300);

      const trueOrFalseCast = { true: 'Ligado', false: 'Desligado' };

      let updatedItemsStr = buildLogString(_originalData, _newData, translateKeys, ['imei', 'temperatureAlarmMode'], trueOrFalseCast, false);

      setTimeout(() => {

        let oldTemperatureRange = {
          temperatureRange: 'temperatureRange' in _originalData ? _originalData.temperatureRange : null,
          temperatureRangeEnabled: 'temperatureAlarmMode' in _originalData ? _originalData.temperatureAlarmMode ? 'Sim' : 'Não' : null,
          temperatureRangeType: 'temperatureRangeType' in _originalData ? _originalData.temperatureRangeType : null
        }

        let newTemperatureRange = {
          temperatureRange: 'temperatureRange' in _newData ? _newData.temperatureRange : null,
          temperatureRangeEnabled: 'temperatureAlarmMode' in _newData ? _newData.temperatureAlarmMode ? 'Sim' : 'Não' : null,
          temperatureRangeType: 'temperatureRangeType' in _newData ? _newData.temperatureRangeType : null
        }

        let logString = buildTemperatureRangeLogString(oldTemperatureRange, newTemperatureRange, imei)

        if(logString !== '') {
          updatedItemsStr += ` / ${logString}`
        }

        logAction(`Comando GPRS${updatedItemsStr}`, _newData.imei);
      }, 300);
    }
  };

  const onClose = () => {
    setShowModal(false);
    if (props.setIsVisible) {
      props.setIsVisible(false);
    }
  };

  return (
    <div>
      {loading && (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.overlay}
      >
        <CircularProgress color="primary" />
      </Grid>
      )}
      <ModalRequirePermission
        isVisible={modalRequirePermissionIsVisible}
        setIsVisible={setModalRequirePermissionIsVisible}
        signaturePasswordValidated={signaturePasswordValidated}
        setSignaturePasswordValidated={setSignaturePasswordValidated}
      />
      <CommandModal
        isOpen={showModal}
        onClose={onClose}
        title="Comandos GPRS Protocolo 2"
      >
        <CommandPv2GprsFields
          {...props}
          handleSubmit={handleSubmit}
          handleModifyCommands={handleModifyV2Commands}
          defaultEnableCommands
        />
      </CommandModal>
    </div>
  );
};
export default CommandPv2Gprs;
